import * as moment from "moment-timezone";
import { _c } from "@app/constants";

export const dateHelpers = {
  toString(str, format?){
    str = (typeof str === 'string' && str.indexOf('Z') !== -1) ? str.replace('Z', '') : str;
    format = format || _c('dateStringFormat');
    return moment(str).format(format);
  },
  toDate(date, format?){
    format = format || _c('dateStringFormat');
    return moment(date, format);
  },
  getTimezoneDate(timeZone, format?){
    // if not found set default
    if(!moment.tz.zone(timeZone)){
      timeZone = 'Europe/Rome';
    }
    format = format || _c('dateStringFormat');
    return moment().tz(timeZone).format(format);
  },
  add(date, value, unit){
    return moment(date).add(value, unit);
  },
  subtract(date, value, unit){
    return moment(date).subtract(value, unit);
  },
  diff(date1, date2, unit){
    return moment(date1).diff(moment(date2), unit);
  },
  formatDateFromEpoch(epochTime) {
    const date = new Date(epochTime);
    const day = date.getDate();
    const month = date.getMonth() + 1; 
    const year = date.getFullYear();
    const hours = date.getHours();
    const dateString = `${day}/${month}/${year} ${hours}:00`;
    return dateString;
}

}
