export const HEADER_MOCK = {
  logo: {
    image: '../../../../assets/img/Logo-header.png',
    title: 'TETI',
    payload: '/'
  },
  nav: {
    items: [
      { text: 'Dashboard', payload: '/', icon: 'n7-icon-dashboard' },
      { text: 'Panoramica', payload: '/s', icon: 'n7-icon-chart-line' },
      { text: 'Sezioni impianto', payload: '/os', icon: 'n7-icon-chart-line' },
      { text: 'Grafico personalizzato', payload: '/', icon: 'n7-icon-chart-line' },
      { text: 'Modello', payload: '/shs', icon: 'n7-icon-chart-lines' },
    ]
  },
  actions: [
    { icon: 'n7-icon-bell', payload: '/', badge: {}
    , },
  ],
  user: {
    img: '../../../../assets/img/User-Avatar-Default.jpg',
  },
  menuToggle: {
    open: {
      payload: 'mobile-open'
    },
    close: {
      payload: 'mobile-close'
    }
  }
}