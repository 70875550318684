import { DataSource } from '@n7-frontend/core';
import { IPaginationData, PAGINATION_MOCK } from '@n7-frontend/components';

export class PaginationDS extends DataSource {
  private _limit: number = 10;
  private _numLinks: number = 5;
  private _basePath: string;

  protected transform(data): IPaginationData {
    if(!data) return;

    this._basePath = this.options.basePath;

    const { total } = data,
      current = +(this.options.current) || 1,
      pages = Math.ceil(total / this._limit),
      margin = Math.floor(this._numLinks / 2); 
    
    let result = {
      first: { 
        payload: current > 1 ? 1 : null, 
        classes: current === 1 ? 'is-disabled' : ''
      },
      prev: { 
        payload: current > 1 ? current - 1 : null, 
        classes: current === 1 ? 'is-disabled' : '' 
      },
      next: { 
        payload: current < pages ? current + 1 : null, 
        classes: current === pages ? 'is-disabled' : '' 
      },
      last: { 
        payload: current < pages ? pages : null, 
        classes: current === pages ? 'is-disabled' : ''
      },
      links: []
    };

    let start = 1, i;
    if((current + margin) > pages){
      start = (pages - this._numLinks) + 1;
    } else if((current - margin) < 1) {
      start = 1;
    } else {
      start = current - margin;
    }

    for(i = 0; i < this._numLinks; i++){
      const pageNumber = start + i;
      result.links.push({
        text: pageNumber, 
        payload: pageNumber !== current? pageNumber : null, 
        classes: pageNumber === current ? "is-active" : ''
      });
    }

    return result;
  }

  getBasePath = () => this._basePath;
}
