import { DataSource } from '@n7-frontend/core';
import { AlertEntry } from 'src/app/components/manage-alerts/manage-alerts'
import { VALUES_KEY, _c } from '@app/constants';
import { helpers } from '@app/helpers';

export class ManageAlertsDS extends DataSource {

  private operatorsMap = {
    'GREATER': '>',
    'LESS': '<',
    'EQUAL': '='
  };

  private elementsConfig: any;

  private _formatIcons ({ section, operator, element, isModel }) {
    const { labels } = this.options,
      config = this.elementsConfig[element];

    // element icons
    let html = ['<div class="iwt-manage-alerts__icons">'];
    html.push(`<span class="n7-icon-circle-full element-color-${config.id}"></span>`);
    if(isModel){
      html.push(`<span class="n7-icon-circle-full icon-model element-color-${config.id}-model"></span>`);
    }
    html.push('</div>');

    return html.join('');
  }

  private _formatSection ({ section, operator, element, isModel }) {
    const { labels } = this.options,
      config = this.elementsConfig[element];

    // section label
    let html = ['<div class="iwt-manage-alerts__section">'];
    html.push(`<span class="iwt-manage-alerts__section-title">${labels.headers[section]}</span>`);
    if(!isModel){
      html.push(`<span class="iwt-manage-alerts__section-text">${config.label}</span>`);
    } else {
      html.push(`<span class="iwt-manage-alerts__section-text">${config.label}<br />vs. ${labels.model}</span>`);
    }
    html.push('</div>');

    return html.join('');
  }

  private _formatText ({section, operator, element, isModel}) {
    const { labels } = this.options,
      config = this.elementsConfig[element];
    if(!isModel){
      return `<div class="iwt-manage-alerts__message">${labels.manage_alerts.alert_text}</div>`;
    } else {
      return `<div class="iwt-manage-alerts__message">
        ${labels.manage_alerts.alert_text_alt.replace('__PARAM__', config.label )}
      </div>`;
    }
  }

  private _formatCondition ({ section, operator, element, isModel, val }) {
    const { labels } = this.options,
      operatorStr = this.operatorsMap[operator],
      config = this.elementsConfig[element];

    let html = `<div><span class="iwt-manage-alerts__condition">${operatorStr} ${val} ${config.unit}</span>`;
    if (isModel && operator === 'LESS') {
      html += `<br><span class="iwt-manage-alerts__negative">${labels['negative']}</span>`;
    }
    html += '</div>'
    return html;
  }

  protected transform(data) {
    if(helpers.isEmptyObject(data) || !this.options || !this.options.labels) return;

    this.elementsConfig = _c(VALUES_KEY);

    var values = [];
    data.forEach( alert => {
      var id = alert.id;
      var section = alert.parameter.section.id;
      var operator = alert.condition;
      var element = alert.parameter.id;
      var isModel = alert.vsModel;
      var val = alert.threshold;
      values.push({ id, section, operator, element, isModel , val });
    });

    const { labels } = this.options;

    return values.map ((v) => {
      let entry : AlertEntry = {
        item: [ 
          this._formatIcons ( v ), 
          this._formatSection ( v ), 
          this._formatText ( v ),
          this._formatCondition ( v ) 
        ],
        payload: v
      }
      return entry;
    });
  }
}
