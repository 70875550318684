import { CompareChartType } from "@app/layouts/home-layout/home-layout.types";
import { environment as env } from "@src/environments/environment";
const ASSETS_PATH = env.assetsPath as string;

export const APP_CONFIG = (APP_NAME) => {
  return {
    style: {
      chart: {
        [CompareChartType]: {
          color: "#F5A624",
          label: "compare",
        },
      },
    },
    views: {
      login: {
        enabled: true,
        disableGuards: true,
      },
      dashboard: {
        enabled: true,
        chartActions: {
          delete: true,
          updateName: true,
          updatePosition: true,
        },
      },
      customChartView: {
        enabled: true,
        filters: {
          itemFilter: true,
          compareFilter: true,
        },
        actions: {
          addToDashboard: true
        },
      },
      itemsView: {
        enabled: true,
        disableSearch: true,
        filters: {
          itemFilter: true,
          compareFilter: true,
        },
        actions: {
          addToDashboard: true,
          alarms: true
        }
      },
      header: {
        enabled: true,
        logo: {
          image: ASSETS_PATH + "/img/Logo-header.png",
          title: APP_NAME,
          payload: {
            source: "nav",
            path: "/",
          },
        },
        actions: [],
        user: {
          img: ASSETS_PATH + "/img/User-Avatar-Default.jpg",
          payload: {
            source: "userButton",
          },
        },
        menuToggle: {
          open: {
            payload: {
              source: "mobileButton",
              action: "open",
            },
          },
          close: {
            payload: {
              source: "mobileButton",
              action: "close",
            },
          },
        },
      },
      footer: {
        enabled: true,
        columns: [
          {
            classes: "col-class",
            title: "TETI",
            text: "Teconologie innovative per il controllo, il monitoraggio e la sicurezza in mare.<br><b>Versione demo: i dati sono solo a titolo esemplificativo</b>",
          },
          {
            images: [
              {
                classes: "img-full",
                url: `${ASSETS_PATH}/img/blocco_loghi_pon_fse.png`,
                alttext: "PORCreO",
              },
            ],
          },
        ],
      },
    },
    filters: {
      date: {
        options: [
          {
            default: true,
            value: { unit: "hours", value: 12, type: "dynamic" },
            label: "i18n.chart_select.options.hours12",
          },
          {
            value: { unit: "hours", value: 24, type: "dynamic" },
            label: "i18n.chart_select.options.hours24",
          },
          {
            value: { unit: "hours", value: 48, type: "dynamic" },
            label: "i18n.chart_select.options.hours48",
          },
          {
            value: { unit: "days", value: 3, type: "dynamic" },
            label: "i18n.chart_select.options.days3",
          },
        ],
        custom: {
          enabled: true,
          enableTime: true
        },
      },
      compare: {
        options: [
          {
            default: true,
            value: { value: 1, unit: "interval", type: "dynamic" },
            label: `i18n.chart_compare.select_options.interval1`,
          },
          {
            value: { value: 1, unit: "months", type: "dynamic" },
            label: `i18n.chart_compare.select_options.months1`,
          },
          {
            value: { value: 1, unit: "years", type: "dynamic" },
            label: `i18n.chart_compare.select_options.years1`,
          },
        ],
        custom: {
          enabled: true,
          enableTime: true
        },
      },
      item: {
        options: [
          {
            value: { value: "-minimum", type: "regex" },
            label: `i18n.item_select.options.min`,
          },
          {
            value: { value: "-maximum", type: "regex" },
            label: `i18n.item_select.options.max`,
          },
          {
            value: { value: "-variance", type: "regex" },
            label: `i18n.item_select.options.var`,
          },
          {
            value: { value: "-average", type: "regex" },
            label: `i18n.item_select.options.avg`,
          },
          {
            default: true,
            value: { value: ".*", type: "regex" },
            label: `i18n.item_select.options.all`,
          },
        ],
      },
    },
  };
};
