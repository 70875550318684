import { DataSource } from "@n7-frontend/core";
import { IDropdownData } from "@app/components/dropdown/dropdown";

export class DropdownDS extends DataSource {
  private _id: string;

  protected transform(data: IDropdownData): IDropdownData {
    if (!data) return;
    this._id = data.id;
    return data;
  }

  public onClickOut(event) {
    const checkbox = document.getElementById(this._id);

    if(checkbox){
      if (
        event.payload.className != "iwt-dropdown__inner-button-label" &&
        event.payload.className != "iwt-dropdown__inner-button" &&
        event.payload.className != "iwt-chart-select-filter__item is-selected" &&
        event.payload.className != "iwt-dropdown__inner-button-icon n7-icon-angle-down" &&
        event.payload.id != this._id
      ) {
        checkbox["checked"] = false;
      }
    }
  }
}
