import { EventHandler } from '@n7-frontend/core';

export class ChartSimulationsSelectEH extends EventHandler {
  public listen() {
    this.innerEvents$.subscribe(({ type, payload }) => {
      switch(type){
        case 'chart-simulations-select.click': {
          const { _id, elementId, sectionId, isSelected } = payload;
          if (_id === 'custom') {
            this.emitGlobal('navigate', ['model-simulations']);
          } else if (!isSelected) {
            this.emitGlobal('navigate', ['model', sectionId, elementId, _id]);
          }
          break;
        }
        
        default:
          break;
      }
    });
  }
}