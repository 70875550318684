import { FormsModule } from '@angular/forms';
import { EventHandler } from '@n7-frontend/core';

export class FormAnnotationsEH extends EventHandler {
  public listen() {
    this.innerEvents$.subscribe(({ type, payload }) => {
      switch(type){
        case 'form-annotations.labelChange':
          this.dataSource.onLabelChange(payload);
          break;

          case 'form-annotations.descriptionChange':
            this.dataSource.onDescriptionChange(payload);
            break;
        
        case 'form-annotations.change': 
          this.emitOuter('change', payload);
          break;

        case 'form-annotations.file-change':
          this.dataSource.onFilechange(payload);
          break;

        case 'form-annotations.download-click': 
          this.dataSource.onDownloadClick();
          break;

        case 'form-annotations.remove-attachment': 
          this.dataSource.onRemoveAttachment(payload);
          break;

        

        default: 
          break;
      }
    });
  
    this.outerEvents$.subscribe(event => {
      switch(event.type){

        case 'chart-layout.save-annotations-modal':
          const formState = this.dataSource.getFormState();
          let formStateNotValid = this.validateForm(formState);

          if(formStateNotValid!=""){
            this.dataSource.formNotValid(formStateNotValid);
          }
          else{
            if (formState._edit){
              this.emitOuter("edit", formState)
            }
            else{
              this.emitOuter("save", formState)
            } 
          }
          break

          case 'chart-layout.annotation-date-selected':
            this.dataSource.open();
            break

          case 'chart-layout.annotation-set-date':
            this.dataSource.setDate(event.payload)
            break

        default:
          break;
      }
    });
  }
  
  validateForm(formState: any): string{
    let err="";
    if(!formState.label){
      err+="Il titolo dell'annotazione è obbligatorio. "
    }
    if(formState.label && formState.label.length > 20){
      err+="Il titolo dell'annotazione deve essere minore di 20 caratteri. "
    }
    if(formState.description && formState.description.length > 1000){
      err+="La descrizione dell'annotazione deve essere minore di 1000 caratteri. "
    }
    const regex = /^\s*$/;
    if(formState.label && regex.test(formState.label)){
      err+="Il titolo dell'annotazione contiene solo spazi. "
    }
    if(!formState.x1){
      err+="Intervallo di date mancante. "
    }

    return err;
  }
}
