import { environment as env } from '@src/environments/environment';

export const VIEWS_KEY = 'views';
export const STYLE_KEY = 'style';
export const LABELS_KEY = 'labels';
export const FILTERS_KEY = 'filters';
export const FILTER_DATE = 'date';
export const FILTER_COMPARE = 'compare';
export const FILTER_ITEM = 'item';

export const BUOYS_KEY = 'buoys';
export const SENSORS_KEY  = 'sensors';
export const ELEMENTS_KEY = 'elements';
export const VALUES_KEY = 'values';

export class Config {
  private static defaults = {
    langs: ["it", "en"],
    useMock: <boolean>false,
    assetsPath: env.assetsPath,
    apiUrl: env.apiUrl,
    dateStringFormat: "YY-MM-DD H:mm",
    dateStringFormatReverseShort: "DD MMM YYYY HH:mm",
    dateStringFormatReverseLong: "DD MMMM YYYY HH:mm",
    dateStringFormatReverseTabular: "D/M/YYYY - HH:mm",
    selectedElementsLimit: 10,
    paginationLimit: 10,
    popoverAlertsLimit: 5,
    dateRangeLimit: 31, // days
    YAxisLogarithmicRange: 100,
    showSearchBar: <boolean>false,

    disableAuth: <boolean> false,
    DASHBOARD: "dashboard",
    SENSORI: "sensori",
    BOE: "boe",
    COMPOSE_CUSTOM_CHART: "personalizzazione-grafico",
    VIEW_CUSTOM_CHART: "grafico-personalizzato",
    LOGIN: "login",
    APP_NAME: "TETI",
  };

  public static get = (key) => Config.defaults[key];
  public static set = (key, value) => (Config.defaults[key] = value);
}

export const _c = (key)=> Config.get(key);
export const _s = (key,value) => Config.set(key,value);
