import { EventHandler } from '@n7-frontend/core';

export class BuoysMapEH extends EventHandler {
  public listen() {

    this.innerEvents$.subscribe(({ type, payload }) => {
      switch(type){
        case 'buoys-map.marker-click': 
          this.emitOuter('marker-click', payload);
          break;

        default:
          break;
      }
    });
  }
}