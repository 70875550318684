import { AfterContentChecked, Component, Input } from '@angular/core';
import { diPublic } from '@angular/core/src/render3/di';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'iwt-form-annotations',
  templateUrl: './form-annotations.html'
})
export class FormAnnotationsComponent{


  constructor(private sanitizer: DomSanitizer){}

  //@Input() lb: any;
  @Input() data: any;
  @Input() emit: any; 


   onLabelChange(value) {
    if (!this.emit) return;
    this.emit('labelChange',   value );
  } 

  onDescriptionChange(value) {
    if (!this.emit) return;
    this.emit('descriptionChange',   value );
  } 

  onFilechange(event: any) {
    this.emit('file-change', event.target.files)
  }

  onDownloadClick(){
    this.emit('download-click');
  }

  onRemoveUploadFile(i){
    this.emit('remove-attachment', i);
  }

 public bypassSecurityTrustUrl(url){
    return this.sanitizer.bypassSecurityTrustUrl(url)

  }
}