import { DataSource } from '@n7-frontend/core';
import { BUOYS_KEY, VIEWS_KEY, _c } from '@app/constants';

export interface Map<Type> {
  [key: string]: Type
}
export class SubnavDS extends DataSource {
  private buoyId: string | null;
  private sensorId: string | null;
  private activeId: string | null;
  public hidden: boolean = true;

  protected transform() {
    if (!this.buoyId) return;

    const sensors = _c(BUOYS_KEY)[this.buoyId].sensors_configuration;
    const items = sensors
      .map(sensor => {
        const path = sensor.id;
        const disabled = !sensor.elements.length
        const _meta = { id: path, disabled };
        return {
          text: sensor.label,
          payload: { path, disabled },
          _meta
        }
      });


    this._setState(items);

    return {
      classes: 'iwt-nav',
      items
    }
  }

  onRouterChange(payload) {
    const params = payload.params;
    const buoyId = params.buoyId;
    const sensorId = params.sensorId;
    this.hidden = true;
    if (buoyId && sensorId) {
      this.buoyId = buoyId;
      this.sensorId = sensorId;
      this.activeId = sensorId;
      this.hidden = false;
      this.update();
    }

    if (this.output) this._setState(this.output.items);
  }

  getBuoyId = () => this.buoyId;
  getSensorId = () => this.sensorId;
  isVisible = () => !this.hidden;

  private _setState(items) {
    items.forEach(item => {

      if (this.activeId === item._meta.id) {
        item.classes = 'is-current';
      } else {
        item.classes = item._meta.disabled ? 'is-disabled' : '';
      }
    });
  }
}
