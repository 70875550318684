export type HomeLayoutChart = {
  elId: string;
  inputEnabled: boolean;
  data: ChartWithData
}

export type NormalChartWithData = {
  id: string,
  name: string,
  uiPosition: number,
  type: "NORMAL",
  interval: ChartInterval
  data: ChartData
}
export type CompareChartWithData = {
  id: string,
  name: string,
  uiPosition: number,
  type: "COMPARE",
  interval: ChartInterval
  data: ChartData,
  comparePeriod: ChartComparePeriod,
  compareData: ChartData
}

export type ChartWithData = NormalChartWithData | CompareChartWithData;

export type NormalChartNoData = {
  id: string,
  name: string,
  uiPosition: number,
  type: "NORMAL",
  interval: ChartInterval,
  seriesIds: string[]
}

export type CompareChartNoData = {
  id: string,
  name: string,
  uiPosition: number,
  type: "COMPARE",
  interval: ChartInterval,
  comparePeriod: ChartComparePeriod,
  seriesIds: string[],
  compareSeriesIds: string[],
}

export type ChartNoData = NormalChartNoData | CompareChartNoData;

export type ChartData = {
  categories: string[];
  series: ChartSeries[];
  annotations?: Annotation[]
}

export type ChartSeries = {
  id: string,
  name: string,
  data: (number | ChartSeriesData)[],
  metadata: any
}

export type ChartSeriesData = {
  value: string,
  metadata?: any
}

export type ChartStaticInterval = {
  type: 'static',
  from: string;
  to: string;
}

export type ChartDynamicInterval = {
  type: 'dynamic',
  value: string | number,
  unit: 'days' | 'hours'
}

export type ChartInterval = ChartStaticInterval | ChartDynamicInterval;

export type ChartDynamicComparePeriod = {
  type: 'dynamic'
  value: string | number,
  unit: 'interval' | 'days' | 'month' | 'years'
}

export type ChartStaticComparePeriod = {
  type: 'static'
  from: string;
  to: string;
}

export type ChartComparePeriod = ChartDynamicComparePeriod | ChartStaticComparePeriod;

export type DateRange = {
  from?: string | Date,
  to?: string | Date
}

export type SeriesDataRequest = {
  seriesIds: string[]
  range?: DateRange
}

export type Annotation = {
  id?: string,
  x1: number,
  x2?: number,
  description?: string,
  label: string,
  attachment?: string | string[]
  chart?: string
}

export const NormalChartType = "NORMAL";
export const CompareChartType  = "COMPARE";

export type ChartTypes = "NORMAL" | "COMPARE";
