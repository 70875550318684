import { Component } from '@angular/core';
import { LayoutBuilder } from '@n7-frontend/core';
import { TranslateService } from '@ngx-translate/core';
import tippy from 'tippy.js';

import * as DS from '@app/common/data-sources';
import * as EH from '@app/common/event-handlers';
import { HomeLayoutEH } from './home-layout.eh';
import { HomeLayoutDS } from './home-layout.ds';
import { CommunicationService } from '@app/services/communication.service';
import { ChartNoData } from './home-layout.types';


@Component({
  selector: 'home-layout',
  templateUrl: './home-layout.html'
})
export class HomeLayoutComponent {
  public lb = new LayoutBuilder('home-layout');
  private widgets: any[] = [
    { id: 'chart-menu', hasStaticData: true },
    { id: 'modal-delete-widget', hasStaticData: true },
    { id: 'modal-reorder-widgets', hasStaticData: true },
    { id: 'sortable-widgets' }
  ];

  constructor(
    private communication: CommunicationService,
    private translate: TranslateService,
  ){}

  ngOnInit(){
    this.communication.request$('/chart/get_all').subscribe(({results}) => {
      this._handleResponse({ charts: results });
    });
  }

  private _handleResponse(response: {charts:ChartNoData[]}){
    // added widgets dinamically
    response.charts.forEach((chart, index) => {
      this.widgets.push({
        id: `chart-${index}`,
        dataSource: DS.ChartDS,
        eventHandler: EH.ChartEH,
      });
      this.widgets.push({
        id: `chart-${index}-title`,
        dataSource: DS.ChartTitleDS,
      });
      this.widgets.push({
        id: `chart-${index}-legend`,
        dataSource: DS.ChartLegendDS,
        eventHandler: EH.ChartLegendEH,
        options: {
          chartId: chart.id,
          isDashboardChart: true,
          hasSensorLabel: true
        }
      });
    });

    // on ready
    this.lb.ready$.subscribe(() => {
      this.lb.eventHandler.emitInner('init', {
        communication: this.communication,
        translate: this.translate,
        tippy: tippy,
        response
      });
    });

    this.lb.init({
      widgetsConfig: this.widgets,
      widgetsDataSources: DS,
      widgetsEventHandlers: EH,
      dataSource: new HomeLayoutDS(),
      eventHandler: new HomeLayoutEH(),
    });
  }

  ngOnDestroy(){
    this.lb.eventHandler.emitInner('destroy');
  }

}