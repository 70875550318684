import { Component, Input} from '@angular/core';

export interface IYAxisCheckbox {
  isCheckboxDisabled: boolean;
  tooltip: string;
  classes?: string;
  payload?: any;
  _meta?: any;
}

@Component({
  selector: 'iwt-y-axis-checkbox',
  templateUrl: './y-axis-checkbox.html'
})
export class YAxisCheckbox {
  @Input() data: IYAxisCheckbox;
  @Input() emit: any;

  onClick(payload: any) {
    if (!this.emit) return;
    this.emit("click", payload);
  }

  onCheck(payload: any) {
    if (!this.emit) return;
    this.emit("y-axis_checkbox_change", payload);
  }
}
