import { LayoutDataSource } from '@n7-frontend/core';
import { LABELS_KEY, SENSORS_KEY, _c } from '@app/constants';
import { Observable, of, Subject } from 'rxjs';
import { UserService, AuthService } from '@app/services';

export class MainLayoutDS extends LayoutDataSource {
  private translate: any;
  private router: any;
  private user: UserService;
  private tippy: any;
  private auth: AuthService;
  private userMenu: any;
  private userMenuOpen: boolean = false;
  private alertsMenu: any;
  private alertsMenuOpen: boolean = false;
  private sensorsConfig: any;
  private pageTitle: string = '';
  private labels: any;
  public elementConfig: any;
  public isLogin: boolean = true;
  public alertData$: Subject<any> = new Subject();


  public modalAlarmStatus: any = {
    error: null,
    success: null,
    loading: false
  };

  public pageTools: any[] | null;

  onInit(payload){
    this.translate = payload.translate;
    this.router = payload.router;
    this.tippy = payload.tippy;
    this.user = payload.user;
    this.auth = payload.auth;
    this.labels = _c(LABELS_KEY).main;
    // preloaded translations
    this.updateWidgets();

    // config
    this.sensorsConfig = _c(SENSORS_KEY);
  }

  onSubnavClick(payload){
    this.router.navigate(payload);
  }

  onHeaderClick(payload){
    if(payload.source === 'nav'){
      this.router.navigate([payload.path]);
    } else if(payload.source === 'userButton'){
      this._toggleUserMenu();
    } else if(payload.source === 'alertsButton'){
      this._toggleAlertsMenu();
    }
  }

  onUserMenuClick(payload){
    if(!payload) return;
    
    if(payload === 'logout'){
      // this.auth.logout().subscribe(() => this.router.navigate(['/login']));
      this.auth.logout().subscribe(() => location.reload());
    } else {
      this.router.navigate([payload]);
    }
  }

  onLoadChart(payload){
    this.router.navigate(payload);
  }

  onBreadcrumbClick(payload){
    if(payload) this.router.navigate(payload);
  }

  onRouterChange({ data, params, url }){
    this.pageTitle = '';
    this.isLogin = (data.source === 'login' ? true : false);
    
    if(data && data.title){
      this.pageTitle = data.title;
    }

    this._updatePageTools({ data, params, url });

    // hide tooltips
    this.tippy.hideAll();

    // hide alert
    this.alertData$.next(null);
  }

  getPageTitle = () => this.pageTitle;

  onOpenAlertModal(payload){
    // reset 
    this.modalAlarmStatus = { success: null, error: null, loading: false };
    this.some([
      'modal-alarm-create',
      'form-alarm-create'
    ]).update(payload);
  }

  onModalAlarmClick(payload): Observable<any> {
    if(payload.source === 'save'){
      const ds = this.widgets['form-alarm-create'].ds;
      const formState = ds.getFormState();
      const input = ds.getWidgetInput();
      return;
    } else {
      of(null);
    }
  }

  onNavigate(payload){
    this.router.navigate(payload);
  }

  onAlarmCreated(response, payload){
    if(payload.isUpdate){
      this._updateAlertData({ text: this.labels.alarm_update.success_msg });
    } else {
      this._updateAlertData({ text: this.labels.alarm_create.success_msg });
    }
    this.widgets['form-alarm-create'].ds.reset();
  }

  closeAlert(){
    console.count('close-alert');
    this.alertData$.next(null);
  }

  getUserAvatar = () => this.user.getAvatar();

  private _updateAlertData(config){
    this.alertData$.next({
      text: config.text,
      hasCloseButton: typeof config.hasCloseButton === 'boolean' ? config.hasCloseButton : true,
      payload: "close",
      classes: config.classes || "is-success" 
    });
  }

  private updateWidgets(){

    this.some(['header', 'subnav', 'breadcrumbs', 'modal-alarm-create']).updateOptions({ labels:this.labels });
    this.some(['header', 'subnav', 'breadcrumbs']).update({});
    
    this.one('list-alerts-table').updateOptions({ labels:this.labels });
  }

  private _toggleUserMenu(){
    if(!this.userMenu){
      const template = document.getElementById('user-menu')
      template.style.display = 'block';
  
      this.userMenu = this.tippy('.n7-header__user-img', {
        content: template,
        trigger: 'manual',
        interactive: true,
        arrow: true,
        theme: 'light-border',
        onHidden: () => this.userMenuOpen = false,
      })[0];
    }
    
    if(this.userMenuOpen){
      this.userMenu.hide();
    } else {
      this.userMenu.show();
    }

    this.userMenuOpen = !this.userMenuOpen;
  }

  private _toggleAlertsMenu(){
    if(!this.alertsMenu){
      const template = document.getElementById('alerts-menu-loading');
      template.style.display = 'block';
  
      this.alertsMenu = this.tippy('.header-action-alerts', {
        content: template,
        trigger: 'manual',
        interactive: true,
        arrow: true,
        theme: 'light-border',
        onHidden: () => this.alertsMenuOpen = false,
      })[0];
    }
    
    if(this.alertsMenuOpen){
      this.alertsMenu.hide();
    } else {
      this.alertsMenu.show();
    }

    this.alertsMenuOpen = !this.alertsMenuOpen;
  }

  private _updatePageTools({ data, params, url }){
    // reset
    this.pageTools = null;
    if(data.source === _c('VIEW_CUSTOM_CHART')){
      this.pageTools = [{
        label: 'i18n.layouts.customized_view.create_new',
        payload: {
          source: 'create-custom-chart',
          id: params.chartId
        }
      }];
    } else if(data.source === _c('DASHBOARD')){
      this.pageTools = [{
        label: 'i18n.layouts.home.reorder',
        payload: {
          source: 'open-reorder-widgets-modal'
        }
      }];
    }
  }
}