import { EventHandler } from '@n7-frontend/core';
import { Subject } from 'rxjs';

export class BuoysLayoutEH extends EventHandler {
  private destroyed$: Subject<any> = new Subject();

  public listen() {
    // listen to inner (layout) events
    this.innerEvents$.subscribe(event => {
      switch(event.type){
        case 'buoys-layout.init':
          this.dataSource.onInit(event.payload);
          break;

        case 'buoys-layout.destroy':
            this.destroyed$.next();
            break;
            
        default:
          break;
      }
    });

    this.outerEvents$.subscribe(event => {
      switch(event.type){
        case 'buoys-map.marker-click':
          this.dataSource.onBuoySelect(event.payload);
          break;

        default:
          break;
      }
    });
  }

}