import { ChartComparePeriod, ChartDynamicComparePeriod, ChartDynamicInterval, ChartInterval, DateRange } from "@app/layouts/home-layout/home-layout.types";
import { dateHelpers } from "./date-helpers";
import { FiltersHelper } from "./filters-helper";

export class IntervalHelper {
  public static getDynamicIntervalLabel= (interval: ChartDynamicInterval) => {
    const { options } = FiltersHelper.getDateFilter();
    const { value, unit } = interval;
    const option = options.find((o) => o.value === value && unit === o.unit);
    if (!option) {
      return `i18n.chart_select.options.${unit}${value}`;
    } else {
      return option.label;
    }
  };
  public static getDefaultInterval = ():ChartInterval => {
    const {options}  = FiltersHelper.getDateFilter();
    const { value } = options.find(opt=> opt.default);
    return {...value}
  }
  public static convertToDateRange = (interval: ChartInterval): DateRange =>{
    if(!interval){
      return{};
    }
    if(interval.type === 'dynamic'){
      const to = new Date(); 
      const from = dateHelpers.subtract(to, interval.value, interval.unit);
      return {
        from, 
        to
      }
    }else{
      return{
        from: interval.from,
        to: interval.to
      }
    }
  }

  public static getDiff = (interval: ChartInterval): number => {
      if(!interval) return;
      const {from, to} =  interval.type == 'dynamic' ? IntervalHelper.convertToDateRange(interval): interval;
      return dateHelpers.diff(to, from, 'hours');
  }
};

export class ComparePeriodHelper {
  public static getDynamicComparePeriodLabel= (period: ChartDynamicComparePeriod, interval?: ChartDynamicInterval) => {
    const { options } = FiltersHelper.getCompareFilter();
    const { value, unit } = period;
    const option = options.find((o) => o.value === value && unit === o.unit);
    if (!option) {
      return `i18n.chart_compare.select_options.${unit}${value}`;
    } else {
      return option.label;
    }
  };
  public static getDefaultComparePeriod = (): ChartComparePeriod => {
    const {options}  = FiltersHelper.getCompareFilter();
    const { value } = options.find(opt=> opt.default);
    return {...value}
  }

  public static convertToDateRange = (period: ChartComparePeriod, interval?: ChartInterval): DateRange =>{
    if(!period){
      return{};
    }
    if(period.type === 'dynamic'){
      const now = new Date();
      const {from, to} = IntervalHelper.convertToDateRange(interval);
      const diff = dateHelpers.diff(to, from, 'hours');
      if(period.unit === 'interval' ){
        const value = typeof period.value === 'string' ? parseInt(period.value) : period.value
        const endDate = dateHelpers.subtract(from, diff*value, 'hours');
        const startDate = dateHelpers.subtract(endDate,diff,'hours');
        return {
          to: endDate,
          from: startDate
        }
      }else{
        return {
          from: dateHelpers.subtract(from, period.value ,period.unit),
          to: dateHelpers.subtract(to, period.value ,period.unit)
        }
      }
    }else{
      return{
        from: period.from,
        to: period.to
      }
    }
  }

};
