import { DataSource } from '@n7-frontend/core';
import { ChartSelectFilterData } from '@app/components/chart-select-filter/chart-select-filter';
import { dateHelpers } from '@app/helpers/date-helpers';
import { _c } from '@app/constants';

export class ChartSimulationsSelectDS extends DataSource {
  public selectedLabel: string;

  protected transform(data): ChartSelectFilterData {
    if(!data) return;

    const { elementId, sectionId, selectedSimulation } = this.options;
    const items: any[] = [];

    data.items.forEach(item => {
      const { name, startDate } = item;
      const isSelected = selectedSimulation.id === item.id; 
      const label = `${name} - ${dateHelpers.toString(startDate, _c('dateStringFormatReverseShort'))}`;
      if (isSelected) {
        this.selectedLabel = label;
      }

      items.push({
        label,
        isSelected,
        payload: {
          isSelected,
          elementId,
          sectionId,
          _id: item.id,
        },
        _meta: {
          id: item.id
        }
      })
    });

    // custom date
    items.push({
      label: 'i18n.simulations_select.view_all',
      payload: {
        _id: 'custom',
      },
      _meta: {
        id: 'custom'
      }
    })

    return { items, classes: 'simulations-select' };
  }
}
