import { DataSource } from '@n7-frontend/core';
import { dateHelpers, FiltersHelper } from '@app/helpers';

export class ChartDatepickerCompareDS extends DataSource {
  private _datepicker: any = null;
  private _previousSelectedDates = null;
  protected transform(data) {
    if(!data) return;


    const {custom} = FiltersHelper.getCompareFilter();

    return {
      // to use on chart updates
      _setDatepicker: (datepicker) => this._datepicker = datepicker,
      _elementId: 'datepicker-compare',
      options: {
        enableTime: custom.enableTime,
        dateFormat: 'Y-m-d H:i',
        mode: "single"
      }
    };
  }

  open(){
    setTimeout(() => this._datepicker.open());
  }

 }
