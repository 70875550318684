
import { Routes } from '@angular/router';
import { Page404LayoutComponent } from './layouts/page-404-layout/page-404-layout';

const routes: Routes = [];

routes.push(  {
  path: '',
  redirectTo: '/home',
  pathMatch: 'full'
},{ 
  path: '**', 
  component: Page404LayoutComponent 
})
export const APP_ROUTES = routes;
