import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LayoutBuilder } from '@n7-frontend/core';

import * as DS from '@app/common/data-sources';
import * as EH from '@app/common/event-handlers';
import { ChartLayoutEH } from './chart-layout.eh';
import { ChartLayoutDS } from './chart-layout.ds';
import { Utils } from '@app/providers';
import { combineLatest } from 'rxjs/operators';
import { CommunicationService } from '@app/services/communication.service';
import { DropdownDS } from '@app/common/data-sources';
import tippy from 'tippy.js';

@Component({
  selector: 'chart-layout',
  templateUrl: './chart-layout.html'
})
export class ChartLayoutComponent implements OnInit {
  public lb = new LayoutBuilder('chart-layout');
  private widgets = [
    { id: 'chart' },
    { id: 'chart-legend' },
    { id: 'item-filter-dropdown', dataSource: DropdownDS},
    { id: 'date-filter-dropdown', dataSource: DropdownDS},
    { id: 'compare-filter-dropdown', dataSource: DropdownDS},
    { id: 'date-select-filter' },
    { id: 'item-select-filter'},
    { id: 'chart-simulations-select' },
    { id: 'chart-datepicker' },
    { id: 'compare-select-filter', hasStaticData: true },
    { id: 'chart-datepicker-compare' },
    { id: 'chart-actions', hasStaticData: true },
    { id: 'chart-alerts-table' },
    { id: 'y-axis-checkbox' },
    { id: 'modal-annotations', hasStaticData: true },
    { id: 'form-annotations' },
    { id: 'annotation-menu', hasStaticData: true},
    //{ id: 'annotation-datepicker'}
  ];
 
  constructor(
    private utils: Utils,
    private communication: CommunicationService,
    private route: ActivatedRoute,
  ){}

  ngOnInit(){
    // on ready
    this.lb.ready$
      .pipe(
        combineLatest(
          this.route.paramMap,
          this.route.data
        )
      ).subscribe(([, params, routeData]) => {
      const buoyId = params.get('buoyId');
      const sensorId = params.get('sensorId');
      const elementId = params.get('elementId');
      const chartId = params.get('chartId');
      const source = routeData.source;
      // and utils to make api calls
      this.lb.eventHandler.emitInner('init', {
        utils: this.utils,
        communication: this.communication,
        tippy: tippy,
        buoyId,
        sensorId,
        elementId,
        chartId,
        source
      });
    });

    this.lb.init({
      widgetsConfig: this.widgets,
      widgetsDataSources: DS,
      widgetsEventHandlers: EH,
      dataSource: new ChartLayoutDS(),
      eventHandler: new ChartLayoutEH(),
    });
  }

  ngOnDestroy(){
    this.lb.eventHandler.emitInner('destroy');
  }

  onAnnotationsClick(){
    this.lb.eventHandler.emitInner('annotation-click')

  }
}
