import { DataSource } from '@n7-frontend/core';
import { IModalData } from '@app/components/modal/modal';

export class ModalAnnotationsDS extends DataSource {
  private xmin: number;
  private xmax: number;

  protected transform(): IModalData {
    return {
      isVisible: true,
      classes: 'iwt-layout-home__modal-reorder-widgets',
      header: {
        label: 'i18n.modal_annotations.title',
        closeButton: {
          payload: 'dismiss'
        }
      },
      footer: {
        actions: [{
          label: 'i18n.cancel',
          payload: 'dismiss'
        }, {
          buttonClasses: 'n7dash-btn-ok',
          label: 'i18n.modal_annotations.button',
          payload: 'save'
        }]
      }
    };
  }

  onOpen(payload){
    this.output.isVisible = true;

  }

  onDismiss(){
    this.output.isVisible = true;
  }
}
