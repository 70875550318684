import { DataSource } from '@n7-frontend/core';

export class AnnotationMenuDS extends DataSource {
  protected transform(data) {
    return {
      items: [{
        label: 'i18n.annotation_menu.edit',
        icon: 'n7-icon-edit',
        payload: 'edit'
      }, {
        label: 'i18n.annotation_menu.remove',
        icon: 'n7-icon-trash',
        payload: 'remove'
      }]
    }
  }
}
