import { DataSource } from '@n7-frontend/core';
import {_c} from '@app/constants'

export class YAxisCheckboxDS extends DataSource {
  private _chartMax: number;
  private _chartMin: number;
  public isCheckboxDisabled: boolean = false;

  protected transform(chartData){
    if (!chartData) return

    const {series} = chartData.data

    series.forEach((seriesItem) => {
      const { data } = seriesItem;
      data.forEach((value) => {

        if(!this._chartMax || this._chartMax<value){
          this._chartMax = value;
        }

        if(!this._chartMin || this._chartMin>value){
          this._chartMin = value;
        }

       });
    });

    this.isCheckboxDisabled = this._chartMax - this._chartMin <  _c("YAxisLogarithmicRange");
    let data = {"isCheckboxDisabled": this.isCheckboxDisabled,
                tooltip: 'i18n.chart_y-axis.checkbox_tooltip'}

   return data
  }
}
