import { NgModule, APP_INITIALIZER } from '@angular/core';

import { AppComponent } from '@app/app.component';
import { APP_IMPORTS } from '@app/app.imports';

import { Configuration } from './providers/configuration.provider';
import { UserService } from './services/user.service';
import { AuthService } from './services/auth.service';
import { AuthGuard } from './auth/auth.guard';
import {  Utils } from './providers';
import { CommunicationService } from './services/communication.service';
// components
import { WrapperComponent } from '@app/components/wrapper/wrapper';
import { IWTManageAlerts } from '@app/components/manage-alerts/manage-alerts';
import { IWTModal } from '@app/components/modal/modal';
import { Chart } from '@app/components/chart/chart';
import { ChartLegend } from '@app/components/chart-legend/chart-legend';
import { IWTMiniCard } from '@app/components/mini-card/mini-card';
import { YAxisCheckbox } from '@app/components/y-axis-checkbox/y-axis-checkbox';
import { DropdownComponent } from '@app/components/dropdown/dropdown';
import { ChartSelectFilterComponent } from '@app/components/chart-select-filter/chart-select-filter';
import { Datepicker } from '@app/components/datepicker/datepicker';
import { FooterComponent } from '@app/components/footer/footer';
import { ElementsAccordionComponent } from '@app/components/elements-accordion/elements-accordion';
import { IWTDataFileUploadForm } from '@app/components/datafile-upload-form/datafile-upload-form';
import { PopoverMenuComponent } from '@app/components/popover-menu/popover-menu';
import { IWTStepsIndicator } from '@app/components/steps-indicator/steps-indicator';
import { IWTParametersPick } from '@app/components/parameters-pick/paramaters-pick';
import { ChartActions } from '@app/components/chart-actions/chart-actions';
import { FormAlarmCreateComponent } from '@app/components/form-alarm-create/form-alarm-create';
import { SortableList } from '@app/components/sortable-grid/sortable-grid';

// layouts
import { LoginLayoutComponent } from '@app/layouts/login-layout/login-layout';
import { MainLayoutComponent } from '@app/layouts/main-layout/main-layout';
import { HomeLayoutComponent } from '@app/layouts/home-layout/home-layout';
import { ChartLayoutComponent } from '@app/layouts/chart-layout/chart-layout';
import { CustomizedViewLayoutComponent } from '@app/layouts/customized-view-layout/customized-view-layout';
import { ElementsListLayoutComponent } from '@app/layouts/elements-list-layout/elements-list-layout';
import { Page404LayoutComponent } from '@app/layouts/page-404-layout/page-404-layout';
import { BuoysLayoutComponent } from './layouts/buoys-layout/buoys-layout';
import { MapComponent } from './components/map/map';
import { FormAnnotationsComponent } from './components/form-annotations/form-annotations';


@NgModule({
  declarations: [
    // components
    AppComponent,
    WrapperComponent,
    IWTManageAlerts,
    IWTModal,
    Chart,
    ChartLegend,
    IWTMiniCard,
    DropdownComponent,
    ChartSelectFilterComponent,
    Datepicker,
    FooterComponent,
    ElementsAccordionComponent,
    IWTDataFileUploadForm,
    PopoverMenuComponent,
    IWTStepsIndicator,
    IWTParametersPick,
    ChartActions,
    FormAlarmCreateComponent,
    FormAnnotationsComponent,
    SortableList,
    MapComponent,
    YAxisCheckbox,

    // layouts
    LoginLayoutComponent,
    MainLayoutComponent,
    HomeLayoutComponent,
    ChartLayoutComponent,
    CustomizedViewLayoutComponent,
    ElementsListLayoutComponent,
    BuoysLayoutComponent,
    Page404LayoutComponent
  ],
  imports: APP_IMPORTS,
  providers: [
    CommunicationService,
    Utils,
    AuthGuard,
    AuthService,
    UserService,
    Configuration,
    {
      provide: APP_INITIALIZER,
      useFactory: (configService: Configuration) => () => configService.load(),
      deps: [Configuration],
      multi: true
    }
  ],
  bootstrap: [AppComponent],
  entryComponents:[
    LoginLayoutComponent,
    MainLayoutComponent,
    HomeLayoutComponent,
    ChartLayoutComponent,
    CustomizedViewLayoutComponent,
    ElementsListLayoutComponent,
    BuoysLayoutComponent,
    Page404LayoutComponent]
})
export class AppModule { }
