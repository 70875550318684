import { DataSource } from '@n7-frontend/core';
import { VALUES_KEY, SENSORS_KEY, _c, BUOYS_KEY } from '@app/constants';
import { ChartWithData } from '@app/layouts/home-layout/home-layout.types';

export class ChartTitleDS extends DataSource {
  protected transform(chart: (ChartWithData & {chartId:string})) {
    if (!chart) return;

    let items = [];
    if (chart.name) {
      items.push({ label: chart.name });
    } else {
      const valuesConfig = _c(VALUES_KEY);
      const sensorsConfig = _c(SENSORS_KEY);
      const buoyConfig = _c(BUOYS_KEY);
      if(chart.data && chart.data.series.length){
        const { id } = chart.data.series[0];
        const [buoyId, sensorId, ...others ] = id.split("-");
        const buoyLabel = buoyConfig[buoyId].label;
        const sensorLabel = sensorsConfig[`${buoyId}-${sensorId}`].label;
        const valueLabel = valuesConfig[id].label;
  
        items.push({ label: buoyLabel });
        items.push({ label: sensorLabel });
        items.push({ label: valueLabel });
      }
    }
    return { items };
  }
}
