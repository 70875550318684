export const GET_HOME_CHARTS_DATA_MOCK = {
  results: [
      {
        "id": "ncr7-CHART-b2i0",
        "name": " Dashboard Chart 1",
        "uiPosition": 0,
        "type": "NORMAL",
        "interval":{
          "type": "static",
          "from": "2019-07-10T00:00:00.000Z",
          "to": "2019-07-17T00:00:00.000Z",
        },
        "seriesIds": ['min_cd','max_cd']
      },
      {
        "id": "ncr7-CHART-b2i04",
        "name": "My custom chart",
        "uiPosition": 1,
        "type": "NORMAL",
        "interval":{
          "type": "dynamic",
          "value": "3",
          "unit": "days"
        },
        "seriesIds": ['min_cd']
      },
      {
        "id": "ncr7-CHART-b2i03",
        "name": " Dashboard Chart 3",
        "uiPosition": 2,
        "type": "NORMAL",
        "interval":{
          "type": "dynamic",
          "value": "3",
          "unit": "days"
        },
        "seriesIds": ['max_cd']
      },
      {
        "id": "ncr7-CHART-b2i02",
        "name": " Dashboard Chart 4",
        "uiPosition": 3,
        "type": "NORMAL",
        "chartPeriod": "FIXED_DATES",
        "seriesIds": ['min_cd']
      },
    ]
};