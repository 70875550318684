import { Route, Routes } from "@angular/router";
import { AuthGuard } from "@app/auth";
import { _c } from "@app/constants";
import {
  ChartLayoutComponent,
  CustomizedViewLayoutComponent,
  ElementsListLayoutComponent,
  HomeLayoutComponent,
  LoginLayoutComponent,
} from "@app/layouts";
import { BuoysLayoutComponent } from "@app/layouts/buoys-layout/buoys-layout";
import { Page404LayoutComponent } from "@app/layouts/page-404-layout/page-404-layout";

export class RoutesManager {

  public static getRoutes = (conf): Routes => {
    const { views } = conf;

    const updatedRoutes: Routes = [];
    if (views.dashboard.enabled) {
      const routes = RoutesManager.getDashboardRoute(views.dashboard);
      updatedRoutes.push(...routes);
    }
    if (views.customChartView.enabled) {
      const routes = RoutesManager.getCustomViewRoute(views.customChartView);
      updatedRoutes.push(...routes);
    }
    if (views.itemsView.enabled) {
      const routes = RoutesManager.getItemsRoute(views.itemsView);
      updatedRoutes.push(...routes);
    }
    if (views.login.enabled) {
      const routes = RoutesManager.getLoginRoute();
      updatedRoutes.push(...routes);
    }

    updatedRoutes.push(
      {
        path: "",
        redirectTo: `/${_c('DASHBOARD')}`,
        pathMatch: "full",
      },
      {
        path: "**",
        component: Page404LayoutComponent,
      }
    );
    return updatedRoutes;
  };
  private static getDashboardRoute = (conf: {
    disableGuard?: true;
  }): Route[] => {
    const { disableGuard } = conf;
    return [
      {
        path: _c('DASHBOARD'),
        component: HomeLayoutComponent,
        canActivate: disableGuard ? [] : [AuthGuard],
        data: {
          title: "i18n.top_headers.home",
          source: _c('DASHBOARD'),
        },
      },
    ];
  };
  private static getItemsRoute = (conf: {
    disableGuard?: true;
  }): Route[] => {
    const {  disableGuard } = conf;
    const guards = disableGuard ? [] : [AuthGuard];
    return [
      {
        path: `${_c('BOE')}`,
        component: BuoysLayoutComponent,
        canActivate: guards,
        data: { source: _c('BOE') },
      },
      {
        path: `${_c('BOE')}/:buoyId`,
        component: BuoysLayoutComponent,
        canActivate: guards,
        data: { source: _c('BOE') },
      },
      {
        path: `${_c('BOE')}/:buoyId/:sensorId`,
        component: ElementsListLayoutComponent,
        canActivate: guards,
        data: { source: _c('SENSORI') },
      },
      {
        component: ChartLayoutComponent,
        path: `${_c('BOE')}/:buoyId/:sensorId/:elementId`,
        canActivate: guards,
        data: { source: _c('SENSORI') },
      },
    ];
  };
  private static getCustomViewRoute = (conf: {
    disableGuard?: true;
  }): Route[] => {
    const { disableGuard } = conf;
    const guards = disableGuard ? [] : [AuthGuard];
    return [
      {
        path: _c('COMPOSE_CUSTOM_CHART'),
        component: CustomizedViewLayoutComponent,
        canActivate: guards,
        data: { title: "i18n.top_headers.custom_view",source: _c('COMPOSE_CUSTOM_CHART') },
      },
      {
        path: `${_c('VIEW_CUSTOM_CHART')}/:chartId`,
        component: ChartLayoutComponent,
        canActivate: guards,
        data: { title: "i18n.top_headers.custom_view", source: _c('VIEW_CUSTOM_CHART') },
      },
    ];
  };
  private static getLoginRoute = (): Route[] => {
    return [
      {
        path: _c('LOGIN'),
        component: LoginLayoutComponent,
        data: { source: _c('LOGIN') },
      },
    ];
  };
}
