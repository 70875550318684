import { LayoutDataSource } from '@n7-frontend/core';
import { map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { VALUES_KEY, LABELS_KEY, _c } from '@app/constants';
import { dateHelpers, helpers, IntervalHelper } from '@app/helpers';
import {ChartWithData, HomeLayoutChart } from './home-layout.types';
import { CommunicationService } from '@app/services/communication.service';

export class HomeLayoutDS extends LayoutDataSource {
  static newChartId: string | null;

  private communication: CommunicationService;
  private tippy: any;
  private response: { charts: ChartWithData[] };
  public labels;

  private chartMenus: any = {};
  private currentChartIdsOrder: string[];

  public charts: HomeLayoutChart[] = [];
  public modalDeleteDescription: string = "";
  public addedChartId: string;
  public placeholdersToShow: number = 2;
  public emptyCharts: any = {};

  onInit(payload) {
    this.communication = payload.communication;
    this.tippy = payload.tippy;
    this.response = payload.response as { charts: ChartWithData[] };
    const charts = this.response.charts;
    charts
      .sort((a, b) => a.uiPosition - b.uiPosition)
      .forEach((chart, index) =>
        this.charts.push({
          elId: `chart-${index}`,
          data: chart,
          inputEnabled: false,
        })
      );

    this.currentChartIdsOrder = this.charts.map(({ data }) => data.id);
    this._updatePlaceholdersToShow();
    this._highlightNewChart();
    this._loadTranslatedLabels();
    this._loadCharts();
  }

  onActionClick(payload) {
    this.toggleChartMenu(payload);
  }

  onChartMenuClick(payload) {
    const selectedChartId = this.getSelectedChartId();
    this.toggleChartMenu(selectedChartId);

    if (payload === "refresh") {
      this._handleChartRefresh(selectedChartId);
    } else if (payload === "rename") {
      this._handleChartRename(selectedChartId);
    }
  }

  onRemoveChart(chartId) {
    this._handleChartRemove(chartId);
  }

  onInputSubmit({ id, value: name }) {
    this.communication
      .request$("chart/update_name", { id, name })
      .subscribe((response) => {
        this.charts.forEach((chart) => {
          if (chart.data.id === response.id) {
            chart.data.name = name;
            chart.inputEnabled = false;
            // update
            this.one(`${chart.elId}-title`).update(chart.data);
          }
        });
      });
  }

  onOpenReorderWidgetsModal() {
    // update sortable list
    this.one("sortable-widgets").update({ charts: this.charts });
  }

  getSelectedChartId() {
    let id: any = null;
    Object.keys(this.chartMenus).forEach((chartId) => {
      if (this.chartMenus[chartId].open) id = chartId;
    });
    return id;
  }

  getChartDateText(data: ChartWithData) {
    const { interval } = data;
    if (!interval) return;

    if (interval.type === "dynamic") {
      return IntervalHelper.getDynamicIntervalLabel(interval);
    } else {
      return [
        dateHelpers.toString(
          interval.from,
          _c("dateStringFormatReverseShort")
        ),
        " - ",
        dateHelpers.toString(
          interval.to,
          _c("dateStringFormatReverseShort")
        ),
      ].join("");
    }
  }

  setModalDeleteDescription(chartId) {
    let label: any = "";
    this.charts.forEach(({ data }) => {
      if (data.id === chartId) {
        label = data.name;
        if (data.data && data.data.series[0] && data.data.series[0].id) {
          const elementsConfig = _c(VALUES_KEY);
          label = elementsConfig[data.data.series[0].id].label;
        } else {
          label = "";
        }
      }
    });
    this.modalDeleteDescription =
      this.labels.modal_delete_widget.description.replace("__NAME__", label);
  }

  toggleChartMenu(chartId) {
    if (!this.chartMenus[chartId]) {
      this.chartMenus[chartId] = { instance: null, open: false };

      const template = document.getElementById("chart-menu__" + chartId);
      template.style.display = "block";


      this.chartMenus[chartId].instance = this.tippy("#chart_" + chartId, {
        content: template,
        trigger: "manual",
        interactive: true,
        arrow: true,
        theme: "light-border",
        placement: "bottom",
        onHidden: () => (this.chartMenus[chartId].open = false),
      })[0];
    }

    if (this.chartMenus[chartId].open) {
      this.chartMenus[chartId].instance.hide();
    } else {
      this.chartMenus[chartId].instance.show();
    }

    this.chartMenus[chartId].open = !this.chartMenus[chartId].open;
  }

  getInputNamePlaceholder$(out$: Observable<any>): Observable<string> {
    return out$.pipe(
      map(({ items }) => {
        if (Array.isArray(items) && items.length) {
          if (items.length === 1) {
            return items[0].label;
          } else if (items.length === 2) {
            return items[1].label;
          }
        }
      })
    );
  }

  saveWidgetsOrderRequest$(chartIds) {
    if (this.currentChartIdsOrder.toString() !== chartIds.toString()) {
      return this.communication.request$("chart/update_chart_position", {
        chartIds,
      });
    }
    return of(null);
  }

  reorderCharts(response) {
    let uiPositions = {};
    response.forEach((item) => (uiPositions[item.id] = item.uiPosition));
    this.charts.forEach((chart) => {
      chart.data.uiPosition = uiPositions[chart.data.id];
    });

    // update current order
    this.currentChartIdsOrder = response.map((item) => item.id);

    // reorder
    this.charts = this.charts.sort(
      (a, b) => a.data.uiPosition - b.data.uiPosition
    );
  }

  getNewChartId = () => HomeLayoutDS.newChartId;

  private _updatePlaceholdersToShow() {
    if (this.charts.length % 2 != 0) {
      this.placeholdersToShow = 1;
    } else this.placeholdersToShow = 2;
  }

  private _handleChartRefresh(id) {
    // FIXME: da vedere con @giulio
    // se ha senso questa feature
  }
  private _handleChartRename(id) {
    this.charts.forEach((chart, index) => {
      if (chart.data.id === id) chart.inputEnabled = !chart.inputEnabled;
    });
  }
  private _handleChartRemove(id) {
    this.communication
      .request$("chart/delete", { id })
      .subscribe((response) => {
        let indexToRemove;
        this.charts.forEach((chart, index) => {
          if (chart.data.id === response.id) indexToRemove = index;
        });
        this.charts.splice(indexToRemove, 1);
        this._updatePlaceholdersToShow();
      });
  }

  protected _loadTranslatedLabels() {
    this.labels = _c(LABELS_KEY).dashboard;
  }

  private _loadCharts() {
    this.response.charts.forEach((chart: ChartWithData, index) => {
      const chartId = `chart-${index}`,
        titleId = chartId + "-title",
        legendId = chartId + "-legend";
      this.one(chartId).updateOptions({ labels: this.labels });
      this.some([titleId, legendId]).update({
        chartId: chartId,
        ...chart,
      });

      if (
        chart.data &&
        Array.isArray(chart.data.series) &&
        chart.data.series.length
      ) {
        this.one(chartId).update({
          chartId: chartId,
          ...chart,
        });
      } else {
        this.emptyCharts[chartId] = true;
      }
    });
  }

  private _highlightNewChart() {
    setTimeout(() => {
      let highlighted = document.getElementsByClassName("is-highlighted")[0];
      helpers.scrollToDomElement(highlighted);
    }, 50);

    setTimeout(() => {
      HomeLayoutDS.newChartId = null;
    }, 10000);
  }
}