import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { FILTERS_KEY, ELEMENTS_KEY, VALUES_KEY, LABELS_KEY, SENSORS_KEY, VIEWS_KEY, _c, _s, BUOYS_KEY } from '@app/constants';
import { RoutesManager } from '@app/helpers/routes.manager';
import { CommunicationService } from '@app/services/communication.service';
import { TranslateService } from '@ngx-translate/core';
import { APP_CONFIG } from './configuration/app.config';
import { LABELS_MAP } from './configuration/labels.map';

@Injectable()
export class Configuration {
  constructor(
    private communication: CommunicationService,
    private translateService: TranslateService,
    private injector: Injector
  ) { }

  private loaded = false;

  async load(): Promise<any> {
    const buoys = await this.communication.request$("/buoy", {}, null, "GET").toPromise();

    const CONF = APP_CONFIG(_c('APP_NAME'));
    const LABELS = LABELS_MAP();
    await this.loadTranslations(LABELS, CONF);
    await this.loadBuoysData(buoys, CONF.style);
    await this.loadStyle();
    await this.loadViews(CONF.views);
    await this.loadFilters(CONF.filters);
    await this.loadRoutes(CONF);
    this.loaded = true;
    return Promise.resolve(true);
  }

  public isLoaded() {
    return this.loaded;
  }

  private loadTranslations = async (LABELS, CONF) => {
    await this.translateLabels(LABELS);
    _s(LABELS_KEY, LABELS);
  };

  private translateLabels = async (conf): Promise<void> => {
    this.translateService.setDefaultLang("it");
    const keys = Object.keys(conf)
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      const translatedLabels = await this.translateMap(conf[key]);
      conf[key] = translatedLabels;
    }
  };

  private translateMap = async (map): Promise<any> => {
    const labelsKeys = Object.keys(map).map((e) => map[e]);
    // load translations
    const translatedLabels = await this.translateService
      .get(labelsKeys)
      .toPromise();
    let updatedLabels = {};
    Object.keys(map).forEach((key) => {
      const i18nKey = map[key];
      updatedLabels[key] = translatedLabels[i18nKey];
    });
    return updatedLabels;
  };

  private loadBuoysData(data, style) {
    const buoys = {};
    const sensors = {};
    const elements = {};
    const values = {};
    const modes = style.chart;
    data.forEach((buoy) => {
      const buoyId = buoy.id;
      buoys[buoyId] = buoy;

      buoy.sensors_configuration.forEach(sensor => {
        const sensorId = [buoyId, sensor.id].join("-");
        sensors[sensorId] = sensor;

        sensor.elements.forEach(element => {
          const elementId = [sensorId, element.id].join("-");
          elements[elementId] = {
            ...element,
            color: element.color,
            colorRgb: this._hexToRgb(element.color),
          };

          element.values.forEach(value => {
            let valueId;
            if (sensor.type === 'multiple-data') {
              valueId = [sensorId, value.id].join("-");
            } else {
              valueId = [elementId, value.id].join("-");
            }
            value.id = valueId;

            values[valueId] = {
              ...value,
              label: `${element.label} ${value.label}`,
              colorRgb: this._hexToRgb(value.color),
              unit: value.unit || "",
            };
            Object.keys(modes).forEach((modeKey) => {
              const mode = modes[modeKey];
              const valueIdMode = [valueId, modeKey].join("-");
              values[valueIdMode] = {
                ...values[valueId],
                id: valueIdMode,
                label: `${element.label} ${value.label} (${mode.label})`,
                color: mode.color,
                colorRgb: this._hexToRgb(mode.color),
                unit: value.unit || "",
              };
            });

          });
        });

      });
    });

    _s(BUOYS_KEY, buoys);
    _s(SENSORS_KEY, sensors);
    _s(ELEMENTS_KEY, elements);
    _s(VALUES_KEY, values);
  }


  private loadStyle() {
    const values = _c(VALUES_KEY);
    const elements = _c(ELEMENTS_KEY);
    const styles = [];
    const headTag = document.querySelector("head");
    const styleElement = document.createElement("style");
    const allItems = { ...values, ...elements };
    Object.keys(allItems).forEach((key) => {
      const item = allItems[key];
      styles.push(`.element-color-${item.id} { color: ${item.color}; }`);
      styles.push(
        `.element-bgcolor-${item.id} { background-color: ${item.color}; }`
      );
    });
    styleElement.appendChild(document.createTextNode(styles.join(" ")));
    headTag.appendChild(styleElement);
  }


  private loadViews = (views) => {
    _s(VIEWS_KEY, views);
  };

  private _hexToRgb(hex) {
    return hex
      .replace(
        /^#?([a-f\d])([a-f\d])([a-f\d])$/i,
        (m, r, g, b) => "#" + r + r + g + g + b + b
      )
      .substring(1)
      .match(/.{2}/g)
      .map((x) => parseInt(x, 16));
  }

  private loadFilters = (filters) => {
    _s(FILTERS_KEY, filters);
  };

  private loadRoutes = async (conf) => {
    const router = this.injector.get(Router);
    const routes = RoutesManager.getRoutes(conf);
    router.resetConfig(routes);
  };

}