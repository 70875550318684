import { Component, OnInit } from '@angular/core';
import { LayoutBuilder } from '@n7-frontend/core';

import * as DS from '@app/common/data-sources';
import * as EH from '@app/common/event-handlers';
import { BuoysLayoutDS } from './buoys-layout.ds';
import { BuoysLayoutEH } from './buoys-layout.eh';
import { Utils } from '@app/providers';
import { CommunicationService } from '@app/services/communication.service';
import { ActivatedRoute, Router } from '@angular/router';


@Component({
  selector: 'buoys-layout',
  templateUrl: './buoys-layout.html'
})
export class BuoysLayoutComponent implements OnInit {
  public lb = new LayoutBuilder('buoys-layout');
  private widgets = [
    { id: 'buoys-map' }
  ];

  constructor(
    private utils: Utils,
    private communication: CommunicationService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
  }

  ngOnInit(){
    // on ready
    this.lb.ready$.subscribe(() => {
      this.lb.eventHandler.emitInner('init', {
        utils: this.utils,
        communication: this.communication,
        router: this.router,
        activatedRoute: this.activatedRoute
      });
    });


    this.lb.init({
      widgetsConfig: this.widgets,
      widgetsDataSources: DS,
      widgetsEventHandlers: EH,
      dataSource: new BuoysLayoutDS(),
      eventHandler: new BuoysLayoutEH(),
    });
  }

  ngOnDestroy(){
    this.lb.eventHandler.emitInner('destroy');
  }

}