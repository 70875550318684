import { FILTERS_KEY, FILTER_COMPARE, FILTER_DATE, FILTER_ITEM, _c } from "@app/constants";

export class FiltersHelper {
  public static getDateFilter = () => {
    return _c(FILTERS_KEY)[FILTER_DATE];
  };
  public static getCompareFilter = () => {
    return _c(FILTERS_KEY)[FILTER_COMPARE];
  };
  public static getItemFilter = () => {
    return _c(FILTERS_KEY)[FILTER_ITEM];
  };
};
