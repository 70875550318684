import { EventHandler } from '@n7-frontend/core';

export class ItemFilterDropdownEH extends EventHandler {
  public listen() {

    this.innerEvents$.subscribe(event => {

      switch(event.type){

        case 'item-filter-dropdown.clickOut':
          this.dataSource.onClickOut(event);
          break;

        default:
          break;
      }
    });
  }
}
