import { FiltersHelper } from "./filters-helper";

export type ItemFilterType = {
  value: string;
  type: "regex"
}
export class ItemFilterHelper {
  public static getDefaultInterval = (): ItemFilterType=> {
    const {options}  = FiltersHelper.getItemFilter();
    const { value } = options.find(opt=> opt.default);
    return {...value}
  }

  public static getFilterLabel= (filter: ItemFilterType) => {
    if(!filter) return;
    const { options } = FiltersHelper.getItemFilter();
    const opt = options.find(opt=> opt.value.value === filter.value);
    return (opt && opt.label) || "";
  };

  public static filter = (values: string[], filter: ItemFilterType) => {
    if(!filter){
      return values;
    }
    return values.filter(value=>{
      if(filter.type === 'regex'){
        return new RegExp(filter.value).test(value);
      }else{
        return false;
      }
    })
  }
};