import { DataSource } from '@n7-frontend/core';
import { LABELS_KEY, VIEWS_KEY, _c } from '@app/constants';

export class HeaderDS extends DataSource {
  private activeId: string | null;
  private avatar: string;
  protected transform(data) {
     const {header} = _c(VIEWS_KEY);
    if(header.enabled){
      const items = this.buildNavItems();
      // active control
      this._setActive(items);
      header.nav = {items}
      return header;
    }else{
      return;
    }
  }

  onRouterChange(payload){
    const paths = payload.url.map(segment => segment.path);
    if(paths[0] === _c('VIEW_CUSTOM_CHART')){
      this.activeId = _c('COMPOSE_CUSTOM_CHART');
    } else if(paths[0] !== 'chart'){
      this.activeId = paths[0];
    }

    if(this.output) this._setActive(this.output.nav.items);
  }

  updateUserAvatar(avatar){
    this.avatar = avatar;
    if(this.output && avatar){
      this.output.user.img = avatar;
    }
  }

  private _setActive(items){
    items.forEach(item => {
      if(this.activeId === item._meta.id){
        item.classes = 'is-current';
      } else {
        item.classes = '';
      }
    });
  }

  private buildNavItems = ()=>{
    const {dashboard, customChartView, itemsView} = _c(VIEWS_KEY)
    const labels = _c(LABELS_KEY);
    const items = [ ];
    if (dashboard.enabled) {
      items.push({
        text: labels.main.home,
        payload: { source: "nav", path: "/" },
        icon: "n7-icon-dashboard",
        _meta: { id: _c('DASHBOARD') }
      });
    }
    if(itemsView.enabled){
      items.push(  {
        text: labels.main.buoys,
        payload: { source: "nav", path: `/${_c('BOE')}` },
        icon: "n7-icon-chart-line",
        _meta: { id: _c('BOE') },
      });
    }
    if(customChartView.enabled){
      items.push({
        text: labels.main.customized_view,
        payload: { source: "nav", path: `/${_c('COMPOSE_CUSTOM_CHART')}` },
        icon: "n7-icon-chart-lines",
        _meta: { id: _c('COMPOSE_CUSTOM_CHART')},
      })
    }
    
    return items;
  }
}
