
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { DvComponentsLibModule } from '@n7-frontend/components';
import { environment as env } from '@src/environments/environment';
import { APP_ROUTES } from '@app/app.routes';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, env.assetsPath + '/i18n/', '.json');
}

export const APP_IMPORTS = [
  BrowserModule,
  HttpClientModule,
  DvComponentsLibModule,
  RouterModule.forRoot(APP_ROUTES),
  TranslateModule.forRoot({
    loader: {
      provide: TranslateLoader,
      useFactory: (createTranslateLoader),
      deps: [HttpClient]
    }
  }),
];