import { EventHandler } from '@n7-frontend/core';

export class ChartDatepickerCompareEH extends EventHandler {
  public listen() {
    this.innerEvents$.subscribe(event => {
      switch(event.type){
        case 'chart-datepicker-compare.change':
          this.emitOuter('change', event.payload);
          break;

        default:
          break;
      }
    });

    this.outerEvents$.subscribe(event => {
      if(event.type === 'chart-layout.custom-date-compare-selected'){
        this.dataSource.open();
      }
    });
  }
}