import { EventHandler } from '@n7-frontend/core';

export class AnnotationMenuEH extends EventHandler {
  public listen() {
    this.innerEvents$.subscribe(({type, payload}) => {
      if(type === 'annotation-menu.click'){
        this.emitOuter('click', payload);
      }
    });
  }
}