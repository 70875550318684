import { BUOYS_KEY, _c } from '@app/constants';
import { LayoutDataSource } from '@n7-frontend/core';
import { map } from 'rxjs/operators';

export class BuoysLayoutDS extends LayoutDataSource {

  private communication;
  private router;
  private activatedRoute;
  public loading = false;

  onInit(payload) {
    this.communication = payload.communication;
    this.router = payload.router;
    this.activatedRoute = payload.activatedRoute;
    this.activatedRoute.paramMap.subscribe(paramMap => {
      const buoyId = paramMap.get("buoyId");
      if (!buoyId) {
        this.loadBuoys();
      } else {
        this.loadBuoyById(buoyId);
      }
    });
  }

  private loadBuoys() {
    this.loading = true;
    const buoysConfig = _c(BUOYS_KEY);
    const buoys = Object.values(Object.values(buoysConfig));
    this.one('buoys-map').update(buoys);
    this.loading = false;
  }

  private loadBuoyById(buoyId) {
    this.loading = true;
    const buoy = _c(BUOYS_KEY)[buoyId];
    this.onBuoySelect(buoy);
    this.loading = false;
  }

  public onBuoySelect(payload) {
    if (payload && payload.id && payload.sensors_configuration.length) {
      this.router.navigate([`${_c('BOE')}/${payload.id}/${payload.sensors_configuration[0].id}`], payload);
    }else{
      this.router.navigate([`${_c('BOE')}`]);
    }
  }
}
