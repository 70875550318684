import { DataSource } from "@n7-frontend/core";
import { helpers, dateHelpers } from "@app/helpers";
import { VALUES_KEY, _c } from "@app/constants";
import { Annotation, ChartData, ChartSeriesData, ChartTypes, ChartWithData, CompareChartType } from "@app/layouts/home-layout/home-layout.types";
export type SeriesMap = { [key: string]: ([string, string])[] };

type ChartConfig = {
  series: string[],
  strokeWidth: number[],
  yaxis: null,
  colors: { rgb: string[], hex: string[] },
};

type SeriesConfig = {
  key: string,
  unit?: string,
  color: string,
  label: string
}

export class ChartDS extends DataSource {
  private _chart: any = null;
  private _selectedItem: string;
  private _seriesConfig: SeriesConfig[] = [];
  private _itemOrder: string[];
  private _chartType: ChartTypes;
  private _chartId: string;
  private _chartMax:  number | ChartSeriesData;
  private _chartMin:  number | ChartSeriesData;
  private _isYAxisLinear: boolean = true;
  private _selectionXmin: number;
  private _selectionXmax: number;
  private _color: string = helpers.getRandomColor();
  private _label: string = "";
  private _annotations: Annotation[];
  private tippy: any;
  private tippyInstance: any;
  private annotationPreviews: any = {};
  private _annotationCheckbox: boolean = false;
  private _categories: any;

  protected transform(chart: ChartWithData & { chartId?: string } & {tippy: any}) {
    if (!chart) return;
    // set chartId
    this._chartId = chart.chartId;
    this._categories = chart.data.categories;


    if (chart.data.annotations){
      this._annotations = chart.data.annotations;
      this._annotations.forEach((annotation)=>{this.annotationPreviews[annotation.id] = {instance: null, open: false}})
    }
    else{
      this._annotations = [];
    }
    this.tippy = chart.tippy;
    const chartConfig = this._getChartConfig(chart);
    const offsetScale = this.getOffsetScale(chart.data.categories);

    return {
      
      // to use on chart updates
      _setChart: (chart) => (this._chart = chart),
      _elementId: chart.chartId || "chart",
      options: {
        annotations:{
          xaxis: this._annotations ? this.loadAnnotations(this._annotations, offsetScale, chart.data.categories[0]) : []
        },
        chart: {
          events: {
            selection: (chartContext, { xaxis, yaxis }) => {
              if (yaxis) {
                this._selectionXmax = xaxis.max;
                this._selectionXmin = xaxis.min;
              }
            },
            click: (e, ctx, options) => {
              const label = e.target.className.baseVal;
              if(label){

                if (label.startsWith("label"))
                  {if(label.indexOf(' ') >= 0)
                    this._label = label.substring(0, label.indexOf(' '));
                  else this._label = label;
                }
                else 
                  this._label = "";
              }
              else this._label = "";
            }
          },
          height: 350,
          type: "area",
          zoom: {
            enabled: false,
          },
          selection: {
            enabled: false,
          },
          shadow: {
            enabled: true,
            color: "#000",
            top: 18,
            left: 7,
            blur: 10,
            opacity: 1,
          },
          toolbar: {
            show: false,
            tools: {
              selection: true,
            },
            autoSelected: "selection"
          },
        },
        dataLabels: {
          enabled: false,
        },
        colors: chartConfig.colors.rgb,
        fill: {
          colors: chartConfig.colors.hex,
          gradient: {
            opacityFrom: 0.5,
            opacityTo: 0.1,
          },
        },
        stroke: {
          curve: "straight",
          width: chartConfig.strokeWidth,
        },
        series: chartConfig.series,
        grid: {
          borderColor: "#e7e7e7",
          strokeDashArray: 3,
          xaxis: {
            lines: {
              show: true,
            },
          },
        },
        markers: {
          size: 3,
          hover: {
            size: 6,
          },
        },
        xaxis: {
          axisBorder: {
            show: true,
            color: "#f4f6fc",
          },
          labels: {
            formatter: (value, timestamp) => dateHelpers.toString(timestamp, 'DD-MM-YY H:mm'),
          },
          type: "datetime",
          tickAmount: 5,
        },
        yaxis: chartConfig.yaxis,
        legend: {
          show: false,
        },
        tooltip: {
          custom: this._customTooltip.bind(this),
        },
        noData: {
          text: "Non ci sono dati disponibili, prova a cambiare l'intervallo di date",
          align: "center",
          verticalAlign: "middle",
          offsetX: 0,
          offsetY: 0,
          style: {
            color: "#292d2e",
            fontSize: "15px",
            fontFamily: "Nunito Sans",
          },
        },
      },
    };
  }

  private _customTooltip({ series, seriesIndex, dataPointIndex, w }) {
    const config = this._seriesConfig;
    const values = series.map((s) => s[dataPointIndex]);
    const hasDiff =
      [CompareChartType].indexOf(this.input.type) !== -1 &&
      values.every((v) => helpers.isNumeric(v));

    let html = ['<div class="iwt-chart__tooltip">'];
    values.forEach((value, index) => {
      if (!helpers.isNumeric(value)) return;

      const selected = config[index].key === this._selectedItem;
      const icon = selected ? "n7-icon-circle-full" : "n7-icon-circle";
      const selectedClass = selected ? "is-selected" : "";
      const label = config[index].label;

      html.push(`
        <div class="iwt-chart__tooltip-element">
          <span class="iwt-chart__tooltip-element-icon ${icon}" style="color: ${config[index].color}"></span>
          <span class="iwt-chart__tooltip-element-label ${selectedClass}">${label}</span>
          <span class="iwt-chart__tooltip-element-value">${helpers.convertToPowerNotation(value)}${config[index].unit}</span>
        </div>
      `);
    });

    // diff control
    if (hasDiff) {
      // FIXME: modificare con la logica API definitiva per model / compare
      // firstIsCompareOrModelValue!!! wtf???
      const firstIsCompareOrModelValue =
        config[0].key.indexOf(this.input.type) !== -1;
      const diffValue = firstIsCompareOrModelValue
        ? values[1] - values[0]
        : values[0] - values[1];
      const diffIcon =
        diffValue < 0 ? "n7-icon-caret-down" : "n7-icon-caret-up";
      const diffUnit = config[0].unit;

      html.push(`
        <div class="iwt-chart__tooltip-element is-diff">
          <span class="iwt-chart__tooltip-element-icon ${diffIcon}"></span>
          <span class="iwt-chart__tooltip-element-label">${
            this.options.labels.diff
          }</span>
          <span class="iwt-chart__tooltip-element-value">${diffValue.toFixed(
            2
          )}${diffUnit}</span>
        </div>
      `);
    }

    html.push("</div>");
    return html.join("");
  }

  private _getSeriesMap(chart: ChartWithData): SeriesMap {
    const seriesMap = {};
    this.addToSeriesMap(seriesMap, chart.data);
    if (chart.type == CompareChartType) {
      const { series } = chart.compareData;
      const {categories} = chart.data;
      const compareSeries = series.map((s) => {
        return { ...s, id: `${s.id}-${chart.type}`};
      });
      this.addToSeriesMap(seriesMap, { categories, series: compareSeries });
    }
    return seriesMap;
  }

  private addToSeriesMap = (map: any, data: ChartData) => {
    if (!data) {
      return;
    }

    const { categories, series } = data;

    this._chartMax = 0;
    this._chartMin = 0;
    series.forEach((seriesItem) => {
      const { id, data } = seriesItem;
      map[id] = data.map((value, index) => {

        if(!this._chartMax || this._chartMax<value){
          this._chartMax = value;
        }

        if(!this._chartMin || this._chartMin>value){
          this._chartMin = value;
        }

        return [
        categories[index],
        helpers.valueToString(value) as string,
      ]});
    });
    return map;
  };

  private _getConfigMap(data: ChartWithData){
    const map = {};
    const itemsConfig = _c(VALUES_KEY);
    const seriesConf = data.data.series.map(s=>{return {id:s.id, metadata:s.metadata, name:s.name}})
    if(data.type === CompareChartType){
      const compareConf = data.compareData.series.map(s=>{
      return {
        id: `${s.id}-${CompareChartType}`,
        metadata: s.metadata,
        name:s.name, isCompare: true}})
      seriesConf.push(...compareConf);
    }
    seriesConf.forEach(item=>{
      const itemConfig = itemsConfig[item.id];
      map[item.id]={...itemConfig,...item};
    })
    return map;
  }

  private _getChartConfig(data: ChartWithData) {
    const seriesMap = this._getSeriesMap(data);
    const configMap = this._getConfigMap(data);
    this._itemOrder = this._itemOrder || Object.keys(seriesMap);
    const chartConfig = {
      series: [],
      strokeWidth: [],
      yaxis: null,
      colors: { rgb: [], hex: [] },
    };

    // reset series config
    this._seriesConfig = [];

    // set selected key
    this._selectedItem = this._itemOrder[0];
    this._itemOrder.forEach((key, index) => {
      let itemConfig = configMap[key];

      const unit = itemConfig.metadata && itemConfig.metadata.unit;
      const yAxisLabel = unit ? unit : itemConfig.label;
      const label = itemConfig.label || itemConfig.name;
      let opacity = 1;
      let colorHex = itemConfig.color;
      if (!this._isComparison() && index > 0) {
        opacity = 0.3;
        colorHex = "#FFFFFF";
      }

      // colors
      chartConfig.colors.rgb.push(
        `rgba(${itemConfig.colorRgb.join(",")}, ${opacity})`
      );
      chartConfig.colors.hex.push(colorHex);

      // stroke width
      chartConfig.strokeWidth.push(index === 0 ? 2 : 1);

      // yaxis
      const yAxisConfig = {
        max: this._chartMax,
        min: this._isYAxisLinear ? 0 : 0.01,
        logarithmic: this._isYAxisLinear ? false : true,
        logBase: 10,
        title: {
          text: yAxisLabel,
        },
        axisBorder: {
          show: true,
          color: "#f4f6fc",
        },
        labels: {
          formatter: function (value) {
            if (value % 1 !== 0) {
              value = value.toFixed(1);
            }
            return helpers.convertToPowerNotation(value);
          },
        },
      };

      if (this._isComparison() && index === 0) {
        chartConfig.yaxis = {
          ...yAxisConfig,
          show: true,
        };
      } else if (!this._isComparison()) {
        chartConfig.yaxis = chartConfig.yaxis || [];
        chartConfig.yaxis.push({
          ...yAxisConfig,
          show: index === 0,
        });
      }


      // series push
      chartConfig.series.push({
        name: label,
        data: seriesMap[key] || [],
      });

      // update series config
      this._seriesConfig.push({
        key: key,
        unit: unit,
        color: itemConfig.color,
        label,
      });
    });

    return chartConfig;
  }

  public onLegendChange({ type, payload }) {
    // dashboard charts control
    const { chartId, isDashboardChart } = payload[Object.keys(payload)[0]];

    if (isDashboardChart && chartId !== this._chartId) return;

    const seriesMap = this._getSeriesMap(this.input);
    let elementsOrder = [],
      hiddenElements = [],
      firstElement: string;

    // first element
    Object.keys(payload).forEach((key) => {
      const meta = payload[key];
      if (meta.hidden) {
        hiddenElements.push(key);
      } else if (meta.front) {
        elementsOrder.push(key);
        firstElement = key;
      }
    });

    // the other elements
    Object.keys(seriesMap).forEach((key) => {
      if (key !== firstElement && hiddenElements.indexOf(key) === -1) {
        elementsOrder.push(key);
      }
    });

    // update
    this._itemOrder = elementsOrder;
    const chartConfig = this._getChartConfig(this.input);
    if (Array.isArray(chartConfig.series) && chartConfig.series.length) {
      // fix container hidden
      setTimeout(() => {
        this._chart.updateOptions({
          series: chartConfig.series,
          colors: chartConfig.colors.rgb,
          fill: { colors: chartConfig.colors.hex },
          yaxis: chartConfig.yaxis,
          stroke: {
            curve: "straight",
            width: chartConfig.strokeWidth,
          },
        });
      });
    } else {
      this._chart.updateOptions({
        series: chartConfig.series,
      });
    }
  }

  public onChartRequest({ type, payload }) {

    this._categories = payload.data.categories;
    this._chartType = payload.type
    this._itemOrder = null;
    if (!this._chart ) {
      this.update(payload);
    } else {
      // update input
      this.input = payload;

      if(this._annotationCheckbox){
        this._chart.clearAnnotations();
        const offsetScale = this.getOffsetScale(payload.data.categories)
        this._annotations = payload.data.annotations;
        this._annotations.forEach((annotation)=>{this.annotationPreviews[annotation.id] = {instance: null, open: false}})
        this._chart.updateOptions(
          {annotations: {xaxis: this.loadAnnotations(this._annotations, offsetScale, payload.data.categories[0])}}
         ) 
      }

      const chartConfig = this._getChartConfig(payload);
      if (Array.isArray(chartConfig.series) && chartConfig.series.length) {
        // fix container hidden
        setTimeout(() => {
          this._chart.updateOptions({
            series: chartConfig.series,
            colors: chartConfig.colors.rgb,
            fill: { colors: chartConfig.colors.hex },
            yaxis: chartConfig.yaxis,
            stroke: {
              curve: "straight",
              width: chartConfig.strokeWidth,
            },
          });

         

        });
      } else {
        this._chart.updateOptions({
          series: chartConfig.series,
        });
      }

      
    }
  }

  onYAxisCheckboxChange(){
    this._isYAxisLinear = this._isYAxisLinear ? false : true;

    const chartConfig = this._getChartConfig(this.input);
    this._chart.updateOptions({
      yaxis: chartConfig.yaxis,
    });
  }

  getX(){
    return { "xmin": this._selectionXmin, "xmax": this._selectionXmax}
  }

  onNewAnnotation(newAnnotation: Annotation){
    //this._addAnnotation(newAnnotation)
  }

private  _addAnnotation(newAnnotation: Annotation){
  /*   const color = helpers.getRandomColor();

    this._chart.addXaxisAnnotation({
      id: newAnnotation.id,
      x: newAnnotation.x1,
      x2: newAnnotation.x2,
      borderColor: color,
      fillColor: color,
      opacity: 0.1,
      label: {  
                borderColor: color,
                click: ()=>{},
                style: {

                  cursor: "pointer",
                  fontSize: '15px',
                  color: "#fff",
                  background: color,
                  class: "annotation-label"
                },
        orientation: "horizontal",
        text: newAnnotation.label 
      }
    });


      this._chart.updateOptions({
       selection: {
         xaxis: {
           min: null,
           max: null
         }
       },
     });      */

  }

  onClick() {
    return this._label
  }

  annotationRemove(id) {
    this._chart.removeAnnotation(id)
  }

  annotationEdit(editedAnnotation: Annotation){
    this._chart.removeAnnotation(editedAnnotation.id);
    this._addAnnotation(editedAnnotation);
  }

  private _isComparison() {
    return [CompareChartType].indexOf(this.input.type) !== -1;
  }

  loadAnnotations(annotations: Annotation[], offsetScale: number = 1, date_from?): any{
   
    const color = "#125b89"
    const from = new Date(date_from).getTime();
    const temp = annotations.map((a)=>{

      const annotation = JSON.parse(JSON.stringify(a));
      delete annotation.attachment;
      let offset= (annotation.x2 - annotation.x1)/offsetScale*1000
      return {

          id: annotation.id,
          x: annotation.x1,
          x2: annotation.x2,
          borderColor: color,
          fillColor: color,
          opacity: 0.1,
          label: {  
            borderColor: color,
            offsetX: ((from > annotation.x1)&&(annotation.x2>from)) ? offset : 0,
            mouseEnter: (target)=>{
              this.handleAnnotationHoverOn(target)
            },
            mouseLeave: (target)=>{
              this.handleAnnotationHoverOff(target)
            }, 
            click: (target)=>{
              this.annotationPreviews[target.id].instance.destroy();
              this.annotationPreviews[target.id].open = false;
            },
            style: {
              cursor: "pointer",
              fontSize: '15px',
              color: "#fff",
              background: color,
              class: "annotation-label",
            },
            orientation: "horizontal",
            text: annotation.label 
          }
            
        }

    })
    return temp;
  }

  getOffsetScale(categories: any){
    const time_from = new Date(categories[0]).getTime();
    const time_to = new Date(categories[categories.length-1]).getTime();
    return time_to-time_from;
  }

  handleAnnotationHoverOn(target: any){
    const id = target.id;
    const annotation = this._annotations.find(obj => obj.id === id);
    const from = dateHelpers.formatDateFromEpoch(annotation.x1)
    const to = dateHelpers.formatDateFromEpoch(annotation.x2)
    const template = 
    `<div class="annotation-preview" >
    <p style="font-size: small"><b>Titolo:</b> ${annotation.label}</p>
    <p style="font-size: small"><b>Descrizione:</b> ${annotation.description ? (annotation.description.length<85 ? annotation.description : annotation.description.slice(0, 84)+"...") : "-" }</p>
    <p style="font-size: small"><b>Date:</b> ${from} - ${to}</p>
    <p style="font-size: small"><b>Allegati:</b> ${annotation.attachment ? annotation.attachment.length : 0}</p>
    </div>
    `

    if(this.annotationPreviews[annotation.id].instance){
      try {
        this.annotationPreviews[annotation.id].instance.destroy();
      } catch (error) {
        console.log(error);
      }
    }

      this.annotationPreviews[annotation.id].instance = this.tippy("text." + annotation.id, {
        content: template,
        trigger: "manual",
        interactive: false,
        arrow: true,
        theme: "light-border",
        placement: "top",
        onHidden: (instance)=>{this.annotationPreviews[annotation.id].open=false; instance.destroy()}
        })[0];

    if(this.annotationPreviews[annotation.id].instance){
      this.annotationPreviews[annotation.id].instance.show()
      this.annotationPreviews[annotation.id].open = true;
    }

  }

  handleAnnotationHoverOff(target: any){
    if(this.annotationPreviews[target.id].open){
      this.annotationPreviews[target.id].instance.hide();
      this.annotationPreviews[target.id].open = false;
    }
  }

  annotationCheckboxChange(payload){
    this._annotationCheckbox = payload;
    this._chart.updateOptions({chart: {selection: {enabled: payload}}})
    if(!payload){
      this._annotations = [];
      this._chart.updateOptions({annotations: {xaxis: []}})
    }
  }

  onLoadAnnotations(payload: any){

      const offsetScale = this.getOffsetScale(this._categories) 
        this._annotations = payload.annotations;
        this._annotations.forEach((annotation)=>{this.annotationPreviews[annotation.id] = {instance: null, open: false}})
        this._chart.updateOptions(
          {annotations: {xaxis: this.loadAnnotations(this._annotations, offsetScale, this._categories[0])}})
  }
}