import { DataSource } from '@n7-frontend/core';

export class BuoysMapDS extends DataSource {

  private map;
  private markers = [];

  protected transform(data) {
    if (!data) {
      return;
    }

    return {
      _setMap: (map, markers = []) => { this.map = map; this.markers = markers; },
      items: data.map(item => { return { coordinates: item.location, properties: item } })
    };
  }
}
