
import { EventHandler } from '@n7-frontend/core';

export class DateFilterDropdownEH extends EventHandler {
  public listen() {

    this.innerEvents$.subscribe(event => {
      switch(event.type){

        case 'date-filter-dropdown.clickOut':
          this.dataSource.onClickOut(event);
          break;

        default:
          break;
      }
    });
  }
}
