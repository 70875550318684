import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Config } from './constants';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent {
  constructor(
    private translateService: TranslateService
  ) {
    this.translateService.setDefaultLang('it');    

  }
}
