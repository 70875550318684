import { GET_SENSORS_DATA_MOCK } from './get-sensors-data.mock';

export const GET_ELEMENTS_LIST_MOCK = {
  status: 'ok',
  values: _getValues()
}

function _getValues(){
  const headers = [
    'metalli_m1',
    'ingresso_civile',
    'preaccumulo',
    'sedimentazione_primaria',
    'denitrificazione',
    'sedimentazione_biologica'
  ];

  return headers.map(header => ({
    id: header,
    items: GET_SENSORS_DATA_MOCK.values
  }));
}