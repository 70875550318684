import { EventHandler } from '@n7-frontend/core';

export class AnnotationDatepickerEH extends EventHandler {
  public listen() {

    this.innerEvents$.subscribe(event => {
      switch(event.type){
        case 'annotation-datepicker.change': 
          this.emitOuter('change', event.payload);
          break;

        default:
          break;
      }
    });

    this.outerEvents$.subscribe(event => {
      if(event.type === 'chart-layout.annotation-date-selected'){
        this.dataSource.open();
      }
      else {
        if(event.type === 'chart-layout.annotation-set-date'){
          this.dataSource.setDate(event.payload)
        }
      }
    });
  }
}