import { DataSource } from '@n7-frontend/core';
import { IChartLegendData } from '@app/components/chart-legend/chart-legend';
import { VALUES_KEY, SENSORS_KEY, _c, BUOYS_KEY } from '@app/constants';
import { ChartWithData, CompareChartType } from '@app/layouts/home-layout/home-layout.types';

export class ChartLegendDS extends DataSource {

  private _iconShow = 'n7-icon-eye';
  private _iconHide = 'n7-icon-eye-slash';
  private _iconOnFront = 'n7-icon-circle-full';
  private _iconNotOnFront = 'n7-icon-circle';
  private _chartType: string;

  protected transform(chart: (ChartWithData & { chartId: string })): IChartLegendData {
    if (!chart) return;
    const valuesConfig = _c(VALUES_KEY);
    const sensorsConfig = _c(SENSORS_KEY);
    const buoysConfig = _c(BUOYS_KEY);
    const { isDashboardChart } = this.options;

    const elements = this.getItemsFromChart(chart);
    const items = [];

    elements.forEach((item, index) => {
      const { metadata, name, data, id } = item;
      if (!data) {
        return;
      }
      const splittedId = id.split("-");
      const buoyId = splittedId[0];
      const sensorId = splittedId[0] + "-" + splittedId[1];
      const sensorLabel = sensorsConfig[sensorId].label;
      const buoyLabel = buoysConfig[buoyId].label;

      const itemConfig = valuesConfig[id];

      const unit = metadata && metadata.unit ? metadata.unit : itemConfig.unit;

      const color = `rgba(${itemConfig.colorRgb.join(',')}, 1)`;
      const isFirst = index === 0;
      const prefix = this.options.hasSensorLabel ? `${buoyLabel} &rsaquo; ${sensorLabel} &rsaquo; ` : "";
      const label = `${prefix}${name || itemConfig.label} ${unit}`;
      items.push({
        label,
        classes: isFirst ? 'is-current' : '',
        left: {
          icon: isFirst ? this._iconOnFront : this._iconNotOnFront,
          color: color,
        },
        right: !isDashboardChart ? {
          icon: this._iconShow,
          color: '',
          tooltip: 'i18n.chart_legend.show_parameter',
        } : null,
        payload: {
          id
        },
        _meta: {
          id,
          front: isFirst,
          hidden: false,
          chartId: chart.chartId,
          isDashboardChart
        },
      });
    });

    return { items };
  }

  private getItemsFromChart = (chart: ChartWithData) => {
    const seriesIds = chart.data.series.map(s => s);
    if (chart.type === CompareChartType) {
      const compareSeriesIds = chart.compareData.series.map(s => {
        return {
          ...s,
          id: `${s.id}-${chart.type}`, name: undefined
        }
      });
      seriesIds.push(...compareSeriesIds);
    }
    return seriesIds;
  }

  public onClick({ type, payload }) {
    const { items } = this.output,
      { id } = payload;

    let state = {};

    if (type.indexOf('right') !== -1) {
      items.forEach(item => {
        if (item._meta.id === id) {
          if (item._meta.hidden === true) {
            item.right.icon = this._iconShow;
            item._meta.hidden = false;
          } else {
            item.right.icon = this._iconHide;
            item._meta.hidden = true;
          }
        }
      });
    } else {
      items.forEach(item => {
        // reset
        item.left.icon = this._iconNotOnFront;
        item._meta.front = false;
        item.classes = '';

        if (item._meta.id === id) {
          item.left.icon = this._iconOnFront;
          item._meta.front = true;
          item.classes = 'is-current';
        }
      });
    }

    // force hidden to false when element is on front
    const itemOnFront = items.filter(item => item._meta.front)[0];
    itemOnFront._meta.hidden = false;
    if (itemOnFront.right) {
      itemOnFront.right.icon = this._iconShow;
    }

    // update state
    items.forEach(item => {
      state[item._meta.id] = item._meta;
    });

    return state;
  }

  onChartRequest({ type, payload }) {
    this._chartType = payload.type;
    this.update(payload);
  }
}
