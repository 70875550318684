import { DataSource } from '@n7-frontend/core';
import { VALUES_KEY, SENSORS_KEY, _c } from '@app/constants';

var alertListTableHead = [
  {
    classes: 'HEADER',
      cells: [
          {
              classes: 'CONTENT',
              content: 'DATA - ORA'
          },
          {
              classes: 'CONTENT',
              content: 'SEZIONE'
          },
          {
              classes: 'CONTENT',
              content: 'PARAMETRO'
          },
          {
              classes: 'CONTENT',
              content: 'VALORE'
          }
      ]
  }
];

export class ListAlertsTableDS extends DataSource {

  protected transform(data){
    if(!data) return;

    const valuesConfig = _c(VALUES_KEY),
          sensorsConfig = _c(SENSORS_KEY),
          { labels } = this.options;

    var rawAlerts = data;
    var transformedAlerts = [];

    rawAlerts.forEach((rawAlert) => {
      transformedAlerts.push(
        transformSingleRawAlert(rawAlert,
                                valuesConfig[rawAlert.alert.parameter.id],
                                sensorsConfig[rawAlert.alert.parameter.section.id],
                                labels)
      );
    });

    return {
      classes: 'test-layout-table',
      head: alertListTableHead,
      body: transformedAlerts
    };
  }
}

function convertEpochTimeDateToString(time: number): string{
  var date = new Date(time);
  var day = date.getDate();
  var month = date.getMonth()+1;
  var year = date.getFullYear();
  var hour = date.getHours();
  var minutes = date.getMinutes();
  return day+'/'+month+'/'+year+' - '+hour+':'+minutes;
}


function createSpanParamCell({id, label, unit, vsModel, labels}): string{
  const cssClass= `element-color-${id}`;
  //unit = unit || '';


  if(!vsModel) {
    return `<span class='n7-icon-circle-full ${cssClass}'></span> ${label} ${unit}`;
  } else {
    return `
      <span class='n7-icon-circle-full ${cssClass}-model'>
      </span><span class='n7-icon-circle-full ${cssClass}'></span>
      ${label} vs. ${labels.model} ${label} ${unit}
    `;
  }
}

function createValueString(value:number,unit:string,vsModel:boolean):string{
  var str = "";
  if(vsModel){
    if(value>0) str+="+ ";
    else if(value<0) str+="- ";
  }
  //if( !unit || typeof(unit) == "undefined" ) unit = '';
  str += Math.abs(value).toFixed(2) +" "+unit;
  return str;
}

function transformSingleRawAlert(rawAlert, configParam, configSection, labels){

  const parameterId = rawAlert.alert.parameter.id,
        vsModel = rawAlert.alert.vsModel,
        unit = configParam.unit,
        time = new Date(rawAlert.date).getTime(),
        label = configParam.label

  var timeStr:string = convertEpochTimeDateToString(time);
  var section:string = configSection.label;
  var paramHtml:string = createSpanParamCell({ 'id':parameterId, label, unit, vsModel, labels });
  var valueStr:string = createValueString(rawAlert.value, unit, vsModel);
  return {
    cells: [
      {  
        content: timeStr,
        type: 'html'
      },
      {  
        content: section,
        type: 'html'
      },
      {  
        content: paramHtml,
        type: 'html',
        classes: vsModel ? 'has-model' : ''
      },
      {  
      content: valueStr,
      type: 'html'
      }
    ]
  };
}