import { LayoutDataSource } from '@n7-frontend/core';
import { BUOYS_KEY, ELEMENTS_KEY, LABELS_KEY, SENSORS_KEY, _c } from '@app/constants';

export class ElementsListLayoutDS extends LayoutDataSource {

  public showSearchBar = false;
  public buoyLabel = "";
  private sensorId: string | null;
  private buoyId: string | null;
  private currentSource: any;
  private routePaths: string[];

  onInit() {
    this.showSearchBar = _c("showSearchBar")
  }

  onRouterChange({ data, params, url }) {
    if (params.sensorId && data.source) {
      this.sensorId = params.sensorId;
      this.buoyId = params.buoyId;
      this.buoyLabel = _c(BUOYS_KEY)[this.buoyId].label;
      const sensoriPath = _c('SENSORI');
      const title = _c(LABELS_KEY).main.sensors;
      this.currentSource = data.source === sensoriPath ? { source: sensoriPath, title } : undefined;

      this.routePaths = url.map(segment => segment.path);

      // request
      this._loadElements();
    }
  }

  getRoutePaths = () => this.routePaths;

  private _loadElements() {
    const sensorsConfig = _c(SENSORS_KEY);
    const elementsConfig = _c(ELEMENTS_KEY);
    const currentSensor = sensorsConfig[`${this.buoyId}-${this.sensorId}`];
    let data = [];
    if (currentSensor) {
      data = currentSensor.elements.map((element) => {
        const elementId = [this.buoyId, this.sensorId, element.id].join("-");
        return elementsConfig[elementId];
      });
    }
    this.one('parameter-cards').update(data);
  }
}
