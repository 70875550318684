import { DataSource } from '@n7-frontend/core';
import { BUOYS_KEY, SENSORS_KEY, _c, ELEMENTS_KEY } from '@app/constants';

export class BreadcrumbsDS extends DataSource {
  private _paths: string[];

  protected transform(data) {
    if(!this.options || !this.options.labels || !this._paths) return;


    let items = [];
    // POPOLARE LE NUOVE SEZIONI
    if(this._paths.length === 4 && ([_c('BOE')].indexOf(this._paths[0]) !== -1)){

      const buoyId = this._paths[1];
      const buoy = _c(BUOYS_KEY)[buoyId];
      const sensorId = `${buoyId}-${this._paths[2]}`;
      const sensor = _c(SENSORS_KEY)[sensorId];
      const elementId = `${buoyId}-${this._paths[2]}-${this._paths[3]}`;
      const element = _c(ELEMENTS_KEY)[elementId];
      if(buoy && sensor && element){

        const firstSensorId = buoy.sensors_configuration[0].id
        let payload = [_c('BOE')];
        items.push({
          label: this.options.labels['buoys'],
          payload: [_c('BOE')]
        });
 
        payload = [_c('BOE'), buoyId, firstSensorId];
        items.push({
          label: buoy ? buoy.label: '',
          payload
        });
  
        payload = [_c('BOE'),buoyId, this._paths[2]];
        items.push({
          label: sensor ? sensor.label: '',
          payload
        });
  
        items.push({
          label: element.label,
          payload
        });
      }
    }

    return { items };
  }

  isVisible(){
    return this.output && Array.isArray(this.output.items) && this.output.items.length; 
  }

  onRouterChange(payload){
    this._paths = payload.url.map(segment => segment.path);

    this.update();
  }
}
