import { Component, OnInit } from '@angular/core';
import { LayoutBuilder } from '@n7-frontend/core';
import { TranslateService } from '@ngx-translate/core';

import * as DS from '@app/common/data-sources';
import * as EH from '@app/common/event-handlers';
import { CustomizedViewLayoutEH } from './customized-view-layout.eh';
import { CustomizedViewLayoutDS } from './customized-view-layout.ds';
import { withLatestFrom } from 'rxjs/operators';
import { ChartLayoutDS } from '../chart-layout/chart-layout.ds';
import { CommunicationService } from '@app/services/communication.service';
import { _c } from '@app/constants';

@Component({
  selector: 'customized-view-layout',
  templateUrl: './customized-view-layout.html'
})
export class CustomizedViewLayoutComponent implements OnInit {
  public lb = new LayoutBuilder('customized-view-layout');
  private widgets = [
    { id: 'modal-custom-chart', hasStaticData: true },
    { id: 'elements-accordion' },
  ];

  constructor(
    private communication: CommunicationService,
    private translate: TranslateService,
  ) {}

  ngOnInit(){
    // reset
    ChartLayoutDS.customChartData = null;
    // on ready
    this.communication.request$('chart/get_custom').pipe(
      withLatestFrom(this.lb.ready$)
    ).subscribe(([chartData]) => {
      // if has custom chart redirect
      if(chartData && chartData.results && chartData.results.length){
        const {results} = chartData;
        const customChart = results[0];
        ChartLayoutDS.customChartData =  customChart ;
        this.lb.eventHandler['emitGlobal']('navigate', [`/${_c('VIEW_CUSTOM_CHART')}/${customChart.id}`]);
      // if no continue
      } else {
        this.lb.eventHandler.emitInner('init', {
          communication: this.communication,
          translate: this.translate,
        });
      }
    });

    this.lb.init({
      widgetsConfig: this.widgets,
      widgetsDataSources: DS,
      widgetsEventHandlers: EH,
      dataSource: new CustomizedViewLayoutDS(),
      eventHandler: new CustomizedViewLayoutEH(),
    });
  }

  ngOnDestroy(){
    this.lb.eventHandler.emitInner('destroy');
  }
}
