import { Component, Input } from '@angular/core';

@Component({
  selector: 'iwt-form-alarm-create',
  templateUrl: './form-alarm-create.html'
})
export class FormAlarmCreateComponent {
  @Input() data: any;
  @Input() emit: any;
    private showForm:any[]=[]
    private counter:number = -1;
  addWarning(){
    this.counter++   
    this.showForm.push({
        id:this.counter,
        value:true
        });  
  }
  removeWarning(index:any){ 
    if(this.counter<0)
        return
    this.showForm[index.id].value= false;
    this.showForm.splice(index.id, 1);
    this.showForm.map(id => {
            if(id.id>index.id)
            {
                id.id--
            }   
            return id
        })
    this.counter--
  }
  onChange(payload, value) {
    if (!this.emit) return;
    this.emit('change', { payload, value });
  }
}