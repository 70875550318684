import { EventHandler } from '@n7-frontend/core';

export class YAxisCheckboxEH extends EventHandler {
  public listen() {


    this.innerEvents$.subscribe(event => {

      switch(event.type){

        case 'y-axis-checkbox.y-axis_checkbox_change':
          this.emitOuter('y-axis_checkbox_change');
          break;

        default:
          break;
      }
    });
  }
}
