import { DataSource } from '@n7-frontend/core';

export class ChartActionsDS extends DataSource {
  protected transform(data) {
    if(!this.options || !this.options.labels) return;

    const { labels, elementConfig } = this.options;
    const { hasAlerts, actions } = data;
    const items = [];
    if(actions && actions.alarms){
      //uncomment to show alert button
/*       if(elementConfig){
        items.push({
          icon: hasAlerts ? 'n7-icon-bell' : 'n7-icon-bell-add',
          payload: 'alarm',
          tooltip: !hasAlerts ? `${labels.alarm_text} ${elementConfig.label}` : null,
          id: 'chart-alerts-action',
          badge: hasAlerts
        });
      } */
    }

    if(actions && actions.addToDashboard){
      items.push({
        icon: 'n7-icon-dashboard-add',
        payload: 'dashboard',
        tooltip: 'i18n.chart_actions.add_to_dashboard',
      });
    }
    return { items }
  }
}
