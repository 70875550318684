import {
  Component,
  Input,
  AfterContentChecked,
} from "@angular/core";
import * as L from "leaflet";
delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

@Component({
  selector: "iwt-map",
  templateUrl: "./map.html",
})
export class MapComponent implements AfterContentChecked {
  @Input() data: any;
  @Input() emit: any;
  private _loaded: boolean = false;

  ngAfterContentChecked() {
    if (!this.data || this._loaded) return;

    this._loaded = true;

    setTimeout(() => {
      const { items } = this.data;
      const map = L.map("map", {
        center: [41.5308696, 11.8650262],
        zoom: 5.5,
        tap: false,
      });
      const tiles = L.tileLayer(
        "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
        {
          maxZoom: 18,
          minZoom: 3,
          attribution:
            '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
        }
      );
      tiles.addTo(map);

      const markers = [];
      for (var i = 0; i < items.length; i++) {
        const { coordinates, properties } = items[i];
        const marker = new L.marker(coordinates);
        marker.addTo(map);

        const customPopup = this.createPopup(properties);        
        marker.bindPopup(customPopup, { closeButton: false });

        if (!properties.enabled) {
          marker._icon.className += " disabled";
        }

        markers.push(marker);
      }
      this.data._setMap(map, markers);
    });
  }

  private createPopup = (properties)=>{
    const content = L.DomUtil.create("div");
    const titlePopup = L.DomUtil.create("h3", "", content);
    titlePopup.innerHTML = properties.label;
    const statePopup = L.DomUtil.create("p", "", content);
    statePopup.innerHTML = `Stato: ${
      properties.enabled ? "Attiva" : "Disattiva"
    }`;
    if (properties.enabled) {
      const sensorButton = L.DomUtil.create("p", "n7-btn", content);
      sensorButton.innerHTML = "Visualizza Sensori";
      L.DomEvent.addListener(
        sensorButton,
        "click",
        function (event) {
          this.emit("marker-click", properties);
        },
        this
      );
    }

    return L.popup().setContent(content);
  }
}
