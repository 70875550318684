import { EventHandler } from '@n7-frontend/core';

export class ModalAnnotationsEH extends EventHandler {
  public listen(){
    this.innerEvents$.subscribe(event => {
      
        if(event.payload === 'dismiss'){
          this.dataSource.onDismiss();
          this.emitOuter("close-annotations-modal");
        }
        else {
          if (event.payload === 'save'){
            this.emitOuter('save')
          }
        }
      });

  }
}