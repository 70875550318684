import { _c } from '@app/constants';
import { EventHandler } from '@n7-frontend/core';

export class CustomizedViewLayoutEH extends EventHandler {

  public listen() {
    // listen to inner (layout) events
    this.innerEvents$.subscribe(event => {
      switch(event.type){
        case 'customized-view-layout.init':
          this.dataSource.onInit(event.payload);
          break;
        case 'customized-view-layout.button_click':
          this.emitOuter('open-modal', null);
          break;
        default:
          break;
      }
    });

    this.outerEvents$.subscribe(event => {
      switch(event.type){
        case 'modal-custom-chart.click':
          this.emitOuter('modal-click', event.payload);
          break;

        case 'elements-accordion.save':
          const request$ = this.dataSource.onElementsSave(event.payload);
          request$.subscribe(response => {
            this.emitOuter('close-modal');
            setTimeout(() => {
              this.emitGlobal('navigate', [`/${_c('VIEW_CUSTOM_CHART')}/${response.id}`]);
            }, 200);
          });
          break;

        default:
          break;
      }
    });

  }

}
