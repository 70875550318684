export const GET_CHART_NORMAL_DATA_MOCK = {
  categories: [
    "2019-08-10T00:00:00.000Z",
    "2019-08-11T00:00:00.000Z",
    "2019-08-12T00:00:00.000Z",
    "2019-08-13T00:00:00.000Z",
    "2019-08-14T00:00:00.000Z",
    "2019-08-15T00:00:00.000Z",
    "2019-08-16T00:00:00.000Z",
    "2019-08-17T00:00:00.000Z",
    "2019-08-18T00:00:00.000Z",
    "2019-08-19T00:00:00.000Z",
    "2019-08-20T00:00:00.000Z",
    "2019-08-21T00:00:00.000Z",
    "2019-08-22T00:00:00.000Z",
    "2019-08-23T00:00:00.000Z",
    "2019-08-24T00:00:00.000Z",
    "2019-09-00T00:00:00.000Z",
    "2019-09-01T00:00:00.000Z",
    "2019-09-02T00:00:00.000Z"
  ],
  series: [
    {
      id: "min_cd",
      name: "Min Cadmio",
      data: [
        650.9043054816724,
        671.2483452760677,
        643.6835356671622,
        677.3855062812532,
        743.5312427949414,
        845.330091026741,
        948.3715132377187,
        677.3855062812532,
        743.5312427949414,
        845.330091026741,
        948.3715132377187,
        677.3855062812532,
        743.5312427949414
      ],
      metadata: {
        unit: "mg"
      }
    },
    {
      id: "max_cd",
      name: "Max Cadmio",
      data: [
        650.9043054816724,
        350.9043054816724,
        343.6835356671622,
        477.3855062812532,
        743.5312427949414,
        245.330091026741,
        548.3715132377187
      ],
      metadata: {
        unit: "mg"
      }
    }
  ]
}