import { DataSource } from '@n7-frontend/core';
import { ELEMENTS_KEY, _c } from '@app/constants';



export class ModalAlarmCreateDS extends DataSource {
  public modalDescription: string;

  protected transform(data) {
    if(!data || !this.options || !this.options.labels) return;
    
    
    const { labels } = this.options      
    const { elementId, isUpdate, sensorId, buoyId } = data      
    const modalLabels = isUpdate ? labels['alarm_update'] : labels['alarm_create']
   
    const id = [buoyId, sensorId, elementId].join("-");
    const elementConfig = _c(ELEMENTS_KEY)[id]
    const title = `${modalLabels['title']} ${elementConfig.label}`
    
    this.modalDescription = modalLabels['description'].replace('__NAME__', elementConfig.label);   
    return {
      isVisible: false,
      classes: 'iwt-layout-chart__modal',
      header: {
        label: title,
        closeButton: {
          payload: {
            source: 'dismiss',
            isUpdate
          }
        }
      },
      footer: {
        actions: [{
          label: 'i18n.cancel',
          payload: {
            source: 'dismiss',
            isUpdate
          }
        }, {
          isDisabled: true,
          buttonClasses: 'n7dash-btn-ok',
          label: modalLabels['save'],
          payload: {
            source: 'save',
            isUpdate
          }
        }]
      }
    };
  }

  onOpen(){
    this.output.isVisible = true;
  }

  onDismiss(){
    this.output.isVisible = false;
  }

  updateActions(){
    this.output.footer.actions = [{
      label: 'i18n.close',
      payload: 'dismiss'
    }];
  }
}