import { _c } from '@app/constants';
import { EventHandler } from '@n7-frontend/core';

export class SubnavEH extends EventHandler {
  public listen() {
    this.innerEvents$.subscribe(({type, payload}) => {
      if(type === 'subnav.click' && !payload.disabled){
        const buoyId = this.dataSource.getBuoyId();
        this.emitOuter('click', [ _c("BOE"),buoyId, payload.path ] );
      }
    });

    EventHandler.globalEvents$.subscribe(({ type, payload }) => {
      if(type === 'global.routerChange') {
        this.dataSource.onRouterChange(payload);
      }
    });
  }
}