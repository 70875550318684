import { Injectable } from '@angular/core';
import { _c } from '@app/constants';
import { catchError, map } from 'rxjs/operators';
import { EMPTY, Subject, throwError } from 'rxjs';
import { Utils } from '@app/providers';


@Injectable()
export class CommunicationService {
  public token: string;
  public tokenError$: Subject<boolean> = new Subject();
  constructor(
    private utils: Utils
  ) { }

  request$(type, params?: any, onError?: any, method = "POST"){
      const headers = this.token ? { 'Authorization': `Bearer ${this.token}` } : {};
      const requestURL = new URL(type, _c('apiUrl')).toString(); 
      const request$ = this.utils.request$(method, requestURL, { ...params }, headers);
    return request$.pipe(
      map((response) => {
        if (Array.isArray(response.errors) && response.errors.length) {
          const { errors } = response;
          return throwError({
            status: 400,
            error: { errors }
          });
        }
        return response;
      }),
      catchError((error) => {
        if (onError) {
          onError(error);
        }
        this._handleError(error)
        return EMPTY;
      })
    );
  }

  public setToken = (token) => this.token = token;

  private _handleError(e){
    // unauthorized
    if(e && e.status === 400 && e.error){
      if(Array.isArray(e.error.errors) && e.error.errors.length){
        const extensionError = e.error.errors[0].extensions;
        if(extensionError.code === 401){
          this.tokenError$.next();
        }
      }
    }
  }

  private makeParamsStr(params){
    let paramsStr = [];
    Object.keys(params).forEach(key => {
      if(Array.isArray(params[key])){
        let arrStr = [];
        params[key].forEach( val => {
          if(typeof(val)==='object'){
            let subParamsStr = this.makeParamsStr(val);
            arrStr.push(`{ ${subParamsStr} }`);
          } else {
            if(!isNaN(val)) arrStr.push(`${val}`);
            else arrStr.push(`"${val}"`)
          }
        });
        paramsStr.push(`${key}: [${arrStr.join(',')}]`);
      } else if( typeof(params[key])==='object' && params[key] ){
          let subParamsStr = this.makeParamsStr(params[key]);
          paramsStr.push(`${key}: { ${subParamsStr} }`);
      } else if( typeof(params[key])==='string' && key.indexOf('$') === 0){
        paramsStr.push(`${key.replace('$', '')}: ${params[key]}`);
      } else {
        if(!isNaN(params[key])) paramsStr.push(`${key}: ${params[key]}`);
        else paramsStr.push(`${key}: "${params[key]}"`);
      }
    });
    return paramsStr.join(' ');
  }

}
