import { _c } from '@app/constants';
import { EventHandler } from '@n7-frontend/core';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { HomeLayoutDS } from '../home-layout/home-layout.ds';
import { Annotation } from '../home-layout/home-layout.types';

export class ChartLayoutEH extends EventHandler {
  private destroyed$: Subject<any> = new Subject();

  public listen() {
    // listen to inner (layout) events
    this.innerEvents$.subscribe(event => {
      let requestPayload;
      switch(event.type){
        case 'chart-layout.init':
          this.dataSource.onInit(event.payload);
          break;

        case 'chart-layout.destroy':
            this.destroyed$.next();
            break;

        case 'chart-layout.datepicker_submit':
          this.dataSource.onDatepickerSubmit();
          this._doChartRequest();
          break;

        case 'chart-layout.annotation_datepicker_submit':
          this.dataSource.onAnnotationDatepickerSubmit();
          break;

        case 'chart-layout.compareCheckboxChange':
          this.dataSource.onCompareCheckboxChange(event.payload);
          if(event.payload) {
            this._doCompareChartRequest();
          } else {
            this._doChartRequest();
          }
          this.emitOuter('compareCheckboxChange', event.payload);
          break;

        case 'y-axis-checkbox.y-axis_checkbox_change':
          this.emitOuter('y-axis_checkbox_change', event.payload);
          break;

        case 'chart-layout.datepicker_compare_submit':
          this.dataSource.onCompareDatepickerSubmit();
          this._doCompareChartRequest();
          break;

        case 'chart-layout.loadAlertModal':
          this.dataSource.onLoadAlertModal();
          this.emitGlobal('openAlertModal', {
            elementId: this.dataSource.getElementId(),
            sensorId: this.dataSource.getSensorId(),
            buoyId: this.dataSource.getBuoyId(),
          });
          break;
        
          case 'chart-layout.annotation-date-selected':
            this.dataSource.openAnnotationDatepicker();
            this.emitOuter('annotation-date-selected');
            break;

        case 'chart-layout.annotation-click':
          this.emitOuter('annotation-click')
          break;

        case 'chart-layout.annotationCheckboxChange':
          this.dataSource.annotationCheckboxChange(event.payload);
          if(event.payload){
          this._loadAnnotations();
          }
          this.emitOuter('annotation-checkbox-change', event.payload);

        default:
          break;
      }
    });

    this.outerEvents$.subscribe(event => {
      switch(event.type){
        case 'chart-legend.change':
          this.emitOuter(event.type, event.payload);
          break;

          case 'chart.mouseUp':
            let x = this.dataSource.setAnnotationX(event.payload);
            this.emitOuter('annotation-set-date', x);
            this.dataSource.annotationModalHidden = false;
            break;

          case 'form-annotations.save':
            this._onFormAnnotationsSave(event);
            break;
          
          case 'form-annotations.edit':
            this._onFormAnnotationsEdit(event);
            break

          case 'modal-annotations.save':
            this.emitOuter("save-annotations-modal");
            break;

            case 'modal-annotations.close-annotations-modal':
              this.dataSource.resetAnnotationForm();
              this.dataSource.annotationModalHidden = true;
              break;

        case 'date-select-filter.click':
          if(event.payload._id !== 'custom'){
            const {type,value, unit} = event.payload;
            this.dataSource.setChartInterval({type,value,unit});
            this._doChartRequest();
          } else {
            this.dataSource.openDatepicker();
            this.emitOuter('custom-date-selected');
          }
          break;
        case 'item-select-filter.click':
            const { value, type } = event.payload;
            this.dataSource.setItemFilter({ value, type });
            this._doChartRequest();
          break;

        case 'compare-select-filter.click':
          if(event.payload._id !== 'custom'){
            const {type,value,unit} = event.payload;
            this.dataSource.setChartComparePeriod({type,value,unit});
            this._doCompareChartRequest();
          } else {
            this.dataSource.openCompareDatepicker();
            this.emitOuter('custom-date-compare-selected');
          }
          break;

        case 'chart-datepicker.change':
          this.dataSource.onDatepickerChange(event);
          break;

          case 'form-annotations.change':
            this.dataSource.onAnnotationDatepickerChange(event);
            break;

        case 'chart-datepicker-compare.change':
          this.dataSource.onCompareDatepickerChange(event);
          break;

        case 'chart-actions.click':
          if(event.payload === 'alarm'){
            if(this.dataSource.hasAlerts()){
              this.dataSource.toggleAlertsTablePopover();
            } else {
              this.emitGlobal('openAlertModal', {
                elementId: this.dataSource.getElementId(),
                sensorId: this.dataSource.getSensorId(),
                buoyId: this.dataSource.getBuoyId(),
              });
            }
          } else if(event.payload === 'dashboard'){

            this.dataSource.addToDashboardRequest().subscribe(chart => {
              this.emitGlobal('navigate', ['/']);

              // update new chart id
              HomeLayoutDS.newChartId = chart.id;
            });
          }
          break;

          case 'y-axis-checkbox.y-axis_checkbox_change':
            this.emitOuter('y-axis_checkbox_change', event.payload);
            break;

          case 'chart.clickLabel':
            this.dataSource.onActionClick(event.payload)
            break;

          case 'annotation-menu.click':
            const id = this.dataSource.getSelectedAnnotationId();
            this.dataSource.toggleAnnotationMenu(id);
            if (event.payload === "remove") {
                 const request$ = this.dataSource.handleAnnotationRemove(id).subscribe((response)=>{
                  this.dataSource.annotationRemove(id)
                  this.emitOuter('annotation-remove', id);
                  this._loadAnnotations();
                 })
            }
            else {
              this.dataSource.onAnnotationMenuClick(id)
              this.dataSource.annotationModalHidden = false;
            }

        default:
          break;
      }
    });

    EventHandler.globalEvents$.pipe(
      takeUntil(this.destroyed$)
    ).subscribe(({ type, payload }) => {
      switch(type){
        case 'global.tools-click':
          if(payload.source === 'create-custom-chart'){
            this.dataSource.deleteCustomChart$(payload.id).subscribe(() => {
              this.emitGlobal('navigate', [`/${_c('COMPOSE_CUSTOM_CHART')}`]);
            });
          } else if(payload.source === 'open-reorder-widgets-modal'){
          }
          break;

        default:
          break;
      }
    });
  }

  private _doChartRequest(){
    // this.dataSource.chartInterval = {...payload} ;
    const chartRequest$ = this.dataSource.chartRequest();
    chartRequest$.subscribe(response => {
      this.dataSource.loading = false;
      if(Array.isArray(response.data.series) && response.data.series.length){
        this.emitOuter('chart-request',  response);
      }
      // update empty text
      this.dataSource.updateEmptyText(response.data);
    });
  }

  private _doCompareChartRequest(){
    const chartRequest$ = this.dataSource.compareChartRequest();
    chartRequest$.subscribe(response => {
      this.dataSource.loading = false;
      if(Array.isArray(response.compareData.series) && response.compareData.series.length){
        this.emitOuter('compare-chart-request', response);
      }
    });
  }

  private _onFormAnnotationsSave(event){
    const newAnnotation: Annotation = {"x1": 0, "label": ""}
    newAnnotation["label"] = event.payload.label;
    if(event.payload.description){
      newAnnotation["description"] = event.payload.description;
    }
    if(event.payload.attachment){
      newAnnotation["attachment"] = event.payload.attachment;
    }

    newAnnotation["x1"]=event.payload.x1;
    newAnnotation["x2"]=event.payload.x2;

    const buoyId = this.dataSource.getBuoyId();
    const sensorId = this.dataSource.getSensorId();
    const elementId = this.dataSource.getElementId();
    newAnnotation["chart"] = `${buoyId}-${sensorId}-${elementId}`

    const request$ = this.dataSource.onAnnotationCreate(newAnnotation);
          request$.subscribe(response => {
            newAnnotation.id = `label-${response.id}`;
            this.emitOuter("newAnnotation", newAnnotation);
            this.emitOuter("close-annotations-modal");
            this.dataSource.setNewAnnotation(newAnnotation);
            this.dataSource.annotationModalHidden = true;
            this.dataSource.resetAnnotationForm();
            this._loadAnnotations();
          });

  }

  private _onFormAnnotationsEdit(event){
    const editedAnnotation = this.dataSource.handleFormAnnotationsEdit(event.payload);
    const request$ = this.dataSource.onAnnotationEdit(editedAnnotation);
    request$.subscribe(response => {
      this.emitOuter("annotation-edit", editedAnnotation);
      this.dataSource.annotationModalHidden = true;
      this.dataSource.resetAnnotationForm();
      this.dataSource.updateEditedAnnotation(editedAnnotation);
      this._loadAnnotations();
    });
  }

  private _loadAnnotations(): Observable<any>{
    return this.dataSource.loadAnnotations().subscribe(response => {
      this.emitOuter("load-annotations", response);
    });
  }

}
