import { DataSource } from '@n7-frontend/core';
import { VALUES_KEY, _c } from '@app/constants';

export class SortableWidgetsDS extends DataSource {
  private _grid: any;

  protected transform(data) {
    if (!data) return;

    // reset
    if(this._grid){
      this._grid.destroy();
    }

    const elementsConfig = _c(VALUES_KEY),
      items = data.charts.map(({ data: chartData }) => {
      let label = chartData.name;
      if(!label){
        label = elementsConfig[chartData.data.series[0].id].label;
      }
      return {
        loaded: false,
        id: chartData.id,
        label
      }
    });

    return {
      _setGrid: (instance) => this._grid = instance,
      _elementId: 'sortable-widgets-grid',
      classes: 'iwt-layout-home__sortable-widgets-container',
      items,
      options: {
        dragEnabled: true,
        sortData: {
          id: (item, element) => element.getAttribute('data-id')
        }
      }
    };
  }

  getWidgetsOrder(){
    const items = this._grid.getItems();
    return items.map(item => item._sortData.id);
  }
}
