import * as scrollToElement from 'scroll-to-element';

export const helpers = {
  core_hasOwn: Object.prototype.hasOwnProperty,

  snapshot(store) {
    let state;
    store.take(1).subscribe(s => state = s);
    return state;
  },

  isInThreshold(value, min, max){
    return value > min && value <= max;
  },

  valueToString(val): string {
    return helpers.isNumeric(val) ? val.toFixed(2) : val
  },

  minutesToString(n){
    let hours: any, minutes: any;

    // is a number
    if(n || n === 0){
      let rawHours = (n / 60);
      hours = Math.floor(rawHours);
      minutes = Math.round((rawHours - hours) * 60);

      if(hours < 10) {
        hours = '0' + hours;
      }
      if(minutes < 10) {
        minutes = '0' + minutes;
      }

    // no value/number
    } else {
      hours = '--';
      minutes = '--';
    }

    return `${hours}:${minutes}`;
  },

  // NOTE:
  //   immutAssign({}, {a: 2, b: 3}, {a: 1}) => {a: 1, b: 3}
  //   immutAssign({}, {a: 2, '<b': null}, {a: 1}) => {a: 1}
  //   immutAssign({}, {a: {a1: 1, a2: 2}, b: 3}, {a: {a1: 2}}) => {a: {a1: 2, a2: 2}, b: 3}
  //   immutAssign({}, {a: {a1: 1, a2: 2}, b: 3}, {'>a': {a1: 2}}) => {a: {a1: 2}, b: 3}
  immutAssign(dest, source, value) {
    let index, doOverwrite, result = Object.assign(dest, source);

    for (let i in value) {
      if (value.hasOwnProperty(i)) {
        if (i.substring(-1, 1) === '>') {
          doOverwrite = true;
          index = i.substr(1);
        } else {
          index = i;
        }

        if (i.substring(-1, 1) === '<') {
          delete result[i.substr(1)];
          continue;
        }

        if (helpers.isObject(value[i]) &&
          typeof result[index] !== 'undefined' &&
          !doOverwrite) {
          result[index] = helpers.immutAssign(helpers.isArray(value[i]) ?
            [] : {}, source[index], value[i]);
        } else {
          result[index] = value[i];
        }
      }
    }

    return result;
  },

  deepAssign(...args: any[]) {
    let options, name, src, copy, copyIsArray, clone;
    let target = arguments[0] || {}
      , i = 1
      , length = arguments.length;

    if (typeof target !== 'object' && !helpers.isFunction(target)) {
      target = {};
    }

    if (length === i) {
      target = this;
      --i;
    }

    for (; i < length; i++) {
      options = arguments[i];
      if (options !== null) {
        for (name in options) {
          if (options.hasOwnProperty(name)) {
            src = target[name];
            copy = options[name];

            if (target === copy) {
              continue;
            }

            copyIsArray = helpers.isArray(copy);
            if (copy && (helpers.isPlainObject(copy) || copyIsArray)) {
              if (copyIsArray) {
                copyIsArray = false;
                clone = src && helpers.isArray(src) ? src : [];

              } else {
                clone = src && helpers.isPlainObject(src) ? src : {};
              }

              target[name] = helpers.deepAssign(clone, copy);

            } else if (copy !== undefined) {
              target[name] = copy;
            }
          }
        }
      }
    }

    return target;
  },

  disabledEventPropagation(e: Event) {
    if (e) {
      if (e.stopPropagation) {
        e.stopPropagation();
      } else if (window.event) {
        window.event.cancelBubble = true;
      }
    }
  },

  isEmptyObject(obj) {
    return Object.keys(obj).length === 0 &&
      obj.constructor === Object;
  },

  getObjectsByKey(key, values) {
    if (!helpers.isObject(values)) {
      return;
    }

    let valuesKeys = Object.keys(values);

    let currents = valuesKeys.reduce((res, k) => {
      if (values[k][key]) {
        res.push(k);
      }
      return res;
    }, []);

    if (currents.length > 0) {
      return currents;
    }
  },

  getNewObjectInDeepCamelCase(oldObj, isArray?) {
    let key, camelKey, newObj, keys = Object.keys(oldObj);
    let n = keys.length;

    if (Array.isArray(oldObj) && !isArray) {
      return helpers.getNewObjectInDeepCamelCase(oldObj, true);
    }

    if (!isArray) {
      newObj = {};
      while (n--) {
        key = keys[n];
        camelKey = key.replace(/_\w/g, (m) => m[1].toUpperCase());
        if (camelKey === 'idRicetta') {
          camelKey = 'id';
        }
        if (typeof oldObj[key] === 'object') {
          newObj[camelKey] = helpers.getNewObjectInDeepCamelCase(
            oldObj[key], Array.isArray(oldObj[key]));
        } else {
          newObj[camelKey] = oldObj[key];
        }
      }
    } else {
      newObj = [];
      for (let i = 0; i < n; i++) {
        if (typeof oldObj[i] === 'object') {
          newObj.push(helpers.getNewObjectInDeepCamelCase(
            oldObj[i], Array.isArray(oldObj[i])));
        } else {
          newObj.push(oldObj[i]);
        }
      }
    }

    return newObj;
  },

  getNewObjectInCamelCase(oldObj) {
    let key, camelKey, keys = Object.keys(oldObj);
    let n = keys.length;
    let newObj = {};

    while (n--) {
      key = keys[n];
      camelKey = key.replace(/_\w/g, (m) => m[1].toUpperCase());
      newObj[camelKey] = oldObj[key];
    }

    return newObj;
  },

  isFunction(value) {
    return typeof value === 'function';
  },

  isObject(value) {
    // http://jsperf.com/isobject4
    return value !== null && typeof value === 'object';
  },

  isArray(value) {
    return Array.isArray(value);
  },

  isWindow(obj) {
    // In IE8 window.window !== window.window, so we allow == here.
    return obj !== null && obj === obj.window;
  },

  isPlainObject(obj) {
    if (!obj || typeof obj !== 'object' ||
      obj.nodeType || helpers.isWindow(obj)) {
      return false;
    }
    try {
      if (obj.constructor &&
        !helpers.core_hasOwn.call(obj, 'constructor') &&
        !helpers.core_hasOwn.call(obj.constructor.prototype, 'isPrototypeOf')) {
        return false;
      }
    } catch (e) {
      return false;
    }

    let key;
    for (key in obj) { if (key) { } }

    return key === undefined || helpers.core_hasOwn.call(obj, key);
  },

  pascalCaseToCamelCase(str) {
    return str.charAt(0).toLowerCase() + str.substring(1);
  },

  camelCaseToPascalCase(str) {
    return str.charAt(0).toUpperCase() + str.substring(1);
  },

  camelCaseToSpaces(str) {
    return str
      .replace(/([A-Z])/g, ' $1')
      .split(' ')
      .map(word => word.charAt(0).toLowerCase() + word.substring(1))
      .join(' ');
  },

  dashCaseToCamelCase(str) {
    return str.replace(/-([a-z])/ig, (all, letter) => {
      return letter.toUpperCase();
    });
  },

  snakeCaseToCamelCase(str) {
    return str.replace(/_([a-z])/ig, (all, letter) => {
      return letter.toUpperCase();
    });
  },

  dashCaseToSpaces(str) {
    return this.camelCaseToSpaces(this.dashCaseToCamelCase(str));
  },

  generateShortUID() {
    return new Date().getTime() + Math.floor(Math.random() * 100000);
  },

  generateUID(separator?) {
    separator = separator || '-';

    function S4() {
      return (((1 + Math.random()) * 0x10000) || 0).toString(16).substring(1);
    }
    return (
      S4() + S4() +
      separator + S4() +
      separator + S4() +
      separator + S4() +
      separator + S4() +
      S4() + S4()
    );
  },

  differenceInSecondsBetweensDates(date1: Date, date2: Date): number {
    return Math.abs(
      Math.round(
        (date1.getTime() - date2.getTime()) / 1000
      )
    );
  },

  mmssfromSecs(secs) {
    let hours = Math.floor(secs / 3600)
      , minutes = Math.floor((secs - (hours * 3600)) / 60)
      , seconds = secs - (hours * 3600) - (minutes * 60);

    seconds = Math.round(seconds * 100) / 100;

    // return (hours < 10 ? '0' + hours : hours) + ':' +
    return (minutes < 10 ? '0' + minutes : minutes) +
      ':' + (seconds < 10 ? '0' + seconds : seconds);
  },

  /**
   * Pads text
   *
   * @param text
   *   Text to pad.
   *
   * @param length
   *   Total lenght of output string(s).
   *
   * @param position
   *   Where to add fill char:
   *     - 'start'
   *     - 'end'
   *
   * @param method
   *   Padding method:
   *     - 'multiline'
   *     - 'truncate'
   *     - 'ellipsis' (implies 'truncate')
   * @param fillChar
   *   Fill character
   */
  padExtra(text, maxLength, position?, method?, fillChar?) {
    if (!text || !text.length) {
      return '';
    }
    let parts
      , output = []
      , returnEmpty = false
      , _pad
      , length = Math.min(text.length, maxLength)
    ;
    position = typeof position === 'undefined' ? 'start' : position;
    method = typeof method === 'undefined' ? 'truncate' : method;
    fillChar = typeof fillChar === 'undefined' ? ' ' : fillChar;

    _pad = (stringPart) => {
      let pad = Array(Math.max(0, maxLength - stringPart.length) + 1).join(fillChar)
        , resultString = stringPart;
      return position == 'start' ? pad + resultString : resultString + pad;
    };

    parts = text.match(new RegExp(".{1," + length + "}", "g"));

    switch (method) {
      case 'multiline':
        parts.forEach((part) => output.push(_pad(part)));
        break;
      case 'truncate':
        if (parts.length) {
          output.push(_pad(parts[0]));
        }
        break;
      case 'elipsis':
        output.push(_pad(parts[0]).substr(0, length - 3) + '...');
        break;
      default:
        returnEmpty = true;
    }

    if (returnEmpty) {
      return [];
    }

    return output;
  },
  ucFirst(str: string) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  },
  isNumeric(n){
    return !isNaN(parseFloat(n)) && isFinite(n);
  },


  /**
   * Function which picks randomly an element (of type any)
   * from a given array.
   *
   * @param array
   *   Array of elements from which to pick one randomly.
   *
   * @param probab
   *   Optional array containing the probability for each element to
   *   be picked (values between 0 and 1). If not given the array assumes
   *   all the elements have the same probability to be picked.
   *   The number of probabilities must be equal to the number of elements in
   *   the array, their values should neven be lower than 0 or greater than 1
   *   and the sum of all the probabilities has to equal 1, if any of those
   *   conditions is not met than the function will ignore the probabilities
   *   (and will prompt a warning in the console)
   *
   */
  randomPick(array: Array<any>,probab?: Array<number>){
      if(!probab)
      return array[ Math.floor(Math.random()*(array.length))];

      if(probab.length!=array.length){
        console.warn(`Wrong probability array!
          (its length must equal that of the input array).
          Ignoring the probabilities`);
        return this.randomPick(array);
      }

      for(var i=0;i<probab.length;i++){
        if(isNaN(probab[i])){
          console.warn(`Wrong probability array!
            (element number ${i} is not a number).
            Ignoring the probabilities`);
          return this.randomPick(array);
        }
        if(probab[i]<0){
          console.warn(`Wrong probability array!
            (element number ${i} is not negative).
            Ignoring the probabilities`);
          return this.randomPick(array);
        }
        if(probab[i]>1){
          console.warn(`Wrong probability array!
            (element number ${i} is bigger than 1).
            Ignoring the probabilities`);
          return this.randomPick(array);
        }
      }

      var sum = 0;
      probab.forEach(p => sum+=p);
      if(sum!=1){
            console.warn(`Wrong probability array!
              (their sum should be 1).
              Ignoring the probabilities`);
            return this.randomPick(array);
      }

      var rand = Math.random();
      var i=0;
      var randomIdx = -1;
      sum=0;
      while( randomIdx<0 && i<probab.length){
        sum += probab[i];
        if(rand<sum) randomIdx = i;
        i++
      }
      return array[randomIdx];
  },

  /**
   * Checks if a given value is a positive integer or not
   * @param value argument to check
   * @returns true if the value is a positive integer,false otherwise
   */
  isPositiveInteger(value:any): boolean{
    if(!helpers.isNumeric(value)) return false;
    var n = Number.parseFloat(value);
    if(!Number.isInteger(n)) return false;
    if(n<=0) return false;
    return true;
  },


  /**
   * Scolls to a given dom element
   * (
   *  IMPORTANT: the function needs/uses the scroll-to-element library
   *   link : https://www.npmjs.com/package/scroll-to-element
   * )
   *
   * @param domElement the (native/vanilla) dom element to scroll to
   * @param options object containing the options for the scroll
   *                (consult the link for mode informations), if not
   *                provided a default configuration will be set instead
   */
  scrollToDomElement(domElement:any,options?:any){
    if(!options) options = {offset:-10,duration:1000};
    scrollToElement(domElement,options);
  },

  convertToPowerNotation(value: number): string {
    if (Math.abs(value) >= 1000) {
        const powersOfTen = ['', 'K', 'M', 'B', 'T'];
        const power = Math.floor(Math.log10(Math.abs(value)) / 3);
        const index = Math.min(Math.floor(power), powersOfTen.length - 1);

        const formattedValue = (value / Math.pow(10, index * 3)).toFixed(2);
        return `${formattedValue}${powersOfTen[index]}`;
    } else {
        return value.toString();
    }
},


 getRandomColor(): string {
  
  const red = Math.floor(Math.random() * 256).toString(16).padStart(2, '0');
  const green = Math.floor(Math.random() * 256).toString(16).padStart(2, '0');
  const blue = Math.floor(Math.random() * 256).toString(16).padStart(2, '0');

  const color = `#${red}${green}${blue}`;
  return color;
}

};
