import { Component, HostListener, Input } from '@angular/core';

export interface IDropdownData {
  id: string,
  label: string,
  icon: string ,
  classes?: string
}
@Component({
  selector: "iwt-dropdown",
  templateUrl: "./dropdown.html",
})
export class DropdownComponent {
  @Input() data: IDropdownData;
  @Input() emit: any;

  constructor() {}

  @HostListener("document:click", ["$event.target"])
  clickedOut(targetElement) {
    if (!this.emit) return;
    this.emit("clickOut", targetElement);
  }
}
