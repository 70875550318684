import { DataSource } from '@n7-frontend/core';
import { ChartSelectFilterData } from '@app/components/chart-select-filter/chart-select-filter';
import { FiltersHelper } from '@app/helpers';



export class ItemSelectFilterDS extends DataSource {

  protected transform(data): ChartSelectFilterData {
    if(!data) return;

    const filter = FiltersHelper.getItemFilter();
    if(!filter) return;
    const {options} = filter;
    const items: any[] = [];

    options.map( (option) => {
      const {value, label} = option;
      const isSelected = false;
      const id = `${value.value}`
      items.push({
        label: label,
        isSelected,
        payload: {
          _id: id,
          ...value,
          isSelected
        },
        _meta: {
          id
        }
      })
    })

    return { items };
  }

  public onClick({ type, payload }){
    // set selected
    this.output.items.forEach(item => {
      item.isSelected = payload._id === item._meta.id;
      item.payload.isSelected = payload._id === item._meta.id;
    });
  }
}
