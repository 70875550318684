export const GET_SECTIONS_MOCK = {
  "data": {
    "getSections": [
        {
          "id": "metalli_m1",
          "label": "Metalli M1",
        },
        {
          "id": "metalli_m2",
          "label": "Metalli M2",
        },
        {
          "id": "metalli_m3",
          "label": "Metalli M3",
        },
        {
          "id": "idrocarburi_i1",
          "label": "Idrocarburi I1"
        },
        {
          "id": "idrocarburi_i2",
          "label": "Idrocarburi I2"
        },
        {
          "id": "idrocarburi_i3",
          "label": "Idrocarburi I3"
        },
        {
          "id": "batteri",
          "label": "Batteri",
        },
        {
          "id": "idrofono",
          "label": "Idrofono",
        }
    ]
  }
};