import { FiltersHelper } from '@app/helpers';
import { DataSource } from '@n7-frontend/core';


export class AnnotationDatepickerDS extends DataSource {
  private _datepicker: any = null;

  protected transform(data) {
    if(!data) return;
    if(this._datepicker) return; 
    
    const {custom} = FiltersHelper.getDateFilter();
    
    return {
      // to use on chart updates
      _setDatepicker: (datepicker) => this._datepicker = datepicker,
      _elementId: 'annotation-datepicker',
      options: {
        enableTime: custom.enableTime,
        dateFormat: 'Y-m-d H:i',
        defaultDate: [data.startDate, data.endDate],
        mode: "range"
      },
    };
  }

  open(){
    setTimeout(() => this._datepicker.open());
  }

  setDate(date: string[]){

    const [from, to] = date
    const dateFrom = new Date(from)
    const dateTo = new Date(to)
    this._datepicker.setDate([dateFrom, dateTo])
  }

 }
