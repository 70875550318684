import { Component, OnInit } from '@angular/core';
import { LayoutBuilder } from '@n7-frontend/core';

import * as DS from '@app/common/data-sources';
import * as EH from '@app/common/event-handlers';
import { ElementsListLayoutEH } from './elements-list-layout.eh';
import { ElementsListLayoutDS } from './elements-list-layout.ds';
import { Utils } from '@app/providers';

@Component({
  selector: 'elements-list-layout',
  templateUrl: './elements-list-layout.html'
})
export class ElementsListLayoutComponent implements OnInit {
  public lb = new LayoutBuilder('elements-list-layout');
  private widgets = [
    { id: 'parameter-cards' }
  ];

  constructor(
    private utils: Utils
  ) {
  }

  ngOnInit(){
    // on ready
    this.lb.ready$.subscribe(() => {
      this.lb.eventHandler.emitInner('init', {
        utils: this.utils,
      });
    });


    this.lb.init({
      widgetsConfig: this.widgets,
      widgetsDataSources: DS,
      widgetsEventHandlers: EH,
      dataSource: new ElementsListLayoutDS(),
      eventHandler: new ElementsListLayoutEH(),
    });
  }

  ngOnDestroy(){
    this.lb.eventHandler.emitInner('destroy');
  }

  onParameterFilterChange(value: string) {
    let payload = {
      'key': 'label',
      'value': value
    }
    this.lb.eventHandler.emitOuter('parameter-filter-change', payload);
  }
}