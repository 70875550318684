import { DataSource } from '@n7-frontend/core';
import { ChartSelectFilterData } from '@app/components/chart-select-filter/chart-select-filter';
import { FiltersHelper } from '@app/helpers';

type CompareOption = {
  default?: boolean,
  value: { value: string | number, unit: 'interval' | 'hours' | 'years' },
  label: string
}

export class CompareSelectFilterDS extends DataSource {
  // last selected w/default
  private _lastSelected: string = 'period1';

  protected transform(data): ChartSelectFilterData {
    if(!data) return;

    const {options, custom} = FiltersHelper.getCompareFilter();
    const items: any[] = [];

    options.map( (option: CompareOption)=> {
      const isSelected = option.default;
      
      const {label, value} = option;
      const id =  `${value.unit}${value.value}`;
      items.push({
        label,
        isSelected,
        payload: {
          _id: id,
          isSelected,
          ...value
        },
        _meta: {
          id: id
        }
      })
      if(isSelected) this._lastSelected = id
    })

    if(custom.enabled){
      items.push({
        label: 'i18n.chart_compare.select_options.custom',
        isSelected: false,
        payload: {
          _id: 'custom',
        },
        _meta: {
          id: 'custom'
        }
      })
    }

    return { items };
  }

  public onClick({ type, payload }){
    // set selected
    this.output.items.forEach(item => {
      if(item._meta.id === 'custom') return;
      item.isSelected = payload._id === item._meta.id;
      item.payload.isSelected = payload._id === item._meta.id;
    });
  }

  public getSelectedPayload(){
    return this.output.items.filter(item => item._meta.id === this._lastSelected)[0].payload;
  }
}
