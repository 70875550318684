export const LABELS_MAP = ():any => {
  return {
    main: {
      home: "i18n.top_headers.home",
      customized_view: "i18n.top_headers.custom_view",
      buoys: "i18n.top_headers.buoys",
      sensors: "i18n.top_headers.sensors",
      alarm_create: "i18n.alarm_create",
      alarm_update: "i18n.alarm_update",
      plant_section: "i18n.top_headers.plant_section",
      model: "i18n.top_headers.model",
      list_alerts: "i18n.top_headers.list_alerts",
      manage_alerts: "i18n.top_headers.manage_alerts",
      model_simulations: "i18n.top_headers.model_simulations",
      titrimetro_input: "i18n.top_headers.titrimetro_input",
      upload_data_analysis: "i18n.top_headers.upload_data_analysis",
      alerts_table: "i18n.alerts_table",
    },
    chartView: {
      model: "i18n.model",
      diff: "i18n.diff",
      alarm_text: "i18n.chart_actions.alarm_text",
      alarm_modal_title: "i18n.alarm_create.title",
      alarm_modal_description: "i18n.alarm_create.description",
      alarm_modal_save: "i18n.alarm_create.save",
      alarm_modal_success_msg: "i18n.alarm_create.success_msg",
      alarm_modal_error_msg: "i18n.alarm_create.error_msg",
      alerts_table: "i18n.alerts_table",
    },
    dashboard: {
      model: "i18n.model",
      diff: "i18n.diff",
      modal_delete_widget: "i18n.modal_delete_widget",
      drag_area_tooltip: "i18n.layouts.home.drag_area_tooltip",
    },
    nav: {
        home: "i18n.top_headers.home",
        customized_view: "i18n.top_headers.custom_view",
    },
    customizedView: {
        'multi': 'i18n.custom_chart.description_multi',
        'single': 'i18n.custom_chart.description_single',
        'noparams': 'i18n.custom_chart.description_noparams',
    }
  };
};
