import { Component, Input, AfterContentChecked } from '@angular/core';
import ApexCharts from 'apexcharts/dist/apexcharts.common.js';

@Component({
  selector: 'iwt-chart',
  templateUrl: './chart.html'
})
export class Chart implements AfterContentChecked {
  @Input() data: any;
  @Input() emit: any;
  private _loaded: boolean = false;

  // FIXME: provare con ngAfterViewInit
  // che dovrebbe passare solo una volta
  ngAfterContentChecked(){
    if(!this.data || this._loaded) return;

    this._loaded = true;

    setTimeout(() => {
      const { _elementId, options } = this.data;
      const chart = new ApexCharts(document.querySelector(`#${_elementId}`), options);
      chart.render();

      // setting chart on parent data source
      this.data._setChart(chart);
    });
  }

  onMouseUp(payload){
    if(!this.emit) return;
    if(payload === 'btn')
      this.emit('mouseUp', payload);
  }

  onClick(payload){
    if(!this.emit) return;
    this.emit('click')
  }
}