import { FiltersHelper } from '@app/helpers';
import { DataSource } from '@n7-frontend/core';


export class ChartDatepickerDS extends DataSource {
  private _datepicker: any = null;

  protected transform(data) {
    if(!data) return;

    const {custom} = FiltersHelper.getDateFilter();

    return {
      // to use on chart updates
      _setDatepicker: (datepicker) => this._datepicker = datepicker,
      _elementId: 'datepicker',
      options: {
        enableTime: custom.enableTime,
        dateFormat: 'Y-m-d H:i',
        defaultDate: [data.startDate, data.endDate],
        mode: "range"
      },
    };
  }

  open(){
    setTimeout(() => this._datepicker.open());
  }

 }
