import { LayoutDataSource } from "@n7-frontend/core";
import { Observable, combineLatest, of } from 'rxjs';
import { map, switchMap, tap } from "rxjs/operators";
import { ELEMENTS_KEY, LABELS_KEY, VIEWS_KEY, _c } from "@app/constants";
import {
  ComparePeriodHelper,
  dateHelpers,
  IntervalHelper,
  ItemFilterHelper,
  ItemFilterType,
} from "@app/helpers";
import { CommunicationService } from "@app/services/communication.service";
import {
  ChartComparePeriod,
  ChartData,
  ChartInterval,
  ChartNoData,
  CompareChartType,
  NormalChartType,
  Annotation
} from "../home-layout/home-layout.types";
import { ObserveOnSubscriber } from "rxjs/internal/operators/observeOn";

export class ChartLayoutDS extends LayoutDataSource {
  static customChartData: any;

  private communication: CommunicationService;

  private labels: any;
  private buoyId: string;
  private sensorId: string;
  private elementId: string;

  private seriesIds: string[];
  private chartId: string;

  public chartInterval: ChartInterval;
  public annotationInterval: ChartInterval;
  public chartComparePeriod: ChartComparePeriod;
  public itemFilter: ItemFilterType;

  private _selectedDateStart: string;
  private _selectedDateEnd: string;
  private _selectedAnnotationDateStart: string;
  private _selectedAnnotationDateEnd: string;
  private _selectedCompareDateStart: string;
  private _selectedCompareDateEnd: string;

  public source: string;

  private alertsTable: any;
  private alertsTableOpen: boolean = false;

  private _lastChartResponse: any;

  public chartSelectLabel: string;
  public compareSelectLabel: string;
  public itemFilterLabel: string;
  public datepickerIsOpen: boolean = false;
  public annotationDatepickerIsOpen: boolean = false;
  public datepickerCompareIsOpen: boolean = false;
  public compareSelectIsVisible: boolean = false;

  private _hasAlerts: boolean = false;
  private _hasItemFilter: boolean = false;
  private _hasCompare: boolean = false;

  public chartNotFound: boolean = false;
  public chartEmptyText: string;

  public chartMode: string;

  public elementConfig: any;

  public newAnnotation: Annotation;

  public loading: boolean = false;

  private annotationMenus: any = {}
  public annotations: Annotation[]= [];
  private tippy: any;
  private tippyTemplate: any;
  public annotationModalHidden: boolean = true;
  public annotationCheckbox: boolean = false;

  onInit(payload) {
    this.communication = payload.communication;
    this.elementId = payload.elementId;
    this.tippy = payload.tippy;
    this.buoyId = payload.buoyId;
    this.sensorId = payload.sensorId;
    this.chartId = payload.chartId;
    this.source = payload.source;
    this.labels = _c(LABELS_KEY).chartView;
    if (this.source === _c('SENSORI')){
      this.initViewItems()
    }else if (this.source === _c('VIEW_CUSTOM_CHART')){
     this.initViewCustomItems()
    }
    // update select label
    this.updateDateSelectLabel();
    this.updateItemSelectLabel();

    this.one("chart-legend").updateOptions({
      labels: this.labels,
      elementConfig: this.elementConfig,
      hasSensorLabel: _c('VIEW_CUSTOM_CHART') == this.source,
    });

  }

  private initViewItems(){
    const elementId = [this.buoyId, this.sensorId, this.elementId].join("-");
    const elementsConfig = _c(ELEMENTS_KEY)[elementId];
    this.elementConfig = elementsConfig;
    const seriesIds = elementsConfig.values.map( value => `${value.id}`);
    this.seriesIds = seriesIds;

    this.setChartInterval(IntervalHelper.getDefaultInterval());
    this.setItemFilter(ItemFilterHelper.getDefaultInterval());
    this.some(["chart", "chart-actions", "chart-alerts-table"]).updateOptions({
      labels: this.labels,
      elementConfig: this.elementConfig,
    });
    const { filters, actions } = _c(VIEWS_KEY).itemsView;
    this.one("chart-actions").update({ hasAlerts: true, actions });
    this._hasCompare = filters.compareFilter;
    this._hasItemFilter = filters.itemFilter;
    this._loadNormalChart();
  }

  private initViewCustomItems(){
    this.setChartInterval(IntervalHelper.getDefaultInterval());
    this.setItemFilter(ItemFilterHelper.getDefaultInterval());
    this.some(["chart", "chart-actions", "chart-alerts-table"]).updateOptions({
      labels: this.labels,
      elementConfig: this.elementConfig,
    });
    const { filters, actions } = _c(VIEWS_KEY).customChartView;
    this._hasCompare = filters.compareFilter;
    this._hasItemFilter = filters.itemFilter
    this.one("chart-actions").update({ hasAlerts: false, actions });
    this._loadCustomChart();
  }

  onCompareCheckboxChange(payload) {
    this.compareSelectIsVisible = payload;
    this.updateDateSelectLabel();
    this.updateItemSelectLabel();

    this.datepickerCompareIsOpen = false;
    const { type, value, unit } = this.getCompareSelectPayload();
    this.setChartComparePeriod({ type, value, unit });
  }

  getCompareSelectPayload() {
    const ds = this.widgets["compare-select-filter"].ds;
    return ds.getSelectedPayload();
  }

  chartRequest(): Observable<any> {
    this.loading = true;
    // config
    let params: any = {};

    // custom chart

    if ([_c('SENSORI'), _c('VIEW_CUSTOM_CHART')].includes(this.source)) {
      params["seriesIds"] = ItemFilterHelper.filter(
        this.seriesIds,
        this.itemFilter
      );
      params["range"] = IntervalHelper.convertToDateRange(this.chartInterval);
    }

    // update select label
    this.updateDateSelectLabel();
    this.updateItemSelectLabel();

    let annotations = [0];
    const chartId = this.getChartID();
    const annotationRequest$ = params.seriesIds && params.seriesIds.length ? this.communication.request$("/annotation/search", {chartId: chartId, range: params.range}) : of([]);

    const chartDataRequest$ = this.communication.request$('data', params);

    return combineLatest(
      annotationRequest$,
      chartDataRequest$    ).pipe(
        tap((responses) =>{
          this.buildChartData(responses);
        }),
        map((responses)=>{
          annotations = responses[0];
          const mergedResponse = {
            interval: this.chartInterval,
            data: {...responses[1], annotations},
            type: NormalChartType,
          };
          return (mergedResponse);
        })
      )
  }

  buildChartData([annotationResponse, chartDataResponse]){
    this.chartMode = NormalChartType;
    this._lastChartResponse = chartDataResponse;
    this.annotations = [];
    annotationResponse.forEach((element) => {
      element.id = `label-${element.id}`;
      this.setNewAnnotation(element);
      this.chartMode = NormalChartType;
      this._lastChartResponse = chartDataResponse;
    });

  }

  compareChartRequest(): Observable<any> {
    this.loading = true;
    this.updateCompareSelectLabel();

    const compareSeries = {
      seriesIds: ItemFilterHelper.filter(this.seriesIds, this.itemFilter),
      range: ComparePeriodHelper.convertToDateRange(
        this.chartComparePeriod,
        this.chartInterval
      ),
    };

    return this.communication.request$("/data", compareSeries).pipe(
      tap((response: any) => (this.chartMode = CompareChartType)),
      switchMap((response: ChartData) => {
        const mergedResponse = {
          interval: this.chartInterval,
          data: this._lastChartResponse,
          type: CompareChartType,
          comparePeriod: this.chartComparePeriod,
          compareData: response,
        };
        return of(mergedResponse);
      })
    );
  }

  addToDashboardRequest(): Observable<any> {
    const config = this.getChartConfig();
    return this.communication.request$("chart/create", config);
  }

  private updateDateSelectLabel() {
    const chartSelectLabel = this.getIntervalLabel(this.chartInterval);
    this.one("date-filter-dropdown").update({
      id: "dateFilterDropdown",
      label: chartSelectLabel,
      icon: 'n7-icon-angle-down',
      classes: this.compareSelectIsVisible ?  'is-disabled' : ''
    });
  }

  private updateItemSelectLabel() {
    const itemFilterLabel = ItemFilterHelper.getFilterLabel(this.itemFilter);
    this.one("item-filter-dropdown").update({
      id: "itemFilterDropdown",
      label: itemFilterLabel,
      icon: 'n7-icon-angle-down',
      classes: this.compareSelectIsVisible ?  'is-disabled' : ''
    });
  }

  private getIntervalLabel = (interval: ChartInterval) => {
    if (interval.type === "dynamic") {
      return IntervalHelper.getDynamicIntervalLabel(interval);
    } else {
      const dateFormat = "YYYY-MM-DD HH:mm";;
      const startDateStr = dateHelpers.toString(interval.from, dateFormat);
      const endDateStr = dateHelpers.toString(interval.to, dateFormat);
      return `${startDateStr} - ${endDateStr}`;
    }
  };

  private updateCompareSelectLabel() {
    if (this.chartComparePeriod.type == "static") {
      const dateFormat = "YYYY-MM-DD HH:mm";
      const { from, to } = this.chartComparePeriod;
      const startDate = dateHelpers.toString(from, dateFormat);
      const endDate = dateHelpers.toString(to, dateFormat);
      this.compareSelectLabel = `${startDate} - ${endDate}`;
    } else {
      this.compareSelectLabel =
        ComparePeriodHelper.getDynamicComparePeriodLabel(
          this.chartComparePeriod
        );
      }
      this.one("compare-filter-dropdown").update({
        id: "compareFilterDropdown",
        label: this.compareSelectLabel,
        icon: 'n7-icon-angle-down',
      });
  }

  onDatepickerChange({ type, payload }) {
    const { selectedDates, dateStr, instance } = payload;

    const [from, to] = dateStr.split(" to "),
      fromDate = from ? new Date(from) : null,
      toDate = to ? new Date(to) : null;

    let isDiff = false;
    if (fromDate && toDate) {
      const timeDiff = Math.abs(toDate.getTime() - fromDate.getTime()),
        diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
      if (diffDays > _c("dateRangeLimit") - 1) {
        instance.setDate([fromDate, fromDate]);
        isDiff = true;
      }
    }
    if (Array.isArray(selectedDates) && selectedDates.length === 2) {
      const [dateStart, dateEnd] = selectedDates;
      this._selectedDateStart = dateStart;
      this._selectedDateEnd = isDiff ? dateStart : dateEnd;
      if (isDiff) {
        instance.open();
      }
    }
  }


  onAnnotationDatepickerChange({ type, payload }) {
    const { selectedDates, dateStr, instance } = payload;

    const [from, to] = dateStr.split(" to "),
      fromDate = from ? new Date(from) : null,
      toDate = to ? new Date(to) : null;

    let isDiff = false;
    if (fromDate && toDate) {
      const timeDiff = Math.abs(toDate.getTime() - fromDate.getTime()),
        diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
      if (diffDays > _c("dateRangeLimit") - 1) {
        instance.setDate([fromDate, fromDate]);
        isDiff = true;
      }
    }
    if (Array.isArray(selectedDates) && selectedDates.length === 2) {
      const [dateStart, dateEnd] = selectedDates;
      this._selectedAnnotationDateStart = dateStart;
      this._selectedAnnotationDateEnd = isDiff ? dateStart : dateEnd;
      if (isDiff) {
        instance.open();
      }
    }
  }

  onCompareDatepickerChange({ type, payload }) {
    const { selectedDates, instance } = payload;
    const diff = IntervalHelper.getDiff(this.chartInterval);
    if (Array.isArray(selectedDates) && selectedDates.length === 1) {
      const dateStart = selectedDates[0];
      this._selectedCompareDateStart = dateStart;
      this._selectedCompareDateEnd = dateHelpers.add(dateStart,diff,'hours');
    }
    this.updateDateSelectLabel();
  }

  openDatepicker() {
    this._selectedDateStart = null;
    this._selectedDateEnd = null;
    this.datepickerIsOpen = true;
  }

  openAnnotationDatepicker(){
    this._selectedAnnotationDateStart = null;
    this._selectedAnnotationDateEnd = null;
    this.annotationDatepickerIsOpen = true;
  }

  openCompareDatepicker() {
    this._selectedCompareDateStart = null;
    this._selectedCompareDateEnd = null;
    this.datepickerCompareIsOpen = true;
  }

  datesSelected() {
    return this._selectedDateStart && this._selectedDateEnd;
  }

  annotationDatesSelected() {
    return this._selectedAnnotationDateStart && this._selectedAnnotationDateEnd;
  }

  compareDatesSelected() {
    return this._selectedCompareDateStart && this._selectedCompareDateEnd;
  }

  onDatepickerSubmit() {
    // close datepicker
    this.datepickerIsOpen = false;
    this.setChartInterval({
      type: "static",
      from: this._selectedDateStart,
      to: this._selectedDateEnd,
    });
  }

  onAnnotationDatepickerSubmit() {
    // close datepicker
    this.annotationDatepickerIsOpen = false;
    this.setAnnotationInterval({
      type: "static",
      from: this._selectedAnnotationDateStart,
      to: this._selectedAnnotationDateEnd,
    });
  }

  getChartInterval() {
    return this.chartInterval;
  }

  getAnnotationInterval() {
    return this.annotationInterval;
  }

  setChartInterval(interval: ChartInterval) {
    this.chartInterval = interval;
  }

  setAnnotationInterval(interval: ChartInterval) {
    this.annotationInterval = interval;
  }

  setChartComparePeriod(period: ChartComparePeriod) {
    this.chartComparePeriod = period;
  }

  setItemFilter(filter: ItemFilterType) {
    this.itemFilter = filter;
  }

  getCurrentDates() {
    if (this.chartInterval.type === "dynamic") {
      const endDate = dateHelpers.toString(new Date());
      const { unit, value } = this.chartInterval;
      const startDate = dateHelpers.toString(
        dateHelpers.subtract(new Date(), value, unit)
      );
      return {
        startDate,
        endDate,
      };
    } else {
      return {
        startDate: dateHelpers.toString(this.chartInterval.from),
        endDate: dateHelpers.toString(this.chartInterval.to),
      };
    }
  }

  getCurrentCompareDates() {
    if (this.chartComparePeriod.type === "dynamic") {
      const endDate = dateHelpers.toString(new Date());
      const { unit, value } = this.chartComparePeriod;
      const startDate = dateHelpers.toString(
        dateHelpers.subtract(new Date(), value, unit)
      );
      //TODO COMVERT DINAMYC
      return {
        startDate,
        endDate,
      };
    } else {
      return {
        endDate: dateHelpers.toString(this.chartComparePeriod.to),
      };
    }
  }

  onCompareDatepickerSubmit() {
    this.datepickerCompareIsOpen = false;
    this.chartComparePeriod = {
      type: "static",
      from: this._selectedCompareDateStart,
      to: this._selectedCompareDateEnd,
    };
    this.updateCompareSelectLabel()
  }

  hasAlerts() {
    return this._hasAlerts;
  }

  hasCompare() {
    return this._hasCompare;
  }

  hasItemFilter() {
    return this._hasItemFilter;
  }

  onLoadAlertModal() {
    this.alertsTable.hide();
  }

  deleteCustomChart$(id:string) {
    return this.communication.request$("chart/delete",{id});
  }

  getElementId = () => this.elementId;
  getSensorId = () => this.sensorId;
  getBuoyId = () => this.buoyId;

  getChartConfig = () => {
    const seriesIds = ItemFilterHelper.filter(this.seriesIds, this.itemFilter);
    const interval = this.getChartInterval();
    const type = this.chartMode;
    if (this.chartMode === NormalChartType) {
      return {
        seriesIds,
        interval,
        type,
      };
    } else {
      return {
        seriesIds,
        interval,
        type,
        comparePeriod: this.chartComparePeriod,
        compareSeriesIds: seriesIds,
      };
    }
  };

  getChartId = () => this.chartId;

  isCompare = () => this.compareSelectIsVisible;

  updateEmptyText(response: ChartData) {
    if (response && response.series && response.series.length) {
      this.chartEmptyText = null;
    } else {
      this.chartEmptyText = "i18n.layouts.chart.no_values";
    }
  }

  private _handleChartRequest(response: any) {

    this.some([
      "chart-datepicker",
      "compare-select-filter",
      "chart-datepicker-compare",
      "annotation-datepicker",
    ]).update(response);

    this.one("form-annotations").update({datepicker: true})

    // chart select
    this.one("date-select-filter").update({});
    this.one("item-select-filter").update({});

    this.updateDateSelectLabel();
    this.updateItemSelectLabel();

    // TODO GESTIRE MANCANZA DI DATI
    this.updateEmptyText(response);

    if (response) {
      this.some(["chart", "chart-legend", "y-axis-checkbox"]).update({ data: response, tippy: this.tippy });
    }
  }

  public toggleAlertsTablePopover() {
    if (!this.alertsTable) {
      const template = document.getElementById("alerts-popover");
      template.style.display = "block";

      this.alertsTable = this.tippy("#chart-alerts-action", {
        content: template,
        trigger: "manual",
        interactive: true,
        arrow: true,
        theme: "light-border no-padding",
        placement: "bottom-end",
        onHidden: () => (this.alertsTableOpen = false),
      })[0];
    }

    if (this.alertsTableOpen) {
      this.alertsTable.hide();
    } else {
      this.alertsTable.show();
    }

    this.alertsTableOpen = !this.alertsTableOpen;
  }

  private _loadNormalChart() {
    const seriesIds = ItemFilterHelper.filter(this.seriesIds, this.itemFilter);
    const range = IntervalHelper.convertToDateRange(this.chartInterval);
    this._loadChart("data", {
      seriesIds,
      range
    });
  }

  private buildCustomChartRequest(){
   return this.communication.request$("chart/get_custom").pipe(
     map((resp) => {
       if (resp && resp.results && resp.results.length) {
         const firstResult = resp.results[0];
         return firstResult;
       } else {
         return;
       }
     })
   );
  }

  private _loadCustomChart() {
    const source$ = ChartLayoutDS.customChartData
      ? of(ChartLayoutDS.customChartData)
      : this.buildCustomChartRequest();

    source$.subscribe((chartNoData:ChartNoData) => {
      if (chartNoData) {
        this.seriesIds = chartNoData.seriesIds;
        this.chartMode = chartNoData.type;
        const params = {
          seriesIds: ItemFilterHelper.filter(this.seriesIds, this.itemFilter),
          range: IntervalHelper.convertToDateRange(this.chartInterval),
        };
        this._loadChart("data", params);
      } else {
        this.chartNotFound = true;
        console.log("chart not found!");
      }
    });
  }

  private _loadChart(method, params) {
    this.loading = true;
    this.communication.request$(method, params).subscribe((response) => {
      this.loading = false;
      if (response) {
        this.chartMode = NormalChartType;
        this._lastChartResponse = response;
        
        if(this.annotationCheckbox){
          const chartId = this.getChartID();
          const annotationRequest$ = params.seriesIds && params.seriesIds.lenght ?
             this.communication.request$("/annotation/search", {chartId: chartId, range: params.range}) : 
             of([]);
          annotationRequest$.subscribe((annotationsResponse) =>{
           annotationsResponse.forEach((element) => {
             element.id = `label-${element.id}`
             this.setNewAnnotation(element);
           }) 
           response = {...response, annotations: annotationsResponse}
           this._handleChartRequest(response);
         })

        }else{
          this._handleChartRequest(response)
        }
      } else {
        this.chartNotFound = true;
      }
    });
  }

  setAnnotationX(payload) {
    const annotation = {"label": "", "x1": 0};
    annotation["x1"] = payload.xmin;
    if(payload.xmax)
      annotation["x2"] = payload.xmax;
    this.newAnnotation = annotation;

    const date1 = new Date(payload.xmin);
    const date2 = new Date(payload.xmax);
    this._selectedAnnotationDateStart = date1.toString();
    this._selectedAnnotationDateEnd =  date2.toString();
    this.setAnnotationInterval({
      type: "static",
      from: this._selectedAnnotationDateStart,
      to: this._selectedAnnotationDateEnd,
    });

    return [payload.xmin, payload.xmax];

  }

  onAnnotationCreate(newAnnotation){
    return this.communication.request$('annotation/create', newAnnotation);
  }

  setNewAnnotation(newAnnotation: Annotation){
    this.annotations.push(newAnnotation);
    this.annotationMenus[newAnnotation.id] = {instance: null, open: false};
  }

  onActionClick(payload) {
    this.toggleAnnotationMenu(payload);
  }

  onAnnotationMenuClick(id) {
      this._handleAnnotationEdit(id);
  }

  private _handleAnnotationEdit(id) {

      let indexToEdit;
      this.annotations.forEach((annotation, index) => {
        if (annotation.id === id) indexToEdit = index;
      });

      const dateStart = new Date(this.annotations[indexToEdit].x1);
      const dateEnd = new Date(this.annotations[indexToEdit].x2);
      this._selectedAnnotationDateStart = dateStart.toString();
      this._selectedAnnotationDateEnd = dateEnd.toString();

      this.one("form-annotations").update({ ...this.annotations[indexToEdit], _edit: true });

  }


 handleAnnotationRemove(id) {
    return this.communication.request$("annotation/delete", { id });
  }

  annotationRemove(id){
    let indexToRemove;
      this.annotations.forEach((annotation, index) => {
        if (annotation.id === id) indexToRemove = index;
      });
      this.annotations.splice(indexToRemove, 1);
  }

  getSelectedAnnotationId() {
    let id: any = null;
    Object.keys(this.annotationMenus).forEach((annotationId) => {
      if (this.annotationMenus[annotationId].open) id = annotationId;
    });
    return id;
  }

  toggleAnnotationMenu(annotationId) {

    if (this.annotationMenus[annotationId].open){
      this.annotationMenus[annotationId].instance.hide();
      this.annotationMenus[annotationId].open = false;
    }
    else{
      if(!this.tippyTemplate){
        const template = document.getElementById("annotation-menu-default");
        template.style.display = "block";
        this.tippyTemplate = template;
      }

      if(this.annotationMenus[annotationId].instance){
        try {
          this.annotationMenus[annotationId].instance.destroy();
        } catch (error) {
          console.log(error);
        }
      }

        this.annotationMenus[annotationId].instance = this.tippy("text." + annotationId, {
          content: this.tippyTemplate,
          trigger: "manual",
          interactive: true,
          arrow: true,
          theme: "light-border",
          onHidden: (instance)=>{instance.destroy(); this.annotationMenus[annotationId].open=false;}
          })[0];

          this.annotationMenus[annotationId].open = !this.annotationMenus[annotationId].open;

          if (this.annotationMenus[annotationId].open) {
            this.annotationMenus[annotationId].instance.show();
          }
    }
  }

  resetAnnotationForm(){
    this.one("form-annotations").update({_edit: false, label: "", description: "", attachment: []});
  }

  handleFormAnnotationsEdit(payload){

    if(!payload.description){
      delete payload.description;
    }
    if(payload.formNotValid){
      delete payload.formNotValid;
    }
    delete payload.userId;
    delete payload._edit;
    return payload;
  }

  onAnnotationEdit(editedAnnotation){
    return this.communication.request$('annotation/update',editedAnnotation);
  }

  updateEditedAnnotation(editedAnnotation){
    const indexToEdit = this.annotations.findIndex(annotation => annotation.id == editedAnnotation.id);
    this.annotations[indexToEdit] = editedAnnotation;
    return editedAnnotation;

  }

  annotationCheckboxChange(value: boolean){
    this.annotationCheckbox = value;
    if(!value){
      this.annotations = [];
      this.annotationMenus = {};
      }
    }
  

  loadAnnotations(): Observable<any> {
    const seriesIds = ItemFilterHelper.filter(this.seriesIds, this.itemFilter);
    const range = IntervalHelper.convertToDateRange(this.chartInterval);
    const chartId = this.getChartID();
    const request$ = seriesIds.length ? this.communication.request$("/annotation/search", { chartId: chartId, range: range }) : of([]);
    return request$.pipe(
      switchMap((response) => {
        response.forEach((element) => {
          element.id = `label-${element.id}`;
          this.setNewAnnotation(element);
        })
        return of({ interval: this.chartInterval, annotations: response });

      })
    )
  }

  getChartID(): string{
    const buoyId = this.getBuoyId();
    const sensorId = this.getSensorId();
    const elementId = this.getElementId();
    return `${buoyId}-${sensorId}-${elementId}`
  }

}


