import { DataSource } from '@n7-frontend/core';

export class UserMenuDS extends DataSource {
  protected transform(data) {
    return {
      items: [{
        label: 'i18n.user_menu.exit',
        icon: 'n7-icon-arrow-right',
        payload: 'logout'
      }]
    }
  }
}
