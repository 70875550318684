import { LayoutDataSource } from '@n7-frontend/core';
import { of, Observable } from 'rxjs';
import { NormalChartType } from '../home-layout/home-layout.types';
import { LABELS_KEY, _c } from '@app/constants';

export class CustomizedViewLayoutDS extends LayoutDataSource {
  private communication: any;
  private translate: any;
  protected labels;

  onInit(payload){
    this.communication = payload.communication;
    this.translate = payload.translate;
    // preloaded translations
    this._loadTranslatedLabels();
    this.one('elements-accordion').updateOptions({ labels: this.labels });
    this.one('elements-accordion').update({});
  }

  onElementsSave(payload): Observable<any> {
    const seriesIds = payload;
    if(seriesIds.length){
      const params = {
        seriesIds,
        isCustom: true,
        type: NormalChartType
      }
      return this.communication.request$('chart/create',params);
    } else {
      return of(null);
    }
  }

  protected _loadTranslatedLabels(){
    this.labels = _c(LABELS_KEY).customizedView;
  }
}
