export const GET_PARAMETERS_MOCK = {
  "data": {
    "getParameters": [
      {
        "id": "ing-nnh4",
        "label": "N_NH4",
        "color": "#da4823",
        "hasModel": false,
        "unit": {
          "id": "CELSIUS",
          "label": "°C"
        },
        "section": {
          "id": "metalli_m1",
          "label": "Metalli M1"
        }
      },
      {
        "id": "ing-ph",
        "label": "pH",
        "color": "#b2d20b",
        "hasModel": true,
        "unit": null,
        "section": {
          "id": "metalli_m1",
          "label": "Metalli M1"
        }
      },
      {
        "id": "ing-sed",
        "label": "Sed.",
        "color": "#96c04a",
        "hasModel": false,
        "unit": {
          "id": "CELSIUS",
          "label": "°C"
        },
        "section": {
          "id": "metalli_m1",
          "label": "Metalli M1"
        }
      },
      {
        "id": "ing-attq",
        "label": "Azoto Totale TQ",
        "color": "#30d0e5",
        "hasModel": false,
        "unit": {
          "id": "KILO_WATT",
          "label": "KW"
        },
        "section": {
          "id": "metalli_m1",
          "label": "Metalli M1"
        }
      },
      {
        "id": "ing-cdfr",
        "label": "COD_Filtr",
        "color": "#b34fb8",
        "hasModel": false,
        "unit": {
          "id": "MILLIGRAM_LITER",
          "label": "mg/l"
        },
        "section": {
          "id": "metalli_m1",
          "label": "Metalli M1"
        }
      },
      {
        "id": "ing-ntf",
        "label": "N_TOT_Filtr",
        "color": "#89b739",
        "hasModel": false,
        "unit": {
          "id": "CELSIUS",
          "label": "°C"
        },
        "section": {
          "id": "metalli_m1",
          "label": "Metalli M1"
        }
      },
      {
        "id": "ing-nno2",
        "label": "N_NO2",
        "color": "#1ef869",
        "hasModel": true,
        "unit": {
          "id": "MILLIGRAM_LITER",
          "label": "mg/l"
        },
        "section": {
          "id": "metalli_m1",
          "label": "Metalli M1"
        }
      },
      {
        "id": "ing-nno3",
        "label": "N_NO3",
        "color": "#855a7f",
        "hasModel": false,
        "unit": {
          "id": "CELSIUS",
          "label": "°C"
        },
        "section": {
          "id": "metalli_m1",
          "label": "Metalli M1"
        }
      },
      {
        "id": "ing-frro",
        "label": "Ferro",
        "color": "#445e35",
        "hasModel": true,
        "unit": {
          "id": "METER_CUBE",
          "label": "m3"
        },
        "section": {
          "id": "metalli_m1",
          "label": "Metalli M1"
        }
      },
      {
        "id": "ing-nichl",
        "label": "Nichel",
        "color": "#5682b0",
        "hasModel": false,
        "unit": {
          "id": "VOLT",
          "label": "V"
        },
        "section": {
          "id": "metalli_m1",
          "label": "Metalli M1"
        }
      },
      {
        "id": "ing-pmb",
        "label": "Piombo",
        "color": "#038efa",
        "hasModel": false,
        "unit": null,
        "section": {
          "id": "metalli_m1",
          "label": "Metalli M1"
        }
      },
      {
        "id": "ing-idrc",
        "label": "Idrocarburi",
        "color": "#d73757",
        "hasModel": true,
        "unit": {
          "id": "METER_CUBE",
          "label": "m3"
        },
        "section": {
          "id": "metalli_m1",
          "label": "Metalli M1"
        }
      },
      {
        "id": "ing-ss105",
        "label": "SS105",
        "color": "#544255",
        "hasModel": false,
        "unit": {
          "id": "VOLT",
          "label": "V"
        },
        "section": {
          "id": "metalli_m1",
          "label": "Metalli M1"
        }
      },
      {
        "id": "ing-uptk",
        "label": "UPTAKE",
        "color": "#b53d98",
        "hasModel": false,
        "unit": {
          "id": "CELSIUS",
          "label": "°C"
        },
        "section": {
          "id": "metalli_m1",
          "label": "Metalli M1"
        }
      },
      {
        "id": "ing-toc",
        "label": "TOC",
        "color": "#a25f6f",
        "hasModel": true,
        "unit": null,
        "section": {
          "id": "metalli_m1",
          "label": "Metalli M1"
        }
      },
      {
        "id": "ing-cr",
        "label": "CROMO",
        "color": "#c71ec3",
        "hasModel": true,
        "unit": {
          "id": "KILO_WATT",
          "label": "KW"
        },
        "section": {
          "id": "metalli_m1",
          "label": "Metalli M1"
        }
      },
      {
        "id": "ing-clor",
        "label": "CLORURI",
        "color": "#f77692",
        "hasModel": false,
        "unit": {
          "id": "VOLT",
          "label": "V"
        },
        "section": {
          "id": "metalli_m1",
          "label": "Metalli M1"
        }
      },
      {
        "id": "ing-svi",
        "label": "SVI",
        "color": "#a746c5",
        "hasModel": true,
        "unit": {
          "id": "VOLT",
          "label": "V"
        },
        "section": {
          "id": "metalli_m1",
          "label": "Metalli M1"
        }
      },
      {
        "id": "ing-norg",
        "label": "N_ORG",
        "color": "#20d77c",
        "hasModel": false,
        "unit": {
          "id": "MILLIGRAM_LITER",
          "label": "mg/l"
        },
        "section": {
          "id": "metalli_m1",
          "label": "Metalli M1"
        }
      },
      {
        "id": "civ-sed",
        "label": "Sed.",
        "color": "#d01204",
        "hasModel": true,
        "unit": {
          "id": "MILLIGRAM_LITER",
          "label": "mg/l"
        },
        "section": {
          "id": "metalli_m2",
          "label": "Metalli M2"
        }
      },
      {
        "id": "civ-attq",
        "label": "Azoto Totale TQ",
        "color": "#a00b22",
        "hasModel": true,
        "unit": {
          "id": "METER_CUBE",
          "label": "m3"
        },
        "section": {
          "id": "metalli_m2",
          "label": "Metalli M2"
        }
      },
      {
        "id": "civ-cdfr",
        "label": "COD_Filtr",
        "color": "#7ce4b5",
        "hasModel": true,
        "unit": {
          "id": "KILO_WATT",
          "label": "KW"
        },
        "section": {
          "id": "metalli_m2",
          "label": "Metalli M2"
        }
      },
      {
        "id": "civ-ntf",
        "label": "N_TOT_Filtr",
        "color": "#0f7270",
        "hasModel": true,
        "unit": {
          "id": "KILO_WATT",
          "label": "KW"
        },
        "section": {
          "id": "metalli_m2",
          "label": "Metalli M2"
        }
      },
      {
        "id": "civ-nno2",
        "label": "N_NO2",
        "color": "#67a10a",
        "hasModel": false,
        "unit": null,
        "section": {
          "id": "metalli_m2",
          "label": "Metalli M2"
        }
      },
      {
        "id": "civ-nno3",
        "label": "N_NO3",
        "color": "#924aba",
        "hasModel": true,
        "unit": {
          "id": "PH",
          "label": "pH"
        },
        "section": {
          "id": "metalli_m2",
          "label": "Metalli M2"
        }
      },
      {
        "id": "civ-rme",
        "label": "Rame",
        "color": "#7b4e3d",
        "hasModel": false,
        "unit": {
          "id": "KILO_WATT",
          "label": "KW"
        },
        "section": {
          "id": "metalli_m2",
          "label": "Metalli M2"
        }
      },
      {
        "id": "civ-nichl",
        "label": "Nichel",
        "color": "#b512fc",
        "hasModel": true,
        "unit": {
          "id": "MILLIGRAM_LITER",
          "label": "mg/l"
        },
        "section": {
          "id": "metalli_m2",
          "label": "Metalli M2"
        }
      },
      {
        "id": "civ-ss105",
        "label": "SS105",
        "color": "#0c28b9",
        "hasModel": true,
        "unit": {
          "id": "VOLT",
          "label": "V"
        },
        "section": {
          "id": "metalli_m2",
          "label": "Metalli M2"
        }
      },
      {
        "id": "civ-ptot",
        "label": "P Totale",
        "color": "#07e167",
        "hasModel": false,
        "unit": {
          "id": "MILLIGRAM_LITER",
          "label": "mg/l"
        },
        "section": {
          "id": "metalli_m1",
          "label": "Metalli M1"
        }
      },
      {
        "id": "civ-uptk",
        "label": "UPTAKE",
        "color": "#2e9c08",
        "hasModel": true,
        "unit": null,
        "section": {
          "id": "metalli_m2",
          "label": "Metalli M2"
        }
      },
      {
        "id": "civ-toc",
        "label": "TOC",
        "color": "#14dc8c",
        "hasModel": false,
        "unit": {
          "id": "CELSIUS",
          "label": "°C"
        },
        "section": {
          "id": "metalli_m2",
          "label": "Metalli M2"
        }
      },
      {
        "id": "civ-br",
        "label": "BORO",
        "color": "#3400d1",
        "hasModel": false,
        "unit": {
          "id": "METER_CUBE",
          "label": "m3"
        },
        "section": {
          "id": "metalli_m2",
          "label": "Metalli M2"
        }
      },
      {
        "id": "civ-cr",
        "label": "CROMO",
        "color": "#20b8f5",
        "hasModel": false,
        "unit": {
          "id": "VOLT",
          "label": "V"
        },
        "section": {
          "id": "metalli_m2",
          "label": "Metalli M2"
        }
      },
      {
        "id": "civ-fsf",
        "label": "FOSFORO",
        "color": "#3960e9",
        "hasModel": false,
        "unit": {
          "id": "MILLIGRAM_LITER",
          "label": "mg/l"
        },
        "section": {
          "id": "metalli_m2",
          "label": "Metalli M2"
        }
      },
      {
        "id": "pre-nnh4",
        "label": "N_NH4",
        "color": "#cc8108",
        "hasModel": true,
        "unit": {
          "id": "METER_CUBE",
          "label": "m3"
        },
        "section": {
          "id": "metalli_m3",
          "label": "Metalli M3"
        }
      },
      {
        "id": "pre-oss",
        "label": "Ossigeno",
        "color": "#88f166",
        "hasModel": true,
        "unit": {
          "id": "CELSIUS",
          "label": "°C"
        },
        "section": {
          "id": "metalli_m3",
          "label": "Metalli M3"
        }
      },
      {
        "id": "pre-ph",
        "label": "pH",
        "color": "#4223dd",
        "hasModel": false,
        "unit": {
          "id": "MILLIGRAM_LITER",
          "label": "mg/l"
        },
        "section": {
          "id": "metalli_m3",
          "label": "Metalli M3"
        }
      },
      {
        "id": "pre-nno2",
        "label": "N_NO2",
        "color": "#d21082",
        "hasModel": false,
        "unit": {
          "id": "CELSIUS",
          "label": "°C"
        },
        "section": {
          "id": "metalli_m3",
          "label": "Metalli M3"
        }
      },
      {
        "id": "pre-nno3",
        "label": "N_NO3",
        "color": "#3cafe8",
        "hasModel": false,
        "unit": {
          "id": "VOLT",
          "label": "V"
        },
        "section": {
          "id": "metalli_m3",
          "label": "Metalli M3"
        }
      },
      {
        "id": "pre-rme",
        "label": "Rame",
        "color": "#f8b9e7",
        "hasModel": true,
        "unit": {
          "id": "PH",
          "label": "pH"
        },
        "section": {
          "id": "metalli_m3",
          "label": "Metalli M3"
        }
      },
      {
        "id": "pre-frro",
        "label": "Ferro",
        "color": "#edf3cc",
        "hasModel": false,
        "unit": {
          "id": "CELSIUS",
          "label": "°C"
        },
        "section": {
          "id": "metalli_m3",
          "label": "Metalli M3"
        }
      },
      {
        "id": "pre-nichl",
        "label": "Nichel",
        "color": "#c060d2",
        "hasModel": true,
        "unit": {
          "id": "CELSIUS",
          "label": "°C"
        },
        "section": {
          "id": "metalli_m3",
          "label": "Metalli M3"
        }
      },
      {
        "id": "pre-pmb",
        "label": "Piombo",
        "color": "#0a97ba",
        "hasModel": false,
        "unit": {
          "id": "MILLIGRAM_LITER",
          "label": "mg/l"
        },
        "section": {
          "id": "metalli_m3",
          "label": "Metalli M3"
        }
      },
      {
        "id": "pre-idrc",
        "label": "Idrocarburi",
        "color": "#241068",
        "hasModel": false,
        "unit": {
          "id": "CELSIUS",
          "label": "°C"
        },
        "section": {
          "id": "metalli_m3",
          "label": "Metalli M3"
        }
      },
      {
        "id": "pre-ss105",
        "label": "SS105",
        "color": "#4f7732",
        "hasModel": false,
        "unit": {
          "id": "CELSIUS",
          "label": "°C"
        },
        "section": {
          "id": "metalli_m3",
          "label": "Metalli M3"
        }
      },
      {
        "id": "pre-uptk",
        "label": "UPTAKE",
        "color": "#fb656d",
        "hasModel": true,
        "unit": {
          "id": "MILLIGRAM_LITER",
          "label": "mg/l"
        },
        "section": {
          "id": "metalli_m3",
          "label": "Metalli M3"
        }
      },
      {
        "id": "pre-toc",
        "label": "TOC",
        "color": "#4ed64d",
        "hasModel": true,
        "unit": null,
        "section": {
          "id": "metalli_m3",
          "label": "Metalli M3"
        }
      },
      {
        "id": "pre-br",
        "label": "BORO",
        "color": "#69d7f3",
        "hasModel": false,
        "unit": {
          "id": "CELSIUS",
          "label": "°C"
        },
        "section": {
          "id": "metalli_m3",
          "label": "Metalli M3"
        }
      },
      {
        "id": "pre-cr",
        "label": "CROMO",
        "color": "#3e9846",
        "hasModel": false,
        "unit": {
          "id": "PH",
          "label": "pH"
        },
        "section": {
          "id": "metalli_m3",
          "label": "Metalli M3"
        }
      },
      {
        "id": "pre-clor",
        "label": "CLORURI",
        "color": "#399f31",
        "hasModel": false,
        "unit": {
          "id": "KILO_WATT",
          "label": "KW"
        },
        "section": {
          "id": "metalli_m3",
          "label": "Metalli M3"
        }
      },
      {
        "id": "pre-svi",
        "label": "SVI",
        "color": "#cfdeb6",
        "hasModel": true,
        "unit": {
          "id": "CELSIUS",
          "label": "°C"
        },
        "section": {
          "id": "metalli_m3",
          "label": "Metalli M3"
        }
      },
      {
        "id": "pre-solvoa",
        "label": "Solv. Org.Azotati",
        "color": "#e0fda2",
        "hasModel": true,
        "unit": {
          "id": "KILO_WATT",
          "label": "KW"
        },
        "section": {
          "id": "metalli_m3",
          "label": "Metalli M3"
        }
      },
      {
        "id": "pre-solvclor",
        "label": "Solv. Clorurati",
        "color": "#7080ed",
        "hasModel": false,
        "unit": {
          "id": "KILO_WATT",
          "label": "KW"
        },
        "section": {
          "id": "metalli_m3",
          "label": "Metalli M3"
        }
      },
      {
        "id": "sedp-oss",
        "label": "Ossigeno",
        "color": "#532a35",
        "hasModel": false,
        "unit": {
          "id": "PH",
          "label": "pH"
        },
        "section": {
          "id": "idrocarburi_i1",
          "label": "Idrocarburi I1"
        }
      },
      {
        "id": "sedp-ph",
        "label": "pH",
        "color": "#2b1a86",
        "hasModel": false,
        "unit": {
          "id": "PH",
          "label": "pH"
        },
        "section": {
          "id": "idrocarburi_i1",
          "label": "Idrocarburi I1"
        }
      },
      {
        "id": "sedp-sed",
        "label": "Sed.",
        "color": "#bd4932",
        "hasModel": true,
        "unit": {
          "id": "VOLT",
          "label": "V"
        },
        "section": {
          "id": "idrocarburi_i1",
          "label": "Idrocarburi I1"
        }
      },
      {
        "id": "sedp-attq",
        "label": "Azoto Totale TQ",
        "color": "#a69cc5",
        "hasModel": false,
        "unit": {
          "id": "VOLT",
          "label": "V"
        },
        "section": {
          "id": "idrocarburi_i1",
          "label": "Idrocarburi I1"
        }
      },
      {
        "id": "sedp-cdfr",
        "label": "COD_Filtr",
        "color": "#04b45b",
        "hasModel": false,
        "unit": {
          "id": "VOLT",
          "label": "V"
        },
        "section": {
          "id": "idrocarburi_i1",
          "label": "Idrocarburi I1"
        }
      },
      {
        "id": "sedp-rme",
        "label": "Rame",
        "color": "#f6634f",
        "hasModel": true,
        "unit": {
          "id": "METER_CUBE",
          "label": "m3"
        },
        "section": {
          "id": "idrocarburi_i1",
          "label": "Idrocarburi I1"
        }
      },
      {
        "id": "sedp-frro",
        "label": "Ferro",
        "color": "#981976",
        "hasModel": true,
        "unit": {
          "id": "PH",
          "label": "pH"
        },
        "section": {
          "id": "idrocarburi_i1",
          "label": "Idrocarburi I1"
        }
      },
      {
        "id": "sedp-nichl",
        "label": "Nichel",
        "color": "#a1860a",
        "hasModel": true,
        "unit": null,
        "section": {
          "id": "idrocarburi_i1",
          "label": "Idrocarburi I1"
        }
      },
      {
        "id": "sedp-pmb",
        "label": "Piombo",
        "color": "#843cf1",
        "hasModel": false,
        "unit": {
          "id": "PH",
          "label": "pH"
        },
        "section": {
          "id": "idrocarburi_i1",
          "label": "Idrocarburi I1"
        }
      },
      {
        "id": "sedp-idrc",
        "label": "Idrocarburi",
        "color": "#0176ea",
        "hasModel": true,
        "unit": {
          "id": "PH",
          "label": "pH"
        },
        "section": {
          "id": "idrocarburi_i1",
          "label": "Idrocarburi I1"
        }
      },
      {
        "id": "sedp-ss105",
        "label": "SS105",
        "color": "#19f759",
        "hasModel": true,
        "unit": {
          "id": "KILO_WATT",
          "label": "KW"
        },
        "section": {
          "id": "idrocarburi_i1",
          "label": "Idrocarburi I1"
        }
      },
      {
        "id": "sedp-ptot",
        "label": "P Totale",
        "color": "#e22731",
        "hasModel": false,
        "unit": {
          "id": "METER_CUBE",
          "label": "m3"
        },
        "section": {
          "id": "idrocarburi_i1",
          "label": "Idrocarburi I1"
        }
      },
      {
        "id": "sedp-fsf",
        "label": "FOSFORO",
        "color": "#adf9b8",
        "hasModel": true,
        "unit": {
          "id": "METER_CUBE",
          "label": "m3"
        },
        "section": {
          "id": "idrocarburi_i1",
          "label": "Idrocarburi I1"
        }
      },
      {
        "id": "sedp-clor",
        "label": "CLORURI",
        "color": "#a08544",
        "hasModel": true,
        "unit": {
          "id": "METER_CUBE",
          "label": "m3"
        },
        "section": {
          "id": "idrocarburi_i1",
          "label": "Idrocarburi I1"
        }
      },
      {
        "id": "sedp-svi",
        "label": "SVI",
        "color": "#90ee04",
        "hasModel": false,
        "unit": {
          "id": "VOLT",
          "label": "V"
        },
        "section": {
          "id": "idrocarburi_i1",
          "label": "Idrocarburi I1"
        }
      },
      {
        "id": "sedp-bod",
        "label": "BOD",
        "color": "#ce0158",
        "hasModel": false,
        "unit": {
          "id": "KILO_WATT",
          "label": "KW"
        },
        "section": {
          "id": "idrocarburi_i1",
          "label": "Idrocarburi I1"
        }
      },
      {
        "id": "sedp-ecol",
        "label": "E.Coli",
        "color": "#93ccb6",
        "hasModel": false,
        "unit": {
          "id": "VOLT",
          "label": "V"
        },
        "section": {
          "id": "idrocarburi_i1",
          "label": "Idrocarburi I1"
        }
      },
      {
        "id": "sedp-solvoa",
        "label": "Solv. Org.Azotati",
        "color": "#33a119",
        "hasModel": false,
        "unit": {
          "id": "PH",
          "label": "pH"
        },
        "section": {
          "id": "idrocarburi_i1",
          "label": "Idrocarburi I1"
        }
      },
      {
        "id": "sedp-solvclor",
        "label": "Solv. Clorurati",
        "color": "#dedeb0",
        "hasModel": false,
        "unit": {
          "id": "VOLT",
          "label": "V"
        },
        "section": {
          "id": "idrocarburi_i1",
          "label": "Idrocarburi I1"
        }
      },
      {
        "id": "sedp-cdmio",
        "label": "Cadmio",
        "color": "#c7f0fc",
        "hasModel": true,
        "unit": {
          "id": "CELSIUS",
          "label": "°C"
        },
        "section": {
          "id": "idrocarburi_i1",
          "label": "Idrocarburi I1"
        }
      },
      {
        "id": "den-oss",
        "label": "Ossigeno",
        "color": "#76bfb0",
        "hasModel": true,
        "unit": {
          "id": "MILLIGRAM_LITER",
          "label": "mg/l"
        },
        "section": {
          "id": "idrocarburi_i2",
          "label": "Idrocarburi I2"
        }
      },
      {
        "id": "den-ph",
        "label": "pH",
        "color": "#7df9c0",
        "hasModel": false,
        "unit": {
          "id": "CELSIUS",
          "label": "°C"
        },
        "section": {
          "id": "idrocarburi_i2",
          "label": "Idrocarburi I2"
        }
      },
      {
        "id": "den-sed",
        "label": "Sed.",
        "color": "#eae4e7",
        "hasModel": true,
        "unit": {
          "id": "VOLT",
          "label": "V"
        },
        "section": {
          "id": "idrocarburi_i2",
          "label": "Idrocarburi I2"
        }
      },
      {
        "id": "den-attq",
        "label": "Azoto Totale TQ",
        "color": "#4d4b19",
        "hasModel": false,
        "unit": {
          "id": "KILO_WATT",
          "label": "KW"
        },
        "section": {
          "id": "idrocarburi_i2",
          "label": "Idrocarburi I2"
        }
      },
      {
        "id": "den-rme",
        "label": "Rame",
        "color": "#098369",
        "hasModel": true,
        "unit": {
          "id": "VOLT",
          "label": "V"
        },
        "section": {
          "id": "idrocarburi_i2",
          "label": "Idrocarburi I2"
        }
      },
      {
        "id": "den-frro",
        "label": "Ferro",
        "color": "#be211c",
        "hasModel": false,
        "unit": {
          "id": "METER_CUBE",
          "label": "m3"
        },
        "section": {
          "id": "idrocarburi_i2",
          "label": "Idrocarburi I2"
        }
      },
      {
        "id": "den-nichl",
        "label": "Nichel",
        "color": "#f9c098",
        "hasModel": false,
        "unit": null,
        "section": {
          "id": "idrocarburi_i2",
          "label": "Idrocarburi I2"
        }
      },
      {
        "id": "den-pmb",
        "label": "Piombo",
        "color": "#5449c5",
        "hasModel": false,
        "unit": {
          "id": "METER_CUBE",
          "label": "m3"
        },
        "section": {
          "id": "idrocarburi_i2",
          "label": "Idrocarburi I2"
        }
      },
      {
        "id": "den-idrc",
        "label": "Idrocarburi",
        "color": "#494c22",
        "hasModel": false,
        "unit": {
          "id": "MILLIGRAM_LITER",
          "label": "mg/l"
        },
        "section": {
          "id": "idrocarburi_i2",
          "label": "Idrocarburi I2"
        }
      },
      {
        "id": "den-ss105",
        "label": "SS105",
        "color": "#4f7e75",
        "hasModel": true,
        "unit": {
          "id": "MILLIGRAM_LITER",
          "label": "mg/l"
        },
        "section": {
          "id": "idrocarburi_i2",
          "label": "Idrocarburi I2"
        }
      },
      {
        "id": "den-ptot",
        "label": "P Totale",
        "color": "#c338b0",
        "hasModel": true,
        "unit": {
          "id": "PH",
          "label": "pH"
        },
        "section": {
          "id": "idrocarburi_i2",
          "label": "Idrocarburi I2"
        }
      },
      {
        "id": "den-uptk",
        "label": "UPTAKE",
        "color": "#2a5e4f",
        "hasModel": true,
        "unit": {
          "id": "KILO_WATT",
          "label": "KW"
        },
        "section": {
          "id": "idrocarburi_i2",
          "label": "Idrocarburi I2"
        }
      },
      {
        "id": "den-toc",
        "label": "TOC",
        "color": "#d89663",
        "hasModel": true,
        "unit": {
          "id": "KILO_WATT",
          "label": "KW"
        },
        "section": {
          "id": "idrocarburi_i2",
          "label": "Idrocarburi I2"
        }
      },
      {
        "id": "den-cdmio",
        "label": "Cadmio",
        "color": "#fa2deb",
        "hasModel": false,
        "unit": null,
        "section": {
          "id": "idrocarburi_i2",
          "label": "Idrocarburi I2"
        }
      },
      {
        "id": "den-fenli",
        "label": "FENOLI",
        "color": "#d5a0bc",
        "hasModel": true,
        "unit": {
          "id": "METER_CUBE",
          "label": "m3"
        },
        "section": {
          "id": "idrocarburi_i2",
          "label": "Idrocarburi I2"
        }
      },
      {
        "id": "sedb-oss",
        "label": "Ossigeno",
        "color": "#da526e",
        "hasModel": true,
        "unit": {
          "id": "METER_CUBE",
          "label": "m3"
        },
        "section": {
          "id": "idrocarburi_i3",
          "label": "Idrocarburi I3"
        }
      },
      {
        "id": "sedb-sed",
        "label": "Sed.",
        "color": "#de867b",
        "hasModel": false,
        "unit": {
          "id": "VOLT",
          "label": "V"
        },
        "section": {
          "id": "idrocarburi_i3",
          "label": "Idrocarburi I3"
        }
      },
      {
        "id": "sedb-attq",
        "label": "Azoto Totale TQ",
        "color": "#8b255d",
        "hasModel": false,
        "unit": {
          "id": "MILLIGRAM_LITER",
          "label": "mg/l"
        },
        "section": {
          "id": "idrocarburi_i3",
          "label": "Idrocarburi I3"
        }
      },
      {
        "id": "sedb-cdfr",
        "label": "COD_Filtr",
        "color": "#34c0c7",
        "hasModel": false,
        "unit": {
          "id": "PH",
          "label": "pH"
        },
        "section": {
          "id": "idrocarburi_i3",
          "label": "Idrocarburi I3"
        }
      },
      {
        "id": "sedb-ntf",
        "label": "N_TOT_Filtr",
        "color": "#3822ba",
        "hasModel": true,
        "unit": {
          "id": "KILO_WATT",
          "label": "KW"
        },
        "section": {
          "id": "idrocarburi_i3",
          "label": "Idrocarburi I3"
        }
      },
      {
        "id": "sedb-nno2",
        "label": "N_NO2",
        "color": "#da676b",
        "hasModel": true,
        "unit": {
          "id": "VOLT",
          "label": "V"
        },
        "section": {
          "id": "idrocarburi_i3",
          "label": "Idrocarburi I3"
        }
      },
      {
        "id": "sedb-nno3",
        "label": "N_NO3",
        "color": "#e65de7",
        "hasModel": true,
        "unit": null,
        "section": {
          "id": "idrocarburi_i3",
          "label": "Idrocarburi I3"
        }
      },
      {
        "id": "sedb-rme",
        "label": "Rame",
        "color": "#36614c",
        "hasModel": true,
        "unit": {
          "id": "METER_CUBE",
          "label": "m3"
        },
        "section": {
          "id": "idrocarburi_i3",
          "label": "Idrocarburi I3"
        }
      },
      {
        "id": "sedb-frro",
        "label": "Ferro",
        "color": "#8ca754",
        "hasModel": true,
        "unit": {
          "id": "CELSIUS",
          "label": "°C"
        },
        "section": {
          "id": "idrocarburi_i3",
          "label": "Idrocarburi I3"
        }
      },
      {
        "id": "sedb-cr",
        "label": "CROMO",
        "color": "#e7a6a0",
        "hasModel": false,
        "unit": {
          "id": "VOLT",
          "label": "V"
        },
        "section": {
          "id": "idrocarburi_i3",
          "label": "Idrocarburi I3"
        }
      },
      {
        "id": "sedb-fsf",
        "label": "FOSFORO",
        "color": "#b34536",
        "hasModel": true,
        "unit": {
          "id": "VOLT",
          "label": "V"
        },
        "section": {
          "id": "idrocarburi_i3",
          "label": "Idrocarburi I3"
        }
      },
      {
        "id": "sedb-bod",
        "label": "BOD",
        "color": "#64e90e",
        "hasModel": false,
        "unit": {
          "id": "VOLT",
          "label": "V"
        },
        "section": {
          "id": "idrocarburi_i3",
          "label": "Idrocarburi I3"
        }
      },
      {
        "id": "sedb-ecol",
        "label": "E.Coli",
        "color": "#5ff037",
        "hasModel": true,
        "unit": null,
        "section": {
          "id": "idrocarburi_i3",
          "label": "Idrocarburi I3"
        }
      },
      {
        "id": "sedb-cdmio",
        "label": "Cadmio",
        "color": "#2c7c3f",
        "hasModel": false,
        "unit": {
          "id": "VOLT",
          "label": "V"
        },
        "section": {
          "id": "idrocarburi_i3",
          "label": "Idrocarburi I3"
        }
      },
      {
        "id": "sedb-fenli",
        "label": "FENOLI",
        "color": "#bc8260",
        "hasModel": false,
        "unit": {
          "id": "KILO_WATT",
          "label": "KW"
        },
        "section": {
          "id": "idrocarburi_i3",
          "label": "Idrocarburi I3"
        }
      },
      {
        "id": "sedb-cdmio",
        "label": "Cadmio",
        "color": "#2c7c3f",
        "hasModel": false,
        "unit": {
          "id": "VOLT",
          "label": "V"
        },
        "section": {
          "id": "batteri",
          "label": "Batteri"
        }
      },
      {
        "id": "sedb-fenli",
        "label": "FENOLI",
        "color": "#bc8260",
        "hasModel": false,
        "unit": {
          "id": "KILO_WATT",
          "label": "KW"
        },
        "section": {
          "id": "idrofono",
          "label": "Idrofono"
        }
      }
    ]
  }
};