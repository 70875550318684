import { DataSource } from '@n7-frontend/core';
import { ChartSelectFilterData } from '@app/components/chart-select-filter/chart-select-filter';
import { FiltersHelper } from '@app/helpers';

export type TemporalFilterOption = {
  value:{
    value: number | string;
    unit: 'hours' | 'days';
  }
  label: string;
  default?: boolean
}

export class DateSelectFilterDS extends DataSource {
  private _compareActive: boolean = false;

  protected transform(data): ChartSelectFilterData {
    if(!data) return;

    const filter = FiltersHelper.getDateFilter();
    if(!filter) return;
    const {options, custom} = filter;
    const items: any[] = [];

    options.map( (option: TemporalFilterOption) => {
      const {value, label} = option;
      const isSelected = false;
      const id = `${value.value}${value.unit}`
      items.push({
        label: label,
        isSelected,
        payload: {
          _id: id,
          ...value,
          isSelected
        },
        _meta: {
          id
        }
      })
    })
    
    if(custom.enabled){
      items.push({
        label: 'i18n.chart_select.options.select_date',
        payload: {
          _id: 'custom',
          start: '',
          end: '',
          isCustomDate: true,
        },
        _meta: {
          id: 'custom'
        }
      })
    }
    return { items };
  }

  public onClick({ type, payload }){
    // set selected
    this.output.items.forEach(item => {
      if(item._meta.id === 'custom') return;

      item.isSelected = payload._id === item._meta.id;
      item.payload.isSelected = payload._id === item._meta.id;
    });
  }

  onCompareCheckboxChange(payload){
    this._compareActive = payload;
    // trigger close
    if(!payload){
      const checkbox = document.getElementsByClassName('iwt-dropdown__inner-input')[0];
      checkbox['checked'] = false;
    }
  }

  isCompareActive(){
    return this._compareActive;
  }
}
