import { FiltersHelper } from '@app/helpers/filters-helper';
import { DataSource } from '@n7-frontend/core';

export class FormAnnotationsDS extends DataSource {
  private _formState: any = {};
  private _datepicker: any = null;
  private _file: File;
  private _fileBase64: string;

  protected transform(data) {
    if(!data) return; 

    const {custom} = FiltersHelper.getDateFilter();

    let returnData = {};

    if(data.datepicker){
       returnData = {
        _setDatepicker: (datepicker) => this._datepicker = datepicker,
        _elementId: 'annotation-datepicker',
        options: {
          enableTime: custom.enableTime,
          dateFormat: 'Y-m-d H:i',
          defaultDate: [data.startDate, data.endDate],
          mode: "range",
          maxDate: new Date()
        },
      };
    }
    else {

      if(data.x1){
        this.setDate([data.x1, data.x2]);
      }
      if(data._edit){
        this._formState = data;
      }
      else{
        data = {...data, _edit: false};
        this._formState = data;
      }
      returnData = data;
    }
    return returnData;
    }

  onLabelChange(value){
    this._formState["label"] = value;
  }

  onDescriptionChange(value){
    this._formState["description"] = value;
  }

  getFormState(){
    if(this._datepicker.selectedDates){
      const [from, to] = this._datepicker.selectedDates;
      this._formState["x1"] = Date.parse(from);
      this._formState["x2"] = Date.parse(to);
    }
    return this._formState;
  }

  open(){
    setTimeout(() => this._datepicker.open());
  }

  setDate(date: string[]){
    if(date[0]){
      const [from, to] = date;
      const dateFrom = new Date(from);
      const dateTo = new Date(to);
      this._datepicker.setDate([dateFrom, dateTo]);
    }

  }

  onFilechange(payload: FileList){
    if(payload[0]){
      this._file = payload[0];
      if(!this._formState["attachment"]){
        this._formState["attachment"] = [];
      }
      
      if(this._formState["attachment"].length<3){
        let reader = new FileReader();
        reader.readAsDataURL(this._file);
        let obj = {"_meta": {}, "data": null};
        obj["_meta"]={"_name": this._file.name, "_type": this._file.type};
  
        if(this._file.size>3000000){
          this.update({...this._formState, formNotValid: "File più grande di 3 MB."});
  
        } else{
          reader.onload = (e: any) => {
            this._fileBase64 = e.target.result;
            obj["data"] = this._fileBase64;
            this._formState["attachment"].push(obj)
          }
        }
        this.update(this._formState);
        }
    }

    
  }

  onRemoveAttachment(i){
    this._formState["attachment"].splice(i, 1);

  }

  formNotValid(err: string){
    this.update({...this._formState, formNotValid: err})
  }
}
