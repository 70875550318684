import { EventHandler } from '@n7-frontend/core';

export class ChartEH extends EventHandler {
  public listen() {

    this.innerEvents$.subscribe(({type, payload}) => {

      switch(type){

        case 'chart.mouseUp':
          const x = this.dataSource.getX();
          this.emitOuter("mouseUp", x)
          break;
        
        case 'chart.click':
          const labelId = this.dataSource.onClick();
          if(labelId !== ""){
            this.emitOuter("clickLabel", labelId)
          }
          break;

        default:
          break;
      }
    });

    this.outerEvents$.subscribe(event => {
      
      // chart legend change
      if(event.type.indexOf('legend.change') !== -1){
        this.dataSource.onLegendChange(event);
      }

      switch(event.type){
        /* case 'chart-layout.chart-legend.change':
          this.dataSource.onLegendChange(event);
          break; */

        case 'chart-layout.annotation-click':
          const x = this.dataSource.getX();
          this.emitOuter("mouseUp", x)
          break;

        case 'chart-layout.chart-request':
          this.dataSource.onChartRequest(event);
          break;

        case 'chart-layout.compare-chart-request':
          this.dataSource.onChartRequest(event);
          break;

        case 'chart-layout.y-axis_checkbox_change':
          this.dataSource.onYAxisCheckboxChange();
          break;

        case 'chart-layout.newAnnotation':
          this.dataSource.onNewAnnotation(event.payload);
          break;

        case 'chart-layout.annotation-edit':
          this.dataSource.annotationEdit(event.payload);
          break;
        
        case 'chart-layout.annotation-remove':
          this.dataSource.annotationRemove(event.payload);
          break;
        
        case'chart-layout.annotation-checkbox-change':
          this.dataSource.annotationCheckboxChange(event.payload)
          break;
        
        case 'chart-layout.load-annotations':
          this.dataSource.onLoadAnnotations(event.payload);
          break;

        default:
          break;
      }
    });
  }
}
