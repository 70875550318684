import { EventHandler } from '@n7-frontend/core';

export class ItemSelectFilterEH extends EventHandler {
  public listen() {

    this.innerEvents$.subscribe(event => {
      switch(event.type){
        case 'item-select-filter.click': 
          if(event.payload.isSelected) return;
          this.dataSource.onClick(event);
          this.emitOuter('click', event.payload);
          break;
        
        default:
          break;
      }
    });
  }
}